import React from 'react';
import Error from '../image/404.svg';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
class NotFound extends React.Component{
    render() {
        const {Dictionary} = this.props;

        return(

            <div className="section-vertical-padding">
                <div className="page-container">
                    <div className="pageNotFound text-center">
                        <img className="image" src={Error} alt="404" />
                        <div>
                            <p>
                                <b>
                                    {Dictionary.ThePageWasNotFound}
                                </b>
                            </p>
                            <p>
                                404 - {Dictionary.ThePageYouAreTryingToAccessDoesNotExistOnOurSite}
                            </p>
                            <div>
                                <Link className="button blue-btn" to={'/'}>
                                    {Dictionary.returnToHome}
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            
            

        )
    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(NotFound)