import React from "react";
import queryString from "query-string";
import _ from "lodash";
import axios from "axios";
import { connect } from "react-redux";
import ListItem from "../../components/Common/ListItem";
import Page from "../../components/Common/Page";
import {
  saveParameters,
  searchHotelAsync,
  fillHotelSearchURL,
  sortHotelSearch,
  searchHotelFilterAsync
} from "../../Actions/HotelAction";
import {
  Header,
  Hotel as HotelURL,
  ServerAddress,
} from "../../Enum/Urls";
import { showError } from "../../Actions/VisualDataAction";
import HotelForm from "../../components/Accomodation/SearchForm";
import { Hotel, Travel, Sort ,Filter} from "../../Enum/Models";
import Currency from "../../Enum/Currency";
import HotelFilter from "../../components/Accomodation/FilterForm";
import Loading from "../../components/Common/Loading";
import danger from "../../image/danger.svg";
import ShowMap from "../../components/Accomodation/showMap";
import LayoutConfig from "../../Config/layoutConfig";
import HotelFormSearchTOF from "../../components/TOF/Hotel-Form-Search-TOF";
class HotelResult extends React.Component {
  state = {
    error: "",
    loadingShow: true,
    first: 0,
    last: 9,
    showfilter: false,
    showsearch: false,
    sort:{
      name:Sort.price,
      reverse:false
    },
    collapseSearchMenu: false,
    showMapContainer: false,
  };
  handleSort(type) {
    this.setState(
      prevState => {
        return {
          ...this.state,
          sort:{
            name:type,
            reverse:prevState.sort.name !== type ? false : !prevState.sort.reverse
          }
        };
      },
      () => {
        this.props.sortHotelSearch({
          typeSort: type,
          isReverse: this.state.sort.reverse,
        });
      }
    );
  }
  changePage(first, last) {
    this.setState({...this.state, first: first, last: last });
    this.scrollTolist();
  }
  handleMapToggle = () => {
    this.setState((prevState) => {
      return {
        showMapContainer: !prevState.showMapContainer,
      };
    });
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      this.getHotels();
    }
    if (prevProps.hotels !== this.props.hotels){
      this.scrollTolist();
    }
  }
  componentDidMount() {
    this.props.searchHotelAsync(Hotel);
    this.getHotels();
    this.props.fillHotelSearchURL(window.location.href);
  }

  getHotels = async () => {
    
    this.setState({
      loadingShow: true
    });

    let parameters = queryString.parse(this.props.location.search);
    this.props.saveParameters(parameters);
    
    let params = new URLSearchParams();
    parameters.adults.split(",").forEach((item) => params.append("adults", item));
    parameters.children.split(",").forEach((item) => params.append("children", item));
    if (parameters.ages.length !== 0) {
      parameters.ages.split(",").forEach((item) => params.append("ages", item));
    }
    params.append("LocationId", parameters.locationId);
    params.append("checkin", parameters.checkin);
    params.append("checkout", parameters.checkout);
    params.append("NationalityCode", Currency.getNationality());
    
    try {
      const response = await axios({
        method: "get",
        url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetAccomadtion}`,
        params: params,
        headers: { ...Header },
      });
      if (response){
        this.props.searchHotelAsync(response.data.result);

        var filter = new Filter();
        //calcaute filter values
        if (response.data.result.hotels !== null) {
          response.data.result.hotels.forEach((hotelItem) => {
            //get all rate
            if (filter.rating.value.indexOf(hotelItem.rating) === -1) {
              filter.rating.value.push(hotelItem.rating);
            }
            //get all facilities
            if (hotelItem.features !== null) {
              hotelItem.features.forEach((featureItem) => {
                featureItem.value = null;
                if (!_.some(filter.features.value, featureItem)) {
                  filter.features.value.push(featureItem);
                }
              });
            }
            //get all Board
            if (hotelItem.boards !== null) {
              hotelItem.boards.forEach((boardItem) => {
                if (!_.some(filter.boards.value, boardItem)) {
                  filter.boards.value.push(boardItem);
                }
              });
            }
            //get all location
            if (hotelItem.regions !== null) {
              hotelItem.regions.forEach((regionsitem) => {
                if (!_.some(filter.regions.value, regionsitem)) {
                  filter.regions.value.push(regionsitem);
                }
              });
            }
          });
          filter.rating.value.sort();
        }
        this.props.searchHotelFilterAsync(filter);
        this.scrollTolist();
      }
    } catch (error) {
      try {
        this.props.showError({
          visibility: true,
          content: error.response.data.error.message,
        })

        } catch (e) {
          this.props.showError({
            visibility: true,
            content: "Internal Error,Please refresh the page",
          })
        }
    } finally{
      this.setState({
        loadingShow: false
      })
    }

  };

  scrollTolist = () => { 
    setTimeout(()=>{
      if(this.listHolder ){ this.listHolder.scrollIntoView({behavior: "smooth"})}
    },50)
  };


  render() {
    const { Dictionary } = this.props;
    return (
      <div className=" search-result-page">
        {(LayoutConfig.themeClassName === "padide-com-theme") && (
          <div className="result-inline-search-section">
            <div className="page-container">
              <HotelFormSearchTOF inline={true} />
            </div>
          </div>
        )}
        <div className={LayoutConfig.themeClassName === "style-2"?"":"section-vertical-padding"}>
          <div className="hotelResult relative">
            {this.state.loadingShow ? (
              <Loading style2={LayoutConfig.themeClassName === "style-2"} fullPage description={Dictionary.SearchingBestPrices+" ..."}/>
              // <div className="page-container">
              //   <div className="loading-outer-container">
              //     <div>
              //       <img
              //         src={LayoutConfig.logo ? LayoutConfig.logo : siteLogo}
              //         className="loading-logo"
              //         alt={
              //           LayoutConfig.companyName
              //             ? LayoutConfig.companyName
              //             : "itours"
              //         }
              //       />
              //       <div className="loading-inner-container">
              //         <div className="item-1"></div>
              //         <div className="item-2"></div>
              //         <div className="item-3"></div>
              //         <div className="item-4"></div>
              //         <div className="item-5"></div>
              //       </div>
              //       <div className="text-center">
              //         {Dictionary.SearchingBestPrices} ...
              //       </div>
              //     </div>
              //   </div>
              // </div>
            ) : this.props.hotels === null ? (
              <div className="page-container">
                <div className="section-vertical-padding">
                  <h2 className="page-title">
                    {Dictionary.NoHotelsFoundForYourRequest}.
                    <div className="page-subtitle">
                      {Dictionary.pleaseTryAgain}.
                    </div>
                  </h2>
                  <div className="clearfix alert-cart card">
                    <img
                      src={danger}
                      className="alert-icon"
                      alt="danger-icon"
                    />
                    <div className="alert-content">
                      <h6 className="alert-title">
                        {Dictionary.NoHotelsFoundForYourRequest}.
                      </h6>
                      <p className="no-margin">{Dictionary.pleaseTryAgain}.</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {LayoutConfig.themeClassName === "padide-com-theme" || LayoutConfig.themeClassName === "style-2" || (
                  <div className="page-container">
                    <h2 className="page-title">{Dictionary.selectHotel}</h2>
                  </div>
                )}
                  {LayoutConfig.themeClassName === "style-2" && (
                    <div className="search-form-wrapper">
                      <div className="page-container">
                        <HotelForm />
                      </div>
                    </div>
                  )}
                <div className="page-container no-padding-mobile">
                  <div className="avails-row float-row">
                    <div className="col-small-12 col-medium-3">
                      <div className="sidebar box-border bg-white">
                        <div className="map-btn-holder">
                          {this.props.hotels && this.props.hotels.length > 0 && (
                            <button
                              className="map-view-btn"
                              type={"button"}
                              onClick={this.handleMapToggle}
                            >
                              {this.state.showMapContainer
                                ? Dictionary.listView
                                : Dictionary.viewMap}
                            </button>
                          )}
                        </div>
                        {/* <div className="sidebar-item clearfix">
                                            <span className="results-number-holder">
                                                <b className="inline-block margin-end-light"> {this.props.hotels.length} </b><span className="inline-block"> hoteller funnet i </span> <b className="inline-block en-font"> {this.props.city.name} </b>
                                            </span>
                                            <button type="button" className="button grey-btn pull-end filter-responsive-btn" onClick={()=>{this.setState((prevState)=>({showfilter:!prevState.showfilter}))}}>
                                            Filtre
                                            </button>
                                        </div> */}
                        <div
                          className={
                            this.state.showfilter
                              ? "showfilter filters-holder"
                              : "filters-holder"
                          }
                        >
                          <HotelFilter scrollTolist={this.scrollTolist} />
                          <button
                            type="button"
                            className="button red-btn filter-close-btn"
                            onClick={() => {
                              this.setState((prevState) => ({
                                showfilter: !prevState.showfilter,
                              }));
                            }}
                          >
                            {Dictionary.close}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-small-12 col-medium-9">
                      {LayoutConfig.themeClassName === "padide-com-theme" || LayoutConfig.themeClassName === "style-2"|| (
                        <div className="result-page-search-bar hidden-xsmall hidden-small">
                          <div className="search-form-wrapper">
                            <HotelForm
                              collapseMode={true}
                              fixedSearchHolder={true}
                            />
                          </div>
                        </div>
                      )}
                      <div ref={element => {this.listHolder = element}}>
                        {this.props.hotels.length > 0 ? (
                          <div className="avails-holder" >
                            <div className="results-sorting-block">
                              <div className="clearfix">
                                <div className="pull-start-small">
                                  {/* <img className={"sortPoint"} src={returnway} alt="sort" title={"sort"}/>  */}
                                  {Dictionary.sortBy}:
                                </div>
                                <div className="pull-end-small">
                                  <button
                                    type="button"
                                    className={`button results-sort-btn ${this.state.sort.name !== Sort.price ? "disable" : this.state.sort.reverse ? "" : "low"}`}
                                    onClick={() => {
                                      this.handleSort(Sort.price);
                                    }}
                                  >
                                    {Dictionary.price}
                                  </button>
                                  <button
                                    type="button"
                                    className={`button results-sort-btn ${this.state.sort.name !== Sort.name ? "disable" : this.state.sort.reverse ? "" : "low"}`}
                                    onClick={() => {
                                      this.handleSort(Sort.name);
                                    }}
                                  >
                                    {Dictionary.hotelName}
                                  </button>
                                  <button
                                    type="button"
                                    className={`button results-sort-btn ${this.state.sort.name !== Sort.rate ? "disable" : this.state.sort.reverse ? "" : "low"}`}
                                    onClick={() => {
                                      this.handleSort(Sort.rate);
                                    }}
                                  >
                                    {Dictionary.starRating}
                                  </button>
                                </div>
                              </div>
                            </div>
                            {this.state.showMapContainer ? (
                              <div className="showMap">
                                {this.props.hotels.length !== 0 && (
                                  <div>
                                    <ShowMap
                                      currency={this.props.currency}
                                      data={this.props.hotels}
                                      height={700}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="showResult">
                                <ListItem
                                  last={this.state.last}
                                  type={Travel.hotel}
                                  data={this.props.hotels}
                                  start={this.state.first}
                                />
                                {this.props.hotels.length < 10 ? null : (
                                  <Page
                                    length={this.props.hotels.length}
                                    itemCurrent={this.state.first}
                                    itemPerPage={10}
                                    onChangeValue={this.changePage.bind(
                                      this
                                    )}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="section-vertical-padding text-center">
                            <div>
                              <span className="itours-icon icon-xlarge error-icon" />
                            </div>
                            <h5 className="normal-title">
                              {Dictionary.NoHotelsFoundForYourRequest}.
                              <div className="page-subtitle">
                                {Dictionary.pleaseTryAgain}.
                              </div>
                            </h5>
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="filter-responsive-btn"
                      onClick={() => {
                        this.setState((prevState) => ({
                          showfilter: !prevState.showfilter,
                        }));
                      }}
                    >
                      {Dictionary.filters}
                    </button>                    
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  error: state.VisualData.error,
  hotels: state.HotelSearch.hotels,
  city: state.HotelSearch.city,
  currency: state.UserData.Currency,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
const mapDispatchToProps = (dispatch) => ({
  showError: (param) => {
    dispatch(showError(param));
  },
  saveParameters: (param) => {
    dispatch(saveParameters(param));
  },
  searchHotelAsync: (param) => {
    dispatch(searchHotelAsync(param));
  },
  searchHotelFilterAsync: (params) => {
    dispatch(searchHotelFilterAsync(params));
  },
  fillHotelSearchURL: (param) => {
    dispatch(fillHotelSearchURL(param));
  },
  sortHotelSearch: (param) => {
    dispatch(sortHotelSearch(param));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(HotelResult);
