import React from 'react';
import {connect} from "react-redux";
import TermsFa from './Terms-fa';
import TermsEn from './Terms-en';
import TermsAr from './Terms-ar';
import TermsNo from './Terms-no';
import TermsTr from './Terms-tr';
import TermsSe from './Terms-se';
import TermsNamin from './Terms-namin';

class Terms extends React.PureComponent{
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render() {
        const {Dictionary,LAN} = this.props;
        return (
            <div className={`section-vertical-padding ${process.env.REACT_APP_THEME ==='NAMINTRAVELCOM' ? "" : "bg-white"}`}>
                <div className="page-container">
                    {LAN === "IR"?
                        null
                    :process.env.REACT_APP_THEME ==='NAMINTRAVELCOM'?
                        <div className="text-center bg-white card-padding margin-bottom blue-border blue capitalized">
                            <h2 className="font-23 bold">{Dictionary.termsAndConditions}</h2>
                            <p className="no-margin semi-bold">It is the buyer and the traveler's responsibility to...</p>
                        </div>
                    :
                        <h2 className="page-title">{Dictionary.termsAndConditions}</h2>
                    }
                  
                    <div className="wrapper justified">
                        {
                            (process.env.REACT_APP_THEME ==='NAMINTRAVELCOM')?
                                <TermsNamin />                            
                            :
                            (LAN === "IR")?
                                <TermsFa />
                            :(LAN === "NO")?
                                <TermsNo />
                            :(LAN === "AR")?
                                <TermsAr />
                            :(LAN === "TR")?
                                <TermsTr />
                            : (LAN === "SE")?
                                <TermsSe />
                            :
                                <TermsEn />
                        }                    
                    </div> 
                </div>
            </div>
        )
    }

}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(Terms)