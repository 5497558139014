import React from 'react';
import queryString from "query-string";
import {Link} from "react-router-dom";
import paymentSuccess from '../../image/payment-successfull.svg';
import paymentError from '../../image/payment-error.svg';
import {connect} from "react-redux";
class Result extends React.Component{
    state={successPayment:false,reserveId:0};
    componentDidMount() {
        let parameters=queryString.parse(this.props.location.search);
        if(parameters.status==="true"){
            this.setState({successPayment:true})
        }
        this.setState({
            reserveId:parameters.reserveId
        });
    }
    render() {
        const {Dictionary} = this.props;
        return(
            <div className="page-container">
                <div>
                {this.state.successPayment &&
                <div className="alert success-alert">
                    <img src={paymentSuccess} alt="successfull payment" className="payment-result-icon" />                                    
                    <div className="alert-message">
                        {Dictionary.paymentDoneSuccessfully}. {Dictionary.ClickOnOkForBooking}
                    </div>
                    <div>
                    <Link className="button" to={"/profile/?mode=1"} >{Dictionary.ok}</Link>
                    </div>
                </div>
                }
                {!this.state.successPayment &&
                <div className="alert error-alert">
                    <img src={paymentError} alt="failed payment" className="payment-result-icon"/>                                    
                    <div className="alert-message">
                        {Dictionary.unfortunatelyPaymentIsFailed}, {Dictionary.pleaseTryAgain}
                    </div>
                    <div>
                        <Link  className="button" to={`/payment/reserve?reserveId=${this.state.reserveId}`} >{Dictionary.checkAnotherPaymentWay} </Link>
                    </div>
                </div>
                }
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(Result)