import React from "react";
import { connect } from "react-redux";

import LayoutConfig from "../../Config/layoutConfig";
import Rate from '../Common/Rate';
import Gallery from "../Common/Gallery";
import ShowMap from "../Accomodation/showMap";

const hotelItemDetails = props => {
    const { hotelDetails,Dictionary } = props;
    return(<>
        <div className="hotel-head-info" dir="ltr">
            <div className="full-width">
                <h3 className="hotel-title en-font">{hotelDetails.name}</h3>
                {hotelDetails.address && (
                <div className="hotel-address en-font">
                    <span className="itours-icon location-dark margin-right-light icon-small" />
                    {hotelDetails.address}
                </div>
                )}
                <div className="hotelRange">
                    <Rate number={hotelDetails.rating} />
                </div>
            </div>
        </div>

        <div className="box-border bg-white margin-bottom detail-section blue-border-right">
        <div className="float-row no-gap ltr">
            <div
            className={
                hotelDetails.features &&
                hotelDetails.features.length > 0 &&
                LayoutConfig.themeClassName !== "padide-com-theme"
                ? "col-small-12 col-large-9"
                : "col-small-12"
            }
            dir="ltr"
            >
            {hotelDetails.images.length >= 1 ? (
                <Gallery images={hotelDetails.images} />
            ) : hotelDetails.mainPhoto ? (
                <img
                src={hotelDetails.mainPhoto}
                alt={hotelDetails.name}
                className="full-width hotel-main-1photo"
                />
            ) : (
                <div className="card hotel-no-picture box-border text-center body-direction">
                <div className="no-image-hint ">
                    <div className="margin-bottom">
                    {Dictionary.noImageHeadingText}
                    </div>
                    <small>{Dictionary.noImageDetailText}</small>
                </div>
                </div>
            )}
            </div>
            {hotelDetails.features.length > 0 ? (
            <div
                className={
                LayoutConfig.themeClassName === "padide-com-theme"
                    ? "col-small-12"
                    : "col-small-12 col-large-3"
                }
            >
                <div className="card-padding">
                <h5 className="normal-title">
                    {Dictionary.specialFacilities}
                </h5>

                {hotelDetails.features.map((item, index) => {
                    return (
                    <div key={index} className="facilitie-item">
                        {item.name === "Facilities" && (
                        <div
                            className="margin-bottom en-font"
                            id="hotel_facilities"
                        >
                            <div dir="ltr" className="feature-list">
                            <div className="feature-items">
                                {item.items.map(
                                (detailsItem, detailsIndex) => (
                                    <div
                                    className={
                                        detailsItem.isImportant
                                        ? "is-important"
                                        : null
                                    }
                                    key={detailsIndex}
                                    >
                                    {detailsItem.name}{" "}
                                    </div>
                                )
                                )}
                            </div>
                            </div>
                        </div>
                        )}
                    </div>
                    );
                })}
                </div>
            </div>
            ) : null}
        </div>
        </div>

        {/*photo Gallery and hotel info*/}
        {hotelDetails.features.length > 0 ? (
        <div className="card card-padding bg-white box-border margin-bottom detail-section blue-border-right">
            {hotelDetails.features.map((item, index) => {
            return (
                <div key={index} className="facilitie-item">
                {item.name === "Object Information" && (
                    <div className="margin-bottom">
                    <h5 className="normal-title">
                        {Dictionary.hotelContactInformation}
                    </h5>
                    <div dir="ltr">
                        <ul className="float-row no-list-style ltr">
                        {item.items.map((detailsItem, detailsIndex) => (
                            <li
                            className="col-small-12 col-medium-6 col-large-3 item"
                            key={detailsIndex}
                            >
                            {detailsItem.name}:{detailsItem.value}{" "}
                            </li>
                        ))}
                        </ul>
                    </div>
                    </div>
                )}
                {item.name === "Building Information" && (
                    <div className="margin-bottom">
                    <h5 className="normal-title">
                        {Dictionary.SpecificationsForHotelBuildings}
                    </h5>
                    <div dir="ltr">
                        <ul className=" float-row  no-list-style ltr">
                        {item.items.map((detailsItem, detailsIndex) => (
                            <li
                            className="col-small-12 col-medium-6 col-large-3 item"
                            key={detailsIndex}
                            >
                            {detailsItem.name}:{detailsItem.value}{" "}
                            </li>
                        ))}
                        </ul>
                    </div>
                    </div>
                )}
                {item.name === "Payment" && (
                    <div className="margin-bottom">
                    <h5 className="normal-title">
                        {Dictionary.ValidFinancialCreditsForHotelUse}
                    </h5>
                    <div dir="ltr">
                        <ul className=" float-row  no-list-style ltr">
                        {item.items.map((detailsItem, detailsIndex) => (
                            <li
                            className="col-small-12 col-medium-6 col-large-3 item"
                            key={detailsIndex}
                            >
                            {detailsItem.name}{" "}
                            </li>
                        ))}
                        </ul>
                    </div>
                    </div>
                )}
                {item.name === "Facilities" && (
                    <div className="margin-bottom" id="hotel_facilities">
                    <h5 className="normal-title">
                        {Dictionary.hotelFacilities}
                    </h5>
                    <div dir="ltr">
                        <ul className=" float-row  no-list-style ltr">
                        {item.items.map((detailsItem, detailsIndex) => (
                            <li
                            className="col-small-12 col-medium-6 col-large-3 item"
                            key={detailsIndex}
                            >
                            {detailsItem.name}{" "}
                            </li>
                        ))}
                        </ul>
                    </div>
                    </div>
                )}
                {item.name === "Room Facilities" && (
                    <div className="margin-bottom">
                    <h5 className="normal-title">
                        {Dictionary.roomFacilities}
                    </h5>
                    <div dir="ltr">
                        <ul className=" float-row  no-list-style ltr">
                        {item.items.map((detailsItem, detailsIndex) => (
                            <li
                            className="col-small-12 col-medium-6 col-large-3 item"
                            key={detailsIndex}
                            >
                            {detailsItem.name}{" "}
                            </li>
                        ))}
                        </ul>
                    </div>
                    </div>
                )}
                {item.name === "Distances" && (
                    <div className="margin-bottom">
                    <h5 className="normal-title">
                        {Dictionary.DistanceFromHotelToPlaces}
                    </h5>
                    <div dir="ltr">
                        <ul className="float-row  no-list-style ltr">
                        {item.items.map((detailsItem, detailsIndex) => (
                            <li
                            className="col-small-12 col-medium-6 col-large-3 item"
                            key={detailsIndex}
                            >
                            {detailsItem.name}:{detailsItem.value} m{" "}
                            </li>
                        ))}
                        </ul>
                    </div>
                    </div>
                )}
                </div>
            );
            })}
        </div>
        ) : null}

        {
        hotelDetails.paragraphs && hotelDetails.paragraphs.length > 0 && (
            <div id="hotel_details">
            <div
                className="card card-padding bg-white box-border margin-bottom blue-border-right"
                dir="ltr"
            >
                <h5 className="normal-title body-direction">
                {" "}
                {Dictionary.hotelDetails}
                </h5>
                <div className="en-font">
                <div className="textContent">
                    {hotelDetails.paragraphs.map((item, index) => {
                    return (
                        <div key={index}>
                        <div>
                            <b className="title">{item.title}</b>
                        </div>
                        <div>
                            <p>{item.para}</p>
                        </div>
                        </div>
                    );
                    })}
                </div>
                </div>
            </div>
            </div>
        )
        // hotel paragraph
        }

        <div className="box-border map-holder">
        <ShowMap data={[props.hotelDetails]} />
        </div>
    </>);
}

const stateMapProp = (state) => ({
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN,
  });
  export default connect(stateMapProp)(hotelItemDetails);
