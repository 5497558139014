import React from "react";

const NaminHomeDestinations = () => {

    const destinations =[
        {
            continent:"َAsia",
            countries:[
                {
                    country:"Afghanistan",
                    cities:["Kabul"]
                },
                {
                    country:"Bangladesh",
                    cities:["Dhaka"]
                },  
                {
                    country:"India",
                    cities:["Delhi","Goa","Mumbai"]
                },                
                {
                    country:"Mongolia",
                    cities:["Ulan Bator"]
                },
                {
                    country:"Nepal",
                    cities:["Kathmandu"]
                }, 
                {
                    country:"Pakistan",
                    cities:["Islamabad","Karachi","Lahore"]
                }, 
                {
                    country:"Philippines",
                    cities:["Manila"]
                },                
                {
                    country:"Sri Lanka",
                    cities:["Colombo"]
                },                         
                {
                    country:"Vietnam",
                    cities:["Hanoi","Ho Chi Minh City"]
                },  
            ]
        },
        {
            continent:"Middle East",
            countries:[               
                {
                    country:"Iran",
                    cities:["Isfahan","Mashhad","Shiraz","Tabriz","Tehran"]
                },
                {
                    country:"Iraq",
                    cities:["Baghdad","Basra","Erbil","Najaf","Sulaimaniya"]
                },                                
                {
                    country:"Jordan",
                    cities:["Amman"]
                },  
                {
                    country:"Kuwait",
                    cities:["Kuwait City"]
                },  
                {
                    country:"Lebanon",
                    cities:["Beirut"]
                },              
                {
                    country:"United Arab Emirates",
                    cities:["Dubai"]
                }
            ]
        },
        {
            continent:"Africa",
            countries:[  
                {
                    country:"Burundi",
                    cities:["Bujumbura"]
                },  
                {
                    country:"Congo",
                    cities:["Kinshasa"]
                },
                {
                    country:"Djibouti",
                    cities:["Djibouti"]
                },                
                {
                    country:"Egypt",
                    cities:["Cairo"]
                }, 
                {
                    country:"Eritrea",
                    cities:["Asmara"]
                },                
                {
                    country:"Ethiopia",
                    cities:["Addis Ababa"]
                },  
                {
                    country:"Gambia",
                    cities:["Banjul"]
                },                         
                {
                    country:"Ghana",
                    cities:["Accra"]
                }, 
                {
                    country:"Kenya",
                    cities:["Mombasa","Nairobi"]
                }, 
                {
                    country:"Nigeria",
                    cities:["Lagos"]
                },                             
                {
                    country:"Rwanda",
                    cities:["Kigali"]
                },   
                {
                    country:"Senegal",
                    cities:["Dakar"]
                },  
                {
                    country:"Somalia",
                    cities:["Mogadishu"]
                },                               
                {
                    country:"Somaliland",
                    cities:["Hargeisa"]
                },                             
                {
                    country:"Sudan",
                    cities:["Khartoum"]
                },                              
                {
                    country:"Tanzania",
                    cities:["Dar-es-Salaam"]
                },                                                             
                {
                    country:"Uganda",
                    cities:["Entebbe"]
                }
            ]
        },
        {
            continent:"America",
            countries:[ 
                {
                    country:"Argentina",
                    cities:["Buenos Aires"]
                },   
                {
                    country:"Bolivia",
                    cities:["La Paz","Santa Cruz"]
                },                       
                {
                    country:"Brazil",
                    cities:["Fortaleza","Rio de Janeiro","São Paulo"]
                },                                     
                {
                    country:"Chile",
                    cities:["Santiago"]
                },                     
                {
                    country:"Colombia",
                    cities:["Bogota","Cali"]
                }, 
                {
                    country:"Ecuador",
                    cities:["Guayaquil","Quito"]
                },  
                {
                    country:"Panama",
                    cities:["Panama City"]
                },                      
                {
                    country:"Peru",
                    cities:["Lima"]
                }
            ]
        }
        // ,
        // {
        //     continent:"Europe",
        //     countries:[
        //     ]
        // }
    ];

    return(<section className="namin-destinations-section">
        <div className="page-container">
            <h3 className="destination-section-title">DESTINATIONS OF THE WORLD</h3>
            <div className="namin-destinations">
                {destinations.map(continentItem=><div className="destination-block">
                    <h4 className="block-title capitalized">{continentItem.continent}</h4>
                    {continentItem.countries.map(countryItem=><div className="dest-country-item">
                        <h5 className="dest-country-title">
                            {countryItem.country}
                        </h5>
                        {countryItem.cities.map(cityItem => <span className="dest-item-link"> {cityItem} </span>)}
                    </div>)}
                </div>)}
            </div>
            {/* <div className="namin-destinations">
                <div className="destination-block">
                    <h4 className="block-title">Asia</h4>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                            India
                        </h5>
                        <span href="#" className="dest-item-link">Mumbai (Bombay) </span>
                        <span href="#" className="dest-item-link">Delhi</span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                            Vietnam
                        </h5>
                        <span href="#" className="dest-item-link">Ho Chi Minh City (Saigon)  </span>
                        <span href="#" className="dest-item-link">Hanoi</span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                            Philippines
                        </h5>
                        <span href="#" className="dest-item-link">Manila</span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Sri Lanka
                        </h5>
                        <span href="#" className="dest-item-link"> Colombo </span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Pakistan
                        </h5>
                        <span href="#" className="dest-item-link">Lahore </span>
                        <span href="#" className="dest-item-link">Islamabad-Rawalpindi	 </span>
                        <span href="#" className="dest-item-link">Karachi	 </span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Bangladesh
                        </h5>
                        <span href="#" className="dest-item-link">Dhaka	 </span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Afghanistan
                        </h5>
                        <span href="#" className="dest-item-link">Kabul	 </span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Nepal
                        </h5>
                        <span href="#" className="dest-item-link">Kathmandu	 </span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Mongolia
                        </h5>
                        <span href="#" className="dest-item-link">Ulan Bator	 </span>
                    </div> 
                </div>
                <div className="destination-block">
                    <h4 className="block-title">America</h4>
                </div>
                <div className="destination-block">
                    <h4 className="block-title">Middle East</h4>

                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Lebanon
                        </h5>
                        <span href="#" className="dest-item-link">Beirut </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Israel
                        </h5>
                        <span href="#" className="dest-item-link">Tel Aviv </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                            Kuwait
                        </h5>
                        <span href="#" className="dest-item-link">Kuwait </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Iraq
                        </h5>
                        <span href="#" className="dest-item-link">Baghdad</span>
                        <span href="#" className="dest-item-link">Basrah</span>
                        <span href="#" className="dest-item-link">Najaf</span>
                        <span href="#" className="dest-item-link">Erbil</span>
                        <span href="#" className="dest-item-link">Sulaimaniyah</span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                            Jordan
                        </h5>
                        <span href="#" className="dest-item-link">Amman </span>
                    </div>              
                </div>     
                <div className="destination-block">
                    <h4 className="block-title">Europe</h4>
                </div>
                <div className="destination-block">
                    <h4 className="block-title">Africa</h4>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Kenya
                        </h5>
                        <span href="#" className="dest-item-link">Nairobi </span>
                        <span href="#" className="dest-item-link">Mombasa</span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Ghana
                        </h5>
                        <span href="#" className="dest-item-link">Accra </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Uganda
                        </h5>
                        <span href="#" className="dest-item-link">Kampala </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Rwanda
                        </h5>
                        <span href="#" className="dest-item-link">Kigali </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Eritrea
                        </h5>
                        <span href="#" className="dest-item-link">Asmara </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Ethiopia
                        </h5>
                        <span href="#" className="dest-item-link">Addis Ababa </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Senegal
                        </h5>
                        <span href="#" className="dest-item-link">Dakar </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Gambia
                        </h5>
                        <span href="#" className="dest-item-link">Banjul </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Tanzania
                        </h5>
                        <span href="#" className="dest-item-link">Dar es Salaam </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Nigeria
                        </h5>
                        <span href="#" className="dest-item-link">Ikeja </span>
                    </div> 
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Sudan
                        </h5>
                        <span href="#" className="dest-item-link">Khartoum </span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                            Somalia
                        </h5>
                        <span href="#" className="dest-item-link">Mogadishu </span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Djibouti 
                        </h5>
                        <span href="#" className="dest-item-link">Djibouti </span>
                    </div>
                    <div className="dest-country-item">
                        <h5 className="dest-country-title">
                        Egypt
                        </h5>
                        <span href="#" className="dest-item-link">Cairo </span>
                    </div> 
                </div>
            </div> */}
        </div>
    </section>)
}

export default NaminHomeDestinations;