import React from "react";
import validator from "validator";
import { Map } from "../../Enum/Common";

export default class Form extends React.Component {
  state = {
    ValidatorList: {},
    ValidatorSummary: [],
    Loading: false,
    DataInput: {},
  };
  componentDidMount(formDOM) {
    let FormData = {};
    if (formDOM !== null) {
      for (let element of formDOM) {
        if (element.name.length > 0) {
          FormData[element.name] = "";
        }
      }
    }

    this.setState({
      ValidatorList: { ...FormData },
    });
  }
  validateForm(event, callback) {
    event.preventDefault();
    let DataFeild = {};
    let ValidatorArr = {};
    let FormStatus = true;
    const { Dictionary } = this.props;
    this.setState({ ValidatorSummary: [] });
    for (let item of event.target) {
      if (item.name.length > 0) {
        if (item.type === "tel" && item.value) {
          const pattern = /[^0-9۰-۹٠-٩]/gi;
          let testResult = item.value.match(pattern);
          if(item.value.length < 4 || item.value.length > 15 || item.value[0]==="0"){
            testResult = true;
          }
          if (testResult) {
            ValidatorArr[item.name] = Dictionary.enteredPhoneNumberIsNotValid2;
            FormStatus = false;
          } else {
            Map(DataFeild, item.name, item.value);
          }
        }
        if (item.getAttribute("data-validator") === "true") {
          if (validator.isEmpty(item.value) || (item.tagName==="SELECT" && item.value === "false")) {
            ValidatorArr[item.name] = Dictionary.thisFieldIsRequired;
            FormStatus = false;
          } else if (item.type === "checkbox") {
            if (!item.checked) {
              ValidatorArr[item.name] = Dictionary.youShouldAcceptThis;
              FormStatus = false;
            }
          } else {
            if (item.type === "email"){
              if (item.value && !validator.isEmail(item.value)) {
                ValidatorArr[item.name] =
                  Dictionary.enteredEmailAddressIsNotValid2;
  
                FormStatus = false;
              } else {
                Map(DataFeild, item.name, item.value);
              }
            }
            if (item.type === "password") {
              if (!validator.isLength(item.value, { min: 6, max: undefined })) {
                ValidatorArr[item.name] =
                  Dictionary.thePasswordMustBeAtLeast6Characters;
                FormStatus = false;
              } else {
                Map(DataFeild, item.name, item.value);
              }
            } //password
             else {
              if (item.value.length === 0) {
                ValidatorArr[item.name] = Dictionary.fillTheText;
                FormStatus = false;
              }else if (item.value==="Invalid date"){
                ValidatorArr[item.name] = Dictionary.thisFieldIsRequired;
                FormStatus = false;
              }else {
                Map(DataFeild, item.name, item.value);
              }
            }
            if (item.getAttribute("data-validator-type") === "alphabet") {
              const pattern = /[^ A-Za-z]/gi;
              let testResult = item.value.match(pattern);
              if (testResult) {
                ValidatorArr[item.name] =
                  Dictionary.numbersAndNonLatinCharactersAreNotAllowed;
                FormStatus = false;
              } else {
                Map(DataFeild, item.name, item.value);
              }
            }
            if (item.getAttribute("data-validator-type") === "farsiAlphabet") {
              const pattern = /^[\u0600-\u06FF\s]+$/;
              let testResult =pattern.test(item.value);
              if (!testResult) {
                ValidatorArr[item.name] =
                'اعداد و کاراکترهای لاتین مجاز نیستند'
                    // Dictionary.numbersAndLatinCharactersAreNotAllowed;
                FormStatus = false;
              } else {
                Map(DataFeild, item.name, item.value);
              }
            }
            if (item.getAttribute("data-validator-type") === "number") {
              const pattern = /[^0-9۰-۹٠-٩]/gi;
              let testResult = item.value.match(pattern);
              if (testResult) {
                ValidatorArr[item.name] = Dictionary.onlyNumbersAreAllowed;
                FormStatus = false;
              } else {
                Map(DataFeild, item.name, item.value);
              }
            }
            if (item.getAttribute("data-validator-type") === "nationalCode") {
              debugger;
              const fixNumbers = str => {
                const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
                const arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
                if(typeof str === 'string'){
                  for(let i=0; i<10; i++){
                    str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
                  }
                }
                return str;
              };
              const value = fixNumbers(item.value); 
              const pattern = /[^0-9]/gi;
              let testResult = value.match(pattern);

              //nationalCode algorithm:
              let controlDigit;
              const v = value;
              let sum = 0;
              for (let i=0 ; i < 9 ;i++){
                sum += Number(v[i]) * (10-i) ;
              }
              const r = sum % 11;
              if (r < 2){
                controlDigit = r ;
              }else{
                controlDigit = 11 - r ;
              }

              if (testResult || value.length !== 10 || (+v[9] !== +controlDigit) ) {
                ValidatorArr[item.name] = Dictionary.nationalCodeIsNotValid;
                FormStatus = false;
              } else {
                Map(DataFeild, item.name, value);
              }
            }
            if (item.getAttribute("data-validator-type") === "passportNumber") {
              const pattern = /[^0-9۰-۹٠-٩A-Za-z]/gi;
              let testResult = item.value.match(pattern);
              if (testResult) {
                ValidatorArr[item.name] = Dictionary.passportNumberIsNotValid;
                FormStatus = false;
              } else {
                Map(DataFeild, item.name, item.value);
              }
            }
          }
        } else {
          if (item.type === "email") {
            if (item.value && !validator.isEmail(item.value)) {
              ValidatorArr[item.name] =
                Dictionary.enteredEmailAddressIsNotValid2;

              FormStatus = false;
            } else {
              Map(DataFeild, item.name, item.value);
            }
          } //email
          else if (item.type === "checkbox") {
            if (item.checked) {
              Map(DataFeild, item.name, true);
            }else{
              Map(DataFeild, item.name, false);
            }
          }
          else if (item.type === "radio") {
            if (item.checked) {
              Map(DataFeild, item.name, item.value);
            }
          }else if (item.getAttribute("data-validator-type") === "alphabet") {
            const pattern = /[^ A-Za-z]/gi;
            let testResult = item.value.match(pattern);
            if (testResult) {
              ValidatorArr[item.name] = Dictionary.numbersAndNonLatinCharactersAreNotAllowed;
              FormStatus = false;
            }
          }else if (item.getAttribute("data-validator-type") === "farsiAlphabet") {
            const pattern = /^[\u0600-\u06FF\s]+$/;
            let testResult =pattern.test(item.value);
            if (!testResult) {
              ValidatorArr[item.name] = 'اعداد و کاراکترهای لاتین مجاز نیستند';
              FormStatus = false;
            }
          }
           else {
            Map(DataFeild, item.name, item.value);
          }
        }
      }
    }
    if (FormStatus) {
      this.setState(
        {
          Loading: FormStatus,
          DataInput: { ...DataFeild },
          ValidatorList: ValidatorArr,
        },
        () => {
          callback();
        }
      );
    } else {
      this.setState({ Loading: FormStatus, ValidatorList: ValidatorArr });
    }
  }
}
