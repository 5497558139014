import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import queryString from "query-string";
import Gallery from "../Common/Gallery";
import {
  saveParameters,
  getPreReserve,
  preReserve,
  setCancelationRoom,
  getHotelDetailsAsync,
  setSafarmarketHotelPixel
} from "../../Actions/HotelAction";
import {
  Header,
  Hotel as HotelURL,
  ServerAddress,
} from "../../Enum/Urls";
import { showError } from "../../Actions/VisualDataAction";
import HotelInformation from "../Accomodation/HotelInformation";
// import Room from "../Accomodation/Room";
import { Currency } from "../../Enum/Models";
import SearchRoom from "../Accomodation/RoomSearch";
import ShowMap from "../Accomodation/showMap";
import Loading from "../Common/Loading";

import HotelMultipleRoom from "./RoomLayout/HotelMultipleRoom";

import HotelSingleRoom from "./RoomLayout/HotelSingleRoom";
import LayoutConfig from "../../Config/layoutConfig";

class HotelDetail extends React.Component {
  state = {
    Facilities: [],
    RoomsFacilities: [],
    doneSelectRoom: false,
    showSearch: true,
    loading:true,
    isFromSafarmarket:false,
    safarMarketId:undefined
  };
  
  currecySign = null;

  toggleSearch = () => {
    this.setState((preState) => {
      return {
        showSearch: !preState.showSearch,
      };
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    this.roomsHolder = React.createRef();

    //safarmarket:
    let parameters = queryString.parse(this.props.location.search);
    let smId;
    let cookies = decodeURIComponent(document.cookie).split(';');
    for (const item of cookies){
      if (item.includes("safarMarketHotelId=")){
        smId=item.split("=")[1];
      }
    }
    if (LayoutConfig.safarmarket && this.props.currencyCode ==="IRR" && parameters.safarmarketId && parameters.utm_medium && parameters.utm_source ){
      
      
      this.setState({isFromSafarmarket:true});
      smId = parameters.safarmarketId;
      const expDate = new Date();
      expDate.setTime(expDate.getTime() + (7*24*60*60*1000));
      document.cookie = `safarMarketHotelId=${parameters.safarmarketId}; expires=${expDate.toUTCString()};path=/`;
    }
    if (LayoutConfig.safarmarket && this.props.currencyCode ==="IRR" && smId) {
      this.setState({
        safarMarketId:smId
      })
      this.props.setSafarmarketPixelValue(encodeURI(`https://safarmarket.com/api/hotel/v1/pixel/itours/2/0/?smId=${smId}`));
    }
    this.getHotelDetails(smId);
  }
  componentWillUnmount(){
    this.props.setSafarmarketPixelValue(undefined);
  }

  getHotelDetails = async(safarmarketId) => {
    this.setState({loading:true});

    let parameters = queryString.parse(this.props.location.search);
    this.props.saveParameters(parameters);
    
    let params = new URLSearchParams();
    params.append("Id", parameters.Id);
    parameters.Adults.split(",").forEach((item) =>
      params.append("Adults", item)
    );
    parameters.Children.split(",").forEach((item) =>
      params.append("Children", item)
    );
    if (parameters.Ages.length !== 0) {
      parameters.Ages.split(",").forEach((item) => params.append("Ages", item));
    }
    params.append("Checkin", parameters.Checkin);
    params.append("Checkout", parameters.Checkout);

    if(safarmarketId){
      params.append("metaId",safarmarketId);
      params.append("source",'safarmarket');
    }

    try{
      const response = await axios({
        method: "get",
        url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetAccomadtionDetails}`,
        params: params,
        headers: { ...Header },
      });
      if (response){
        this.props.getHotelDetailsAsync(response.data.result)
      }
    }catch(error){
      try {
        this.props.showError({
          visibility: true,
          content: error.response.data.error.message,
        })
      } catch (e) {
        this.props.showError({
          visibility: true,
          content: "Internal Error,Please refresh the page",
        })
      }
    }
    this.setState({loading:false});

  };

  componentDidUpdate(prevProps, prevState, snapshot){
    if (prevProps.hotelDetails !== this.props.hotelDetails){
      const facilities = this.props.hotelDetails?.features?.find(item => item.keyword==="facilities")?.items || [];
      const roomFacilities = this.props.hotelDetails?.features?.find(item => item.keyword==="room_facilities")?.items || [];
      this.setState({
        Facilities: facilities,
        RoomsFacilities: roomFacilities,
      })
    }
  }

  handleSelectRoom = async (bookingToken) => {
    //create Parameters'
    this.setState({
      doneSelectRoom: true,
    });
    let parameters = queryString.parse(this.props.location.search);
    let params = {};
    let adult = [];
    parameters.Adults.split(",").forEach((item) => adult.push(parseInt(item)));
    params["adults"] = adult;
    let children = [];
    parameters.Children.split(",").forEach((item) =>
      children.push(parseInt(item))
    );
    params["children"] = children;
    if (parameters.Ages.length !== 0) {
      let ages = [];
      parameters.Ages.split(",").forEach((item) => ages.push(parseInt(item)));
      params["ages"] = ages;
    }
    params["checkin"] = parameters.Checkin;
    params["checkout"] = parameters.Checkout;
    params["bookingToken"] = bookingToken;
    if(this.state.safarMarketId){
      params["metaId"]=this.state.safarMarketId;
      params["source"]="safarmarket";
    }

    //this.props.setRooms(params);
    let response = await preReserve(params);
    if (response) {
      if (response.error?.message === "Unavailable"){
        this.setState({doneSelectRoom:false});
        this.props.showError({
          visibility:true,
          content:<div>
              {this.props.Dictionary.UnfortunatelyThisRoomIsNotAvailablePleaseChooseAnotherOne}.
            </div>,
          closable:true,
          icon:"info",
          buttonLabel:this.props.Dictionary.ok
        })




      }else{
        let url = `/hotel/getPassengers?preReserveKey=${response.preReserveKey}`;
        if(this.state.safarMarketId){
          url = `/hotel/getPassengers?preReserveKey=${response.preReserveKey}&smid=${this.state.safarMarketId}`;
        }
        this.props.history.push(url);
      }
    }
  };

  scrollToRooms = () => { if(this.roomsHolder && this.roomsHolder.current ){ this.roomsHolder.current.scrollIntoView({behavior: "smooth"})}} ;


  render() {
    const { Dictionary } = this.props;
    let loadingCell = [];
    if (this.state.RoomsInfo) {
      for (let cnt = 0; cnt < this.state.RoomsInfo.length; cnt++) {
        let cashArr = [];
        for (
          let icnt = 0;
          icnt < this.state.RoomsInfo[cnt].rooms.length;
          icnt++
        ) {
          cashArr.push(false);
        }
        loadingCell[cnt] = cashArr;
      }
    }
    if (this.props.currencyCode !== null) {
      let currency = this.props.currencyCode.toString();
      this.currecySign = Currency[currency];
    } else {
      this.currecySign = "???";
    }
    const {hotelDetails } = this.props;

    let roomLayout;
    if (
      hotelDetails &&
      hotelDetails.availabilities &&
      hotelDetails.availabilities.length > 0
    ) {
      if (hotelDetails.availabilities[0].rooms.length === 1) {
        roomLayout = hotelDetails.availabilities.map((m) => {
          return (
            <HotelSingleRoom
              key={hotelDetails.availabilities.indexOf(m)}
              hotelFacilities={this.state.Facilities}
              roomFacilities={this.state.RoomsFacilities}
              room={m.rooms[0]}
              rates={m.rates}
              date={hotelDetails.date}
              currency={this.props.currencyCode}
              onSelectRoom={this.handleSelectRoom}
              location={this.props.location}
            />
          );
        });
      } else {
        roomLayout = hotelDetails.availabilities.map((m) => {
          return (
            <HotelMultipleRoom
              key={hotelDetails.availabilities.indexOf(m)}
              rooms={m.rooms}
              rates={m.rates}
              date={hotelDetails.date}
              currency={this.props.currencyCode}
              onSelectRoom={this.handleSelectRoom}
              location={this.props.location}
            />
          );
        });
      }
    } else {
      roomLayout = (
        <div className="box-border card-padding bg-white text-center">
          <div className="margin-bottom">
            <span className="itours-icon error-icon icon-xlarge" />
          </div>
          <div className="text-center font-18">
            <div className="margin-bottom">
              <strong>{Dictionary.SorryNoRoomFoundRightNow}!</strong>
            </div>
            <p>{Dictionary.pleaseTryAnotherHotel}</p>
          </div>
        </div>
      );
    }
    return (
      <div className="roomDetailsContainer">
        <div className="page-container no-padding-mobile">
          {this.state.loading  ? 
            <Loading style2={LayoutConfig.themeClassName === "style-2"} fullPage description={Dictionary.getHotelInformation+" ..."}/>
          :this.state.doneSelectRoom ?
            (<Loading style2={LayoutConfig.themeClassName === "style-2"} fullPage/>

            // <div className="loading-outer-container">
            //   <div>
            //     <img
            //       src={LayoutConfig.logo ? LayoutConfig.logo : siteLogo}
            //       className="loading-logo"
            //       alt={
            //         LayoutConfig.companyName
            //           ? LayoutConfig.companyName
            //           : "itours"
            //       }
            //     />
            //     <div className="loading-inner-container">
            //       <div className="item-1"></div>
            //       <div className="item-2"></div>
            //       <div className="item-3"></div>
            //       <div className="item-4"></div>
            //       <div className="item-5"></div>
            //     </div>
            //     <div className="text-center">
            //       {this.state.doneSelectRoom ? (
            //         <span>{Dictionary.SendsSelectedRoomInformation} ... </span>
            //       ) : (
            //         <span> {Dictionary.getHotelInformation} ... </span>
            //       )}
            //     </div>
            //   </div>
            // </div>
          ) : (
            //loading Section
            hotelDetails && 
            <div className="section-vertical-padding hotel-details">
              { this.state.isFromSafarmarket && <h5 className="bg-white box-border margin-bottom-large card-padding normal-title safarmarket-welcome">
                شما از موتور جستجو <b className="purple">سفرمارکت</b> به <b className="purple">آیتورز</b> هدایت شده اید. لطفا فرایند رزرو هتل خود را در این صفحه ادامه دهید.
              </h5>}
              <div className="hotel-head-info" dir="ltr">
                <HotelInformation
                  model={hotelDetails}
                  //   salePrice={
                  //     hotelDetails.segments[0]
                  //       ? hotelDetails.segments[0].rooms[0].salePrice
                  //       : 0
                  //   }
                  salePrice={0}
                  currencyCode={this.props.currencyCode}
                  onScrollToRooms={this.scrollToRooms}
                />
              </div>

              <div className="box-border bg-white margin-bottom detail-section blue-border-right">
                <div className="float-row no-gap ltr">
                  <div
                    className={
                      hotelDetails &&
                      hotelDetails.features &&
                      hotelDetails.features.length > 0 &&
                      LayoutConfig.themeClassName !== "padide-com-theme"
                        ? "col-small-12 col-large-9"
                        : "col-small-12"
                    }
                    dir="ltr"
                  >
                    {hotelDetails.images.length >= 1 ? (
                      <Gallery images={hotelDetails.images} />
                    ) : hotelDetails.mainPhoto ? (
                      <img
                        src={hotelDetails.mainPhoto}
                        alt={hotelDetails.name}
                        className="full-width hotel-main-1photo"
                      />
                    ) : (
                      <div className="card hotel-no-picture box-border text-center body-direction">
                        <div className="no-image-hint ">
                          <div className="margin-bottom">
                            {Dictionary.noImageHeadingText}
                          </div>
                          <small>{Dictionary.noImageDetailText}</small>
                        </div>
                      </div>
                    )}
                  </div>
                  {hotelDetails.features.length > 0 ? (
                    <div
                      className={
                        LayoutConfig.themeClassName === "padide-com-theme"
                          ? "col-small-12"
                          : "col-small-12 col-large-3"
                      }
                    >
                      <div className="card-padding">
                        <h5 className="normal-title">
                          {Dictionary.specialFacilities}
                        </h5>

                        {hotelDetails.features.map((item, index) => {
                          return (
                            <div key={index} className="facilitie-item">
                              {item.name === "Facilities" && (
                                <div
                                  className="margin-bottom en-font"
                                  id="hotel_facilities"
                                >
                                  <div dir="ltr" className="feature-list">
                                    <div className="feature-items">
                                      {item.items.map(
                                        (detailsItem, detailsIndex) => (
                                          <div
                                            className={
                                              detailsItem.isImportant
                                                ? "is-important"
                                                : null
                                            }
                                            key={detailsIndex}
                                          >
                                            {detailsItem.name}{" "}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              {/*photo Gallery and hotel info*/}
              {hotelDetails.features.length > 0 ? (
                <div className="card card-padding bg-white box-border margin-bottom detail-section blue-border-right">
                  {hotelDetails.features.map((item, index) => {
                    return (
                      <div key={index} className="facilitie-item">
                        {item.name === "Object Information" && (
                          <div className="margin-bottom">
                            <h5 className="normal-title">
                              {Dictionary.hotelContactInformation}
                            </h5>
                            <div dir="ltr">
                              <ul className="float-row no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}:{detailsItem.value}{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {item.name === "Building Information" && (
                          <div className="margin-bottom">
                            <h5 className="normal-title">
                              {Dictionary.SpecificationsForHotelBuildings}
                            </h5>
                            <div dir="ltr">
                              <ul className=" float-row  no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}:{detailsItem.value}{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {item.name === "Payment" && (
                          <div className="margin-bottom">
                            <h5 className="normal-title">
                              {Dictionary.ValidFinancialCreditsForHotelUse}
                            </h5>
                            <div dir="ltr">
                              <ul className=" float-row  no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {item.name === "Facilities" && (
                          <div className="margin-bottom" id="hotel_facilities">
                            <h5 className="normal-title">
                              {Dictionary.hotelFacilities}
                            </h5>
                            <div dir="ltr">
                              <ul className=" float-row  no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {item.name === "Room Facilities" && (
                          <div className="margin-bottom">
                            <h5 className="normal-title">
                              {Dictionary.roomFacilities}
                            </h5>
                            <div dir="ltr">
                              <ul className=" float-row  no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {item.name === "Distances" && (
                          <div className="margin-bottom">
                            <h5 className="normal-title">
                              {Dictionary.DistanceFromHotelToPlaces}
                            </h5>
                            <div dir="ltr">
                              <ul className="float-row  no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}:{detailsItem.value} m{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : null}

              {
                hotelDetails.paragraphs && hotelDetails.paragraphs.length > 0 && (
                  <div id="hotel_details">
                    <div
                      className="card card-padding bg-white box-border margin-bottom blue-border-right"
                      dir="ltr"
                    >
                      <h5 className="normal-title body-direction">
                        {" "}
                        {Dictionary.hotelDetails}
                      </h5>
                      <div className="en-font">
                        <div className="textContent">
                          {hotelDetails.paragraphs.map((item, index) => {
                            return (
                              <div key={index}>
                                <div>
                                  <b className="title">{item.title}</b>
                                </div>
                                <div>
                                  <p>{item.para}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )
                // hotel paragraph
              }

              <div className="box-border map-holder">
                <ShowMap data={[this.props.hotelDetails]} />
              </div>
              {LayoutConfig.themeClassName === "style-2" ? <br/> : <div className="result-page-search-bar hotel-detail-searchholder margin-bottom">
                <div className="search-form-header">
                  {Dictionary.selectedHotelAndDates}
                  <button
                    type="button"
                    className="button dark-btn margin-start full-width-mobile"
                    onClick={this.toggleSearch}
                  >
                    {this.state.showSearch
                      ? Dictionary.close
                      : Dictionary.modifySearch}
                  </button>
                </div>
                {this.state.showSearch && (
                  <div className="detail-search-holder">
                    <SearchRoom />
                  </div>
                )}
              </div>}

              <div className="room-select-card" id="roomSelectSection" ref={this.roomsHolder} >
                <h5 className="normal-title">{Dictionary.selectRoom} </h5>
                <div id={"roomSelect"} className={"select-room-wrapper"}>
                  {roomLayout}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const dispatchMapProp = (dispatch) => ({
  getHotelDetailsAsync: (params) => {
    dispatch(getHotelDetailsAsync(params));
  },
  showError: (param) => {
    dispatch(showError(param));
  },
  saveParameters: (param) => {
    dispatch(saveParameters(param));
  },
  getPreReserve: (param) => {
    dispatch(getPreReserve(param));
  },
  setCancelationRoom: (param) => {
    dispatch(setCancelationRoom(param));
  },
  setSafarmarketPixelValue:(data)=>dispatch(setSafarmarketHotelPixel(data))
});
const stateMapProp = (state) => ({
  hotelDetails: state.HotelSearch.selectedHotel,
  error: state.VisualData.error,
  currencyCode: state.UserData.Currency,
  params: state.HotelSearch.parameters,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(stateMapProp, dispatchMapProp)(withRouter(HotelDetail));
