import axios from "axios";
import {
  ServerAddress,
  Payment as PaymentURL,
  Header,
  Hotel as HotelURL,
  Flight as FlightURL
} from "../Enum/Urls";
import { Payment } from "../Enum/Models";
import { showError, editMessage } from "./VisualDataAction";
import { bookhotel } from "./HotelAction";
//import actions
import { getReserveByIdFlight,getDirectTicketByID,getDirectTicketByIdLoading } from "./FlightAction";
import { getReserveByID } from "./HotelAction";
import { getReserveByIdPackage,confirmPackage } from "./PackageAction";
export const setBankList = (data) => ({ type: "SetBanks", banksInfo: data });
export const setTokenId = (tokenId) => ({ type: "SetTokenID", tokenId });
export const gatewayReportAsync = (data) => ({
  type: "VoucherDetailPayment",
  result: { ...data },
});
//Sync methods
export const getBankList = (params) => {
  return (dispatch) => {
    let token = localStorage.getItem("userToken");
    let HeaderAthu;
    if (token) {
      HeaderAthu = { Authorization: `Bearer ${token}`, ...Header };
    } else {
      HeaderAthu = { ...Header };
    }
    if (params.acceptLanguage) {
      HeaderAthu["Accept-Language"] = params.acceptLanguage;
      delete params.acceptLanguage;
    }
    axios({
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Payment}${PaymentURL.GetBanksInformation}`,
      params: params,
      headers: HeaderAthu,
    })
      .then((response) => {
        dispatch(setBankList(response.data.result));
      })
      .catch((error) => {
        try {
          dispatch(
            showError({
              visibility: true,
              content: error.response.data.error.message,
            })
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};
export const setBankID = (param) => {
  return (dispatch) => {
    let token = localStorage.getItem("userToken");
    let HeaderAthu;
    if (token) {
      HeaderAthu = { Authorization: `Bearer ${token}`, ...Header };
    } else {
      HeaderAthu = { ...Header };
    }
    axios({
      method: "post",
      url: `${ServerAddress.Type}${ServerAddress.Payment}${PaymentURL.MakeToken}`,
      data: param,
      headers: HeaderAthu,
    })
      .then((response) => {
        dispatch(setTokenId(response.data.result.tokenId));
        dispatch(editMessage(true));
      })
      .catch((error) => {
        try {
          dispatch(
            showError({
              visibility: true,
              content: error.response.data.error.message,
            })
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};
export const gatewayReport = (params) => {
  return (dispatch) => {
    axios({
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Payment}${PaymentURL.GatewayReport}`,
      params,
      headers: { ...Header },
    })
      .then((response) => {
        dispatch(gatewayReportAsync(response.data.result));
      })
      .catch((error) => {
        try {
          dispatch(
            showError({
              visibility: true,
              content: error.response.data.error.message,
            })
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};

export const GetReserveById = (params) => {
  console.clear();
  console.log("getReserveById run!", params.toString());
  return (dispatch) => {
    axios({
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GETType}`,
      params,
      headers: { ...Header },
    }).then((response) => {
      if (response.data.result.type === "FlightGlobal") {
        dispatch(getReserveByIdFlight(params));
      } else if (response.data.result.type === "HotelGlobal") {
        dispatch(getReserveByID(params));
      } else if (response.data.result.type ==="Package"){
        dispatch(getReserveByIdPackage(params));
      }
    }).catch((error) => {
      try {
        dispatch(
          showError({
            visibility: true,
            content: error.response.data.error.message,
          })
        );
      } catch (e) {
        dispatch(
          showError({
            visibility: true,
            content: "Internal Error,Please refresh the page",
          })
        );
      }
    });
  };
};
export const paymentByMethod = (params) => {
  return (dispatch) => {
    axios({
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GETType}`,
      params,
      headers: { ...Header },
    }).then((response) => {
      if (response.data.result.type === "FlightGlobal") {
        axios({
            method:"get",
            params,
            url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetReserveByID}`,
            headers:{...Header}
        })
        .then( res => {
          if ( res.data.result.reserveStatus === Payment.PaymentSuccessful){
            dispatch(getDirectTicketByIdLoading(true));
            dispatch(
              getDirectTicketByID({
                reserveId: params.get("reserveId"),
                userName: params.get("username"),
              })
            );
          }
        });
      } else if (response.data.result.type === "HotelGlobal") {
        dispatch(
          bookhotel({
            reserveId: params.get("reserveId"),
            userName: params.get("username"),
          })
        );
      } else if (response.data.result.type === "Package"){
        dispatch(
          confirmPackage({
            reserveId: +params.get("reserveId"),
            username: params.get("username"),
          })
        );
      }
    });
  };
};