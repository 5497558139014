import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import axios from "axios";
import { ServerAddress, Header,User } from "../../Enum/Urls";
import ChangePassword from "../../components/Admin/ChangePassword";
import "react-day-picker/lib/style.css";
import { createUser } from "../../Actions/UserAction";
import { getHotelReserve } from "../../Actions/HotelAction";
import queryString from "query-string";
import { editMessage } from "../../Actions/VisualDataAction";
import numeral from "numeral";
import { Payment } from "../../Enum/Models";
import Price from "../../components/Common/Price";
import ProfileEdit from "../../components/Admin/ProfileEdit";

class Index extends React.Component {
  ref = null;
  state = {
    userInformation: this.props.userData,
    selectedDay: undefined,
    selectedItem: 0,
    lockItem: true,
    MessageContainerShow: false,
    MessageContainerContent: null,
    showError: false,
    activationLoading:false,
    activationMessage:undefined,
    showModal:false
  };
  closeModal = () =>{
    if (!this.state.activationLoading){
      this.setState({showModal:false});
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let parameters = queryString.parse(this.props.location.search);
    if (parameters.mode) {
      this.setState({ selectedItem: parseInt(parameters.mode) });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.selectedItem !== prevState.selectedItem) {
      if (this.state.selectedItem === 1) {
        //call hotel service
        this.props.editMessage(false);
        this.props.getHotelReserve("HotelGlobal");
      }
      if (this.state.selectedItem === 2) {
        //call hotel service
        this.props.editMessage(false);
        // if (LayoutConfig.themeClassName === 'naminTravel-theme--%%%--'){
        //   let emailAdress = this.props.userData && this.props.userData.emailAddress;
        //   this.props.getHotelReserve("NaminTravelFlights", emailAdress);
        // }else{
          this.props.getHotelReserve("FlightGlobal");
        // }
      }
      this.props.editMessage(false);
    }
    if (prevProps.userData !== this.props.userData){
      this.setState({
        userInformation: this.props.userData
      })
    }
  }
  bindNode = (node) => {
    if (node) {
      this.ref = node.state;
    }
  };
  changeFeild(e) {
    let value = e.target.value;
    let name = e.target.name;
    let input = {...this.state.userInformation};
    input[name] = value;
    this.setState({
      userInformation: { ...input },
    });
  }
  resendEmail =() => {
    this.setState({
      activationLoading:true,
      showModal:true
    });
    const params = {"emailAddress":this.props.userData.emailAddress};
    axios.post(`${ServerAddress.Type}${ServerAddress.User}${User.SendEmailActivation}`, params, {headers: {...Header,},})
    .then(({ data }) => {
      if (data.error) {
         this.setState({
            activationLoading:false,
            activationMessage:{text:data.error.message,success:data.success}
          });
        }else{
          this.setState({
            activationLoading:false,
            activationMessage:{text:this.props.Dictionary.anActivationLinkHasBeenSentToYourEmail,success:data.success}
          });
       }
    })
    .catch((error) => {
      try {
        this.setState({
          activationLoading:false,
          activationMessage:{text:error.response.data.error.message,success:false}
        });
      } catch (e) {
        this.setState({
          activationLoading:false,
          activationMessage:{text:"Internal Error,Please refresh the page",success:false}
        });
      }
    });
  }
  render() {
    const { Dictionary,LAN } = this.props;
    const { userData } = this.props;
    return (
      <div className="section-vertical-padding">
        <div className="profileContainer">
          <div className="page-container">
            <h2 className="page-title"> {Dictionary.userProfile} </h2>
            <div className="bg-white box-shadow">
              <div className="clearfix tab-holder text-center">
                <button
                  type="button"
                  onClick={() => this.setState({ selectedItem: 0 })}
                  className={
                    this.state.selectedItem === 0
                      ? "tab-s active-tab-s"
                      : "tab-s"
                  }
                >
                  {Dictionary.editInformation}
                </button>
                <button
                  type="button"
                  onClick={() => this.setState({ selectedItem: 1 })}
                  className={
                    this.state.selectedItem === 1
                      ? "tab-s active-tab-s"
                      : "tab-s"
                  }
                >
                  {Dictionary.reservedHotels}
                </button>
                <button
                  type="button"
                  onClick={() => this.setState({ selectedItem: 2 })}
                  className={
                    this.state.selectedItem === 2
                      ? "tab-s active-tab-s"
                      : "tab-s"
                  }
                >
                  {Dictionary.reservedFlights}
                </button>
                <button
                  type="button"
                  onClick={() => this.setState({ selectedItem: 3 })}
                  className={
                    this.state.selectedItem === 3
                      ? "tab-s active-tab-s"
                      : "tab-s"
                  }
                >
                  {Dictionary.changePassword}
                </button>
              </div>
              {this.state.selectedItem === 0 && (
                <div className="card-padding">
                  <div>
                    <h3 className="normal-title">
                      <span className="itours-icon icon-large user-profile margin-end" />
                      <span className="inline-block">
                        {Dictionary.userInformations}
                      </span>
                    </h3>


                    {LAN === "IR" ? null
                    // <div className="margin-top margin-bottom">
                    //   <label className="form-label">
                    //     {Dictionary.phoneNumber} :{" "}
                    //   </label>
                    //   <b className="inline-block margin-start" dir='ltr'>
                    //     {userInformation.phoneNumber}
                    //   </b>
                    // </div>
                    :(
                      <>
                    <div>
                      <label className="form-label">
                        {Dictionary.email} :{" "}
                      </label>
                      <b className="inline-block margin-start">
                        {userData.emailAddress}
                      </b>
                    </div>
                    <div className="varified margin-bottom">
                      {!userData.isEmailConfirmed ? (
                        <>
                          <div className={`itours-modal ${this.state.showModal ? "itours-modal visible" : ""}`}>
                            <div className="modal-overlay" onClick={this.closeModal} />
                            <div className="modal-inner small-width text-center">
                              <div className="modal-main">
                                {this.state.activationLoading ? (
                                  <div className="loading-outer-container">
                                    <div>
                                      <div className="loading-inner-container">
                                        <div className="item-1"></div>
                                        <div className="item-2"></div>
                                        <div className="item-3"></div>
                                        <div className="item-4"></div>
                                        <div className="item-5"></div>
                                      </div>
                                      <div className="text-center">
                                        {Dictionary.loadingData} ...
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  this.state.activationMessage && 
                                  <div>
                                    <br/><br/>
                                      {this.state.activationMessage.success?
                                      <span className="itours-icon check-green icon-xlarge" />
                                      :
                                      <span className="itours-icon error-icon icon-xlarge" />
                                      }
                                    <div className="margin-top margin-bottom">
                                    {this.state.activationMessage.text}.
                                    </div>
                                    <br/>
                                  </div>
                                )}
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="button color-1-btn"
                                  onClick={this.closeModal}
                                  disabled={this.state.activationLoading}
                                >
                                  {Dictionary.ok}
                                </button>
                              </div>
                            </div>
                          </div>
                          <span className="purple">
                            {Dictionary.yourEmailAddressIsNotConfirmedPleaseCheckEmailrecivedFromUs}
                            <button type="button" className="link-style-btn margin-start" onClick={this.resendEmail} >
                              {Dictionary.resendEmail}
                            </button>
                          </span>
                        </>
                      ) : (
                        <span className="green">
                          {Dictionary.YourEmailAddressIsConfirmed}
                        </span>
                      )}
                    </div>                      
                      </>
                    )
                    }

                    {this.state.lockItem ? (
                      <>
                        <div>
                          <label className="form-label">
                            {Dictionary.firstName} :{" "}
                          </label>
                          <b className="inline-block margin-start">
                            {userData.firstName.length === 0
                              ? Dictionary.notRegistered
                              : userData.firstName}
                          </b>
                        </div>
                        <div>
                          <label className="form-label">
                            {Dictionary.lastName} :{" "}
                          </label>
                          <b className="inline-block margin-start">
                            {userData.lastName.length === 0
                              ? Dictionary.notRegistered
                              : userData.lastName}
                          </b>
                        </div>
                        <div>
                          <label className="form-label">
                            {Dictionary.gender}:{" "}
                          </label>
                          <b className="inline-block margin-start">
                            {userData.gender
                              ? Dictionary.male
                              : Dictionary.female}
                          </b>
                        </div>
                        <div>
                          <label className="form-label">
                            {Dictionary.phoneNumber} :{" "}
                          </label>
                          <b className="inline-block margin-start">
                            {userData.phoneNumber && userData.phoneNumber.length !== 0
                              ? <span className="inline-block" dir="ltr">{userData.phoneNumber}</span>
                              : Dictionary.notRegistered}
                          </b>
                        </div>                          
                        {LAN === "IR"?(                            
                            <div>
                              <label className="form-label">
                                {Dictionary.email} :{" "}
                              </label>
                              <b className="inline-block margin-start">
                                {userData.emailAddress && userData.emailAddress.length !== 0
                                  ? <span className="inline-block" dir="ltr">{userData.emailAddress}</span>
                                  : Dictionary.notRegistered}
                              </b>
                            </div>  
                          ):null}                        
                        <div>
                          <label className="form-label">
                            {Dictionary.birthday}:{" "}
                          </label>
                          <b className="inline-block margin-start">
                            {userData.birthDay == null
                              ? Dictionary.notRegistered
                              : userData.birthDay.split("T")[0]}
                          </b>
                        </div>
                        <div>
                          <label className="form-label">
                            {Dictionary.nationality} :{" "}
                          </label>
                          <b className="inline-block margin-start">
                            {userData.nationalityId == null
                              ? Dictionary.notRegistered
                              : userData.nationalityId}
                          </b>
                        </div>
                        <button
                          className="button color-1-btn margin-top"
                          type="button"
                          onClick={() => this.setState({ lockItem: false })}
                        >
                          {Dictionary.editInformation}
                        </button>
                      </>
                    ) : (
                      <ProfileEdit userData={userData} cancel={() => this.setState({ lockItem: true })} />
                    )}
                  </div>
                  {this.state.MessageContainerShow ? (
                    <div className="errorShow">
                      {this.state.MessageContainerContent}
                    </div>
                  ) : null}
                </div>
              )}
              {this.state.selectedItem === 3 && (
                <div className="card-padding">
                  <div className="information">
                    <h3 className="normal-title">
                      <span className="itours-icon icon-large change-password margin-end" />
                      <span className="inline-block">
                        {Dictionary.changePassword}
                      </span>
                    </h3>
                    <ChangePassword />
                  </div>
                  {this.state.MessageContainerShow ? (
                    <div className="errorShow">
                      {this.state.MessageContainerContent}
                    </div>
                  ) : null}
                </div>
              )}
              {this.state.selectedItem === 1 && (
                <div className="card-padding">
                  <h3 className="normal-title">
                    <span className="itours-icon icon-large hotel-ticket margin-end" />
                    <span className="inline-block">
                      {Dictionary.reservedHotels}
                    </span>
                  </h3>

                  {!this.props.responded ? (
                    <div>
                      {Dictionary.loadingData}
                      ...
                    </div>
                  ) : (
                    <div className="itours-table-holder responsive-table no-scroll-responsive-table">
                      <table className="itours-table">
                        <thead>
                          <tr>
                            <th>{Dictionary.id}</th>
                            <th> {Dictionary.checkIn} </th>
                            <th>{Dictionary.fullName}</th>
                            <th>{Dictionary.hotelName}</th>
                            <th>{Dictionary.price}</th>
                            <th>{Dictionary.status}</th>
                            <th />
                            <th />
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.hotelVocher.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.id}{" "}
                                </span>
                                <span> {item.id} </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.checkIn}{" "}
                                </span>
                                <span className="text-end"> {item.date} </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.fullName}{" "}
                                </span>
                                <span className="text-end">
                                  {" "}
                                  {item.gender ? "Mr" : "Mrs"} {item.firstName}{" "}
                                  {item.lastName}{" "}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.hotelName}{" "}
                                </span>
                                <span className="text-end"> {item.hotel} </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.price}{" "}
                                </span>
                                <span
                                  className="nowrap price-holder text-end"
                                  dir="ltr"
                                >
                                  {" "}
                                  {numeral(item.salePrice).format("0,0")}{" "}
                                  {item.currency}{" "}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.status}{" "}
                                </span>
                                {item.status === "Issued" ? (
                                  <span className="green nowrap text-end">
                                    {Dictionary.Issued}
                                  </span>
                                ) : item.status === "Pending" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.paymentPending}
                                  </span>
                                ) : item.status === "Canceled" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.canceled}
                                  </span>
                                ) : item.status === "Refunded" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.Refunded}
                                  </span>
                                ) : item.status === "Failed" ? (
                                  <span className="purple nowrap text-end">
                                    {Dictionary.WebServiceUnsuccessful}
                                  </span>
                                ) : item.status === "PaymentSuccessful" ? (
                                  <span className="green nowrap text-end">
                                    {Dictionary.successfulPayment}
                                  </span>
                                ) : item.status === "WebServiceUnsuccessful" ? (
                                  <span className="purple nowrap text-end">
                                    {Dictionary.WebServiceUnsuccessful}
                                  </span>
                                ) : item.status === "Registered" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.registered}
                                  </span>
                                ) : item.status === "Undefined" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.undefined}
                                  </span>
                                ) : (
                                  <span className="nowrap text-end">
                                    {Dictionary.undefined}
                                  </span>
                                )}
                              </td>

                              {item.status === Payment.Registered ||
                              item.status === Payment.Pending ? (
                                <td>
                                  <a
                                    className="payment button red-btn nowrap"
                                    href={`${ServerAddress.Type}${ServerAddress.localServer}/payment/reserve?reserveId=${item.id}`}
                                  >
                                    {Dictionary.payment}
                                  </a>
                                </td>
                              ) : (
                                <td className="nowrap">---</td>
                              )}
                              {item.status === Payment.Registered ||
                              item.status === Payment.Issued ? (
                                <td>
                                  <a
                                    target="_blank"
                                    className=" button color-1-btn nowrap"
                                    rel="noopener noreferrer"
                                    href={`http://92.222.136.211:8080/en/Hotel/Voucher?reserveId=${item.id}&username=${userData.userName}`}
                                  >
                                    {Dictionary.GetVoucher}
                                  </a>
                                </td>
                              ) : (
                                <td className="nowrap">---</td>
                              )}
                              <td>
                                <Link
                                  className="nowrap content-link has-left-arrow"
                                  to={`/profile/details?userName=${userData.userName}&reserveId=${item.id}`}
                                >
                                  {Dictionary.reservationDetails}
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {this.state.selectedItem === 2 && (
                <div className="card-padding">
                  <h3 className="normal-title">
                    <span className="itours-icon icon-large flight-ticket margin-end" />
                    <span className="inline-block">
                      {Dictionary.reservedFlights}
                    </span>
                  </h3>

                  {!this.props.responded ? (
                    <div>
                      {Dictionary.loadingData}
                      ...
                    </div>
                  ) : (
                    <div className="itours-table-holder responsive-table no-scroll-responsive-table">
                      <table className="itours-table">
                        <thead>
                          <tr>
                            <th>{Dictionary.id}</th>
                            <th>{Dictionary.departure} </th>
                            <th>{Dictionary.return} </th>
                            <th>{Dictionary.fullName} </th>
                            <th>{Dictionary.email} </th>
                            <th>{Dictionary.phoneNumber} </th>
                            <th>{Dictionary.price} </th>
                            <th>{Dictionary.status} </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.flightVoucher.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.id}{" "}
                                </span>
                                <span> {item.id} </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.departureDate}{" "}
                                </span>
                                <span className="text-end">
                                  {" "}
                                  {moment(
                                    item.departureDate,
                                    "YYYY-MM-DDTHH:mm"
                                  ).format("YYYY/MM/DD HH:mm")}{" "}
                                </span>
                              </td>
                              <td>
                                {item.returnDate ? <><span className="mobile-label">
                                  {" "}
                                  {Dictionary.returnDate}{" "}
                                </span>
                                <span className="text-end">
                                  {" "}
                                  {moment(
                                    item.returnDate,
                                    "YYYY-MM-DDTHH:mm"
                                  ).format("YYYY/MM/DD HH:mm")}{" "}
                                </span>
                                </>
                                :"--"}
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.fullName}{" "}
                                </span>
                                <span className="en-font text-end">
                                  {" "}
                                  {item.gender ? "Mr" : "Mrs"} {item.firstName}{" "}
                                  {item.lastName}{" "}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.email}{" "}
                                </span>
                                <span dir="ltr" className="en-font text-end">
                                  {" "}
                                  {item.email}{" "}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.phoneNumber}{" "}
                                </span>
                                <span dir="ltr"> {item.phoneNumber} </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.price}{" "}
                                </span>
                                <span
                                  className="nowrap price-holder text-end"
                                  dir="ltr"
                                >
                                  <Price currency={item.currency.code}>
                                    {item.salePrice}
                                  </Price>
                                </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.status}{" "}
                                </span>
                                {item.status === "Issued" ? (
                                  <span className="green nowrap text-end">
                                    {Dictionary.Issued}
                                  </span>
                                ) : item.status === "Pending" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.paymentPending}
                                  </span>
                                ) : item.status === "Canceled" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.canceled}
                                  </span>
                                ) : item.status === "Refunded" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.Refunded}
                                  </span>
                                ) : item.status === "Failed" ? (
                                  <span className="purple nowrap text-end">
                                    {Dictionary.WebServiceUnsuccessful}
                                  </span>
                                ) : item.status === "PaymentSuccessful" ? (
                                  <span className="green nowrap text-end">
                                    {Dictionary.successfulPayment}
                                  </span>
                                ) : item.status === "WebServiceUnsuccessful" ? (
                                  <span className="purple nowrap text-end">
                                    {Dictionary.WebServiceUnsuccessful}
                                  </span>
                                ) : item.status === "Registered" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.registered}
                                  </span>
                                ) : item.status === "Undefined" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.undefined}
                                  </span>
                                ) : (
                                  <span className="nowrap text-end">
                                    {Dictionary.undefined}
                                  </span>
                                )}
                              </td>
                              <td>
                                <Link
                                  className="nowrap content-link has-left-arrow"
                                  to={`/profile/details?userName=${userData.userName}&reserveId=${item.id}`}
                                >
                                  {Dictionary.reservationDetails}
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userData: state.UserData.userData,
  hotelVocher: state.HotelSearch.reserveHotel,
  responded: state.VisualData.Responded,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  flightVoucher: state.FlightSearch.reserveFlight,
});
const mapActionToDispatch = (dispatch) => ({
  createUser: (userInfo) => dispatch(createUser(userInfo)),
  getHotelReserve: (type,email) => dispatch(getHotelReserve(type,email)),
  editMessage: (param) => {
    dispatch(editMessage(param));
  },
});
export default connect(mapStateToProps, mapActionToDispatch)(Index);
