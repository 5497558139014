import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Price from "../Common/Price";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Flight as FlightURL, Header, ServerAddress } from "../../Enum/Urls";
import { showError,toggleAccountWindow } from "../../Actions/VisualDataAction";
import {setNaminTravelSelectedFlight} from "../../Actions/FlightAction";
import FlightItinerary from "./FlightItinery";
import RouteAllSegments from "./RouteAllSegments";
import airplane from "../../image/airplane.svg";
import { Collapse ,Tag} from "antd";
class FlightItem extends React.Component {
  static propTypes = {
    model: PropTypes.any.isRequired,
  };
  state = {
    showDetails: false,
    showRules: false,
    rulesContent: null,
    activeTab: "flightItinery"
    //,requiredLoginModalVisibility:false
  };
  getRules = (key) => {
    axios({
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetRules}`,
      params: { key },
      headers: { ...Header },
    })
      .then((response) => {
        this.setState({
          rulesContent: response.data.result,
        });
      })
      .catch((error) => {
        try {
          this.props.showError({
            visibility: true,
            content: error.response.data.error.message,
          });
        } catch (e) {
          this.props.showError({
            visibility: true,
            content: "Internal Error,Please refresh the page",
          });
        }
      });
  };
  toggleDetails = () => {
    this.setState((prevstate) => {
      return {
        showDetails: !prevstate.showDetails,
      };
    });
  };
  toggleRules = (value) => {
    if (value) {
      this.getRules(this.props.model.key);
    }
    this.setState({
      showRules: value,
    });
  };
  tabClick = (item) => {
    this.setState({
      activeTab: item,
    });
    if (item === "flightRules") {
      this.toggleRules(true);
    }
  };
  bookFlight = (key)=>{
    // if (process.env.REACT_APP_THEME !=='NAMINTRAVELCOM%%%%%' || this.props.isEmailConfirmed){
      let travelType = 'flight';
      if(this.props.domestic){
        travelType = 'domesticflight';
      }
      this.props.history.push(
        `/${travelType}/getpassenger?key=${key}`
      );
    // }else{
    //   this.setState({...this.state,requiredLoginModalVisibility:true});
    //   this.props.setNaminTravelSelectedFlight({key:key,extraBaggage:false});
    // }
  }
  bookFlightWithExtraBaggage = (key)=>{
    // if (process.env.REACT_APP_THEME !=='NAMINTRAVELCOM%%%%%' || this.props.isEmailConfirmed){
      let travelType = 'flight';
      if(this.props.domestic){
        travelType = 'domesticflight';
      }
      this.props.history.push(
        `/${travelType}/getpassenger?key=${key}&extraBaggage=true`
      );
    // }else{
    //   this.setState({...this.state,requiredLoginModalVisibility:true});
    //   this.props.setNaminTravelSelectedFlight({key:key,extraBaggage:true});
    // }
  }
  openDetailsAndShowRoules = ()=>{
    this.setState({...this.state,showDetails:true});
    this.tabClick("flightRules");
  }

  render() {
    const { Panel } = Collapse;
    const { Dictionary } = this.props;
    let baggageAvailable = true;
    let airportChange = false;

    const flightType = this.props.model.flightType && <Tag 
      className="margin-end-light"
      color={this.props.model.flightType==="Systemic"?"purple":"orange"}
    >
      {this.props.model.flightType==="Systemic"?
        Dictionary.Systemic
      :this.props.model.flightType==="Charter"?
        Dictionary.Charter
      : this.props.model.flightType}
    </Tag>

    const availableSeats = this.props.model.availableSeats && <span className="purple margin-end-light font-14 xs-font-12">{Dictionary.availableSeats}: {this.props.model.availableSeats}  </span>
    this.props.model.airItinerary.forEach((item) => {
      item.flightSegments.forEach((segmentItem, index) => {
        segmentItem.baggageInformation.filter(x=>x.passengerType !== "INF").forEach((baggageItem) => {
          if (baggageItem.baggageAllowance < 1) {
            baggageAvailable = false;
          }
        });
        if (index < item.flightSegments.length - 1) {
          if (
            !(
              segmentItem.arrivalAirport.locationCode ===
              item.flightSegments[index + 1].departureAirport.locationCode
            )
          ) {
            airportChange = true;
          }
        }
      });
    });
    let extraBaggageBadge = null;
    if (process.env.REACT_APP_THEME ==='NAMINTRAVELCOM' && this.props.model.isEthnic){
      let extraBaggagePrase = Dictionary.withExtraBaggage;
      if (this.props.model.airItinerary[0].flightSegments[0].marketingAirline.code === "TK"){
        extraBaggagePrase = `${Dictionary.with15KExtraBaggage} (${Dictionary.onlyAdultsAndChildren})`;
      }
      extraBaggageBadge = <span className="extraBaggageBadge margin-end-light">{extraBaggagePrase}</span> ;
    }
    return (
      <>
        {/* {this.state.requiredLoginModalVisibility && <div className="itours-modal login-to-book-modal visible">
            <div className="modal-overlay" onClick={()=>{this.setState({...this.state,requiredLoginModalVisibility:false})}} />
            <div className="modal-inner small-width">
              <div className="modal-main">
                <span className="itours-icon user-login icon-xxlarge" />
                <h3 className="normal-title">{this.props.userName ? "please Confirm Your Email To Continue" :Dictionary.pleaseLoginToContinue} ...</h3>
                <p className="text-center gray semi-bold margin-bottom-large">
                  <span className="itours-icon info-icon icon-small margin-end-light" />
                  {this.props.userName ? Dictionary.pleaseConfirmYourEmailThenReloadThisPageToContinueTheBooking :Dictionary.toProceedWithBookingAtSpecialPriceYouMustLogIn}
                </p>
                <div className="text-center margin-top-large margin-bottom-large">
                  {this.props.userName ?
                  <button type="button" className="button blue-btn large-btn" onClick={()=>{window.location.reload();}} >
                    {Dictionary.Reload}
                  </button>
                  :<button
                    type="button"
                    className="button red-btn large-btn"
                    onClick={()=>{
                      this.setState({...this.state,requiredLoginModalVisibility:false});
                      this.props.toggleAccountWindow();
                    }}
                  >
                    {Dictionary.login}
                  </button>}               
                </div>
              </div>
            </div>
          </div>
        } */}

        {this.state.showDetails && <div className="overlay" onClick={()=>{this.setState({showDetails:false})}} />}
        <div className="flight-result-item" >
          <div className="item-main">
            <div className="item-main-inner">
              <div className={"content"}>
                {baggageAvailable && (
                  <div className="included-baggage-mark">
                    <span className="itours-icon check-green icon-xsmall margin-end-light" />
                    <span> {Dictionary.BaggageIncluded} </span>
                  </div>
                )}
                {this.props.model.airItinerary.map((item, index) => (
                  <RouteAllSegments key={item.flightSegments[0].flightNumber} model={item} />
                  // <Route key={index} model={item} />
                ))}
                {airportChange && (
                  <div className="has-airport-change">
                    <span className="itours-icon error2-icon icon-small inline-block-middle margin-end-light" />
                    <span className="purple inline-block-middle font-12 semi-bold">
                      {Dictionary.StopForAirportChange}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.state.showDetails && (
            <div className="flightDetails margin-bottom">
              <div className="flight-detail-main">            
                <div className="itours-tab-holder">
                  <div className="itours-tab-list">
                    <button
                      className={
                        this.state.activeTab === "flightItinery"
                          ? "itours-tab-btn active"
                          : "itours-tab-btn"
                      }
                      onClick={() => this.tabClick("flightItinery")}
                    >
                      {Dictionary.flightItinery}
                    </button>
                    <button
                      className={
                        this.state.activeTab === "baggageInfo"
                          ? "itours-tab-btn active"
                          : "itours-tab-btn"
                      }
                      onClick={() => this.tabClick("baggageInfo")}
                    >
                      {Dictionary.baggageInfo}
                    </button>
                    <button
                      className={
                        this.state.activeTab === "flightFare"
                          ? "itours-tab-btn active"
                          : "itours-tab-btn"
                      }
                      onClick={() => this.tabClick("flightFare")}
                    >
                      {Dictionary.flightFare}
                    </button>
                    <button
                      className={
                        this.state.activeTab === "flightRules"
                          ? "itours-tab-btn active"
                          : "itours-tab-btn"
                      }
                      onClick={() => this.tabClick("flightRules")}
                    >
                      {Dictionary.flightRules}
                    </button>
                  </div>
                  <div className="itours-tab-contents">
                    {this.state.activeTab === "flightItinery" ? (
                      <div className="detail-flight">
                        <FlightItinerary model={this.props.model.airItinerary} />
                      </div>
                    ) : (
                      false
                    )}
                    {this.state.activeTab === "baggageInfo" ? (
                      <div className="flight-baggage-detail">
                        {this.props.model.airItinerary.map((item) => {
                          return (
                            <div
                              className="detail-flight-item "
                              key={item.flightSegments[0].flightNumber}
                            >
                              {item.flightSegments.map((item, index) => {
                                return (
                                  <div
                                    className="baggegesInformation"
                                    dir="ltr"
                                    key={index}
                                  >
                                    <div className="text-left">
                                      <div>
                                        <b>{item.departureAirport.cityName}</b> -{" "}
                                        <small>
                                          {item.departureAirport.countryName}
                                        </small>
                                      </div>
                                    </div>
                                    <div className={"airplaneIcon"}>
                                      <img
                                        src={airplane}
                                        alt={"airplane"}
                                        title={"airplane"}
                                      />
                                    </div>
                                    <div className="text-left">
                                      <div>
                                        <b>{item.arrivalAirport.cityName}</b> -{" "}
                                        <small>
                                          {item.arrivalAirport.countryName}
                                        </small>
                                      </div>
                                    </div>
                                    <div>
                                      {item.baggageInformation.map(
                                        (baggageItem, baggageIndex) => (
                                          <div key={baggageIndex}>
                                            {baggageItem.baggageAllowance}{" "}
                                            {baggageItem.unitType}{" "}
                                            {this.props.hasExtraBaggage && <span>(
                                              +{item.extraBaggageInformation.filter(x=>x.passengerType === baggageItem.passengerType)[0].baggageAllowance}  {baggageItem.unitType}{" "} {Dictionary.extraBaggage}
                                            )</span>}
                                            <span> {Dictionary.for} </span>
                                            {baggageItem.passengerType === "ADT" && (
                                              <span>{Dictionary.adult}</span>
                                            )}
                                            {baggageItem.passengerType === "CHD" && (
                                              <span>{Dictionary.child}</span>
                                            )}
                                            {baggageItem.passengerType === "INF" && (
                                              <span>{Dictionary.infant}</span>
                                            )}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      false
                    )}
                    {this.state.activeTab === "flightFare" ? (
                      <div className={"fareContainer"}>
                        <div className="title">{Dictionary.fareTotal}</div>
                        <div className="baseFare">
                          <strong>{Dictionary.baseFare}: </strong>
                          <Price currency={this.props.currency}>
                            {this.props.model.priceInfo.baseFare}
                          </Price>
                        </div>
                        {this.props.model.priceInfo.fess && (this.props.model.priceInfo.fess.length > 0) && (
                          <div className="fees">
                            <strong>{Dictionary.fees}: </strong>
                            <Price currency={this.props.currency}>
                              {this.props.model.priceInfo.fess[0].amount}
                            </Price>
                          </div>
                        )}
                        {this.props.model.priceInfo.taxes && (this.props.model.priceInfo.taxes.length > 0) && (<div className="taxes">
                          <strong>{Dictionary.taxes}: </strong>
                          <Price currency={this.props.currency}>
                            {this.props.model.priceInfo.taxes[0].amount}
                          </Price>
                        </div>)}
                        {this.props.hasExtraBaggage && 
                        <div className="extraBaggage">
                          <strong>{Dictionary.extraBaggage}: </strong>
                          <Price currency={this.props.currency}>
                            {this.props.model.priceInfo.extraBaggage}
                          </Price>
                        </div>                      
                        }
                        <hr />
                        <div className="totalPrice">
                          <strong>{Dictionary.totalPrice}: </strong>
                          <Price currency={this.props.currency}>
                            {this.props.model.priceInfo.totalFare}
                          </Price>
                        </div>

                        {this.props.hasExtraBaggage && <div className="totalPrice">
                          <strong>{Dictionary.totalFareWithExtraBaggage}: </strong>
                          <Price currency={this.props.currency}>
                            {this.props.model.priceInfo.totalFareWithExtraBaggage}
                          </Price>
                        </div>}
                      </div>
                    ) : (
                      false
                    )}
                    {this.state.activeTab === "flightRules" ? (
                      <div className="rules-container">
                        {this.state.rulesContent ? (
                          <>
                            {this.state.rulesContent.flightRules.length === 0 ? (
                              <div>{Dictionary.informationNotFound}</div>
                            ) : (
                              <>
                                {this.state.rulesContent.flightRules.map(
                                  (item, index) => {
                                    return (
                                      <div
                                        className="rules-item en-font"
                                        key={index}
                                      >
                                        <div className="rule-title">
                                          FareBase: {item.fareBase}
                                        </div>
                                        <Collapse>
                                          {item.flightRule.map(
                                            (itemIn, indexIn) => {
                                              return (
                                                <Panel
                                                  header={itemIn.ruleTitle}
                                                  key={indexIn}
                                                >
                                                  <p>{itemIn.ruleDescription}</p>
                                                </Panel>
                                              );
                                            }
                                          )}
                                        </Collapse>
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <div>
                            {Dictionary.loadingData}
                            <span className="dots-loading">
                              <span className="dot">.</span>
                              <span className="dot">.</span>
                              <span className="dot">.</span>
                            </span>
                          </div>
                        )}
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                  <div className="hidden-medium hidden-large">
                    <div className={`item-footer ${availableSeats ||flightType ? " multiple_rows":""}`}>
                      <div>
                        <button
                          type="button"
                          onClick={() => this.toggleDetails()}
                          className="button blue-btn flight-detail-btn"
                        >
                          {Dictionary.flightDetails}{" "}
                          <span className={this.state.showDetails ? "rot" : null} />
                        </button>
                        {flightType}
                        {availableSeats}
                      </div>
                      <div className="no-wrap text-end">
                        <b dir="ltr" className="margin-end-half margin-start-light">
                          <Price currency={this.props.currency}>
                            {this.props.model.totalPrice}
                          </Price>
                        </b>
                        {this.props.disableBook ||
                          <>
                            <button
                              type="button"
                              className="button red-btn select-flight-btn has-arrow"
                              onClick={()=>{this.bookFlight(this.props.model.key)}}
                            >
                              {Dictionary.book}
                            </button>

                            {this.props.hasExtraBaggage && <button
                              type="button"
                              className="button green-btn select-flight-btn margin-start-half has-arrow"
                              onClick={()=>{this.bookFlightWithExtraBaggage(this.props.model.key)}}
                            >
                              {Dictionary.BookWithExtraBaggage} (+ <Price currency={this.props.currency}>{this.props.model.priceInfo.extraBaggage}</Price>)
                            </button>}
                            
                            {this.props.hasExtraBaggage && <div className="text-end font-13">
                              {Dictionary.BaggagePerPersonCanBeSelectedDeselectedInTtheNextStep}
                            </div>}
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}          
          <div className={`item-footer ${availableSeats ||flightType ? " multiple_rows":""}`}>
            {/* <div>           
              {this.props.model.key.slice(0, 3) === "009" ?
                <>
                <Tag color="red" className="itours-tag">{Dictionary.nonChangeable}</Tag>
                  <Tag color="red" className="itours-tag">{Dictionary.nonRefundable}</Tag>
                </>
                :null
              }
            </div> */}
            <div>
              <button
                type="button"
                onClick={() => this.toggleDetails()}
                className="button blue-btn flight-detail-btn margin-end-light margin-start-light margin-bottom-light margin-top-light" 
              >
                {Dictionary.flightDetails}{" "}
                <span className={this.state.showDetails ? "rot" : null} />
              </button>
              {flightType}
              {availableSeats}
              {process.env.REACT_APP_THEME ==='NAMINTRAVELCOM%%%%' && this.props.model.isEthnic && <button type="button" className="link-style-btn margin-start red" onClick={this.openDetailsAndShowRoules}>{Dictionary.oneFreeChange} <span className="itours-icon help-icon icon-small" /></button>}
              {extraBaggageBadge}
            </div>

            <div className="no-wrap text-end">
              <b dir="ltr" className="margin-end-half margin-start-light">
                <Price currency={this.props.currency}>
                  {this.props.model.totalPrice}
                </Price>
              </b>
              {this.props.disableBook ||
              <>
              <button
                type="button"
                className="button red-btn select-flight-btn has-arrow"
                onClick={()=>{this.bookFlight(this.props.model.key)}}
              >
                {Dictionary.book}
              </button>
              {this.props.hasExtraBaggage && <button
                type="button"
                className="button green-btn select-flight-btn margin-start-half has-arrow"
                onClick={()=>{this.bookFlightWithExtraBaggage(this.props.model.key)}}
              >
                {Dictionary.BookWithExtraBaggage} (+ <Price currency={this.props.currency}>{this.props.model.priceInfo.extraBaggage}</Price>)
              </button>}
              {this.props.hasExtraBaggage && <div className="text-end font-13">
                {Dictionary.BaggagePerPersonCanBeSelectedDeselectedInTtheNextStep}
              </div>}
              </>
              }



            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  currency: state.UserData.Currency,
  userName : state.UserData.userData.userName,
  isEmailConfirmed : state.UserData.userData.isEmailConfirmed
});
const mapDispatchToProps = (dispatch) => ({
  showError: (param) => dispatch(showError(param)),
  // toggleAccountWindow: () => dispatch(toggleAccountWindow()),
  setNaminTravelSelectedFlight : (data) => dispatch (setNaminTravelSelectedFlight(data))
});
const routerBind = withRouter(FlightItem);
export default connect(mapStateToProps, mapDispatchToProps)(routerBind);
