import {UserModel,Reducers} from '../Enum/Models';
import Currency from '../Enum/Currency';
const userDataStorage=localStorage.getItem("userData");
const userData=userDataStorage? JSON.parse(userDataStorage):UserModel;

const DefaultUserData={token:null,userData:userData,Currency:Currency.getCurrency()};
export const UserData=(state=DefaultUserData,action)=>{
    switch(action.type){
        case "CreateUser":
            return {
                ...state,
                token:action.token,
                userData:{...action.userData}
            };
        case Reducers.CREATEUSER:
            return {
                ...state,
                userData:action.userData
            };

        case Reducers.SETCURRENCY:
            return{
                ...state,
                Currency:action.Currency
            }
        default:
            return state;

    }
}