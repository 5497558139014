import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Logo from '../../image/itours-logo-2.svg';
import classes from './Loading.module.css';

const Loading = props => {
    const {Dictionary} = props;
    let loading = (<div className="logo-loading">
        <img src={Logo} alt={'iTours logo'} title={'iTours logo'} />
    </div>);
    if (props.style2){
        loading = (<div className={classes.ldsEllipsis}>
            <div />
            <div />
            <div />
            <div />
        </div>);
    }
    const wrapperClass = [];
    if (props.fullPage){
        wrapperClass.push("loading-full-page");
    }
    if (props.className){
        wrapperClass.push(props.className);
    }
    if (props.style2){
        wrapperClass.push ("style2");
    }
    return(
        <div className={wrapperClass.join(" ")}>
            <div className="loading-section text-center">
                {loading}
                <div>
                    {Dictionary ? <strong>{props.description || `${Dictionary.loadingData}${Dictionary.comma} ${Dictionary.pleaseWait} ...`}</strong> : null }
                </div>
            </div>
        </div>      
    )
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary
});
const RouterDom=withRouter(Loading);
export default connect(mapStateToProps)(RouterDom);






