import { Link } from "react-router-dom";
import { connect } from "react-redux";

import LayoutConfig from "../../Config/layoutConfig";


const SearchBoxTab = props =>{
    const {Dictionary} = props;
    
    const flightIcon = <span className={`itours-icon ${LayoutConfig.themeClassName === 'style-2'?"margin-end-half style-2-flight-line-gray":"top-plane-white hidden-medium hidden-large margin-end-light"}`} />;
    const flightIconActive = <span className={`itours-icon ${LayoutConfig.themeClassName === 'style-2'?"margin-end-half style-2-flight-line-white":"top-plane-blue hidden-medium hidden-large margin-end-light"}`} />;

    const domesticFlightIcon = <span className={`itours-icon ${LayoutConfig.themeClassName === 'style-2'?"margin-end-half style-2-domestic-flight-line-gray":"top-plane-white hidden-medium hidden-large margin-end-light"}`} />;
    const domesticFlightIconActive = <span className={`itours-icon ${LayoutConfig.themeClassName === 'style-2'?"margin-end-half style-2-domestic-flight-line-white":"top-plane-blue hidden-medium hidden-large margin-end-light"}`} />;
  
    const hotelIcon = <span className={`itours-icon ${LayoutConfig.themeClassName === 'style-2'?"margin-end-half style-2-hotel-line-gray":"hotel-white hidden-medium hidden-large margin-end-light"}`} />;
    const hotelIconActive = <span className={`itours-icon ${LayoutConfig.themeClassName === 'style-2'?"margin-end-half style-2-hotel-line-white":"hotel-blue hidden-medium hidden-large margin-end-light"}`} />;

    const packageIcon = <span className={`itours-icon ${LayoutConfig.themeClassName === 'style-2'?"margin-end-half style-2-package-line-gray":"package-white hidden-medium hidden-large margin-end-light"}`} />;
    const packageIconActive = <span className={`itours-icon ${LayoutConfig.themeClassName === 'style-2'?"margin-end-half style-2-package-line-white":"package-blue hidden-medium hidden-large margin-end-light"}`} />;



    let tabsJsx;

    switch(props.searchType){
        case "flight":
            tabsJsx = (<>                
                {LayoutConfig.hasDomesticFlight?
                    <>
                    <button type="button" className="home-tab home-active-tab">
                        {domesticFlightIconActive}
                        {Dictionary.domesticFlight}
                    </button>
                    <Link to="/foreignflight" className="home-tab">
                        {flightIcon}
                        {Dictionary.foreignFlight}
                    </Link>                                        
                    </>
                    :
                    <button type="button" className="home-tab home-active-tab">
                        {flightIconActive}
                        {Dictionary.foreignFlight}
                    </button>
                }
                <Link to="/hotel" className="home-tab">
                    {hotelIcon}
                    {Dictionary.foreignHotel}
                </Link>
                <Link to="/package" className="home-tab">
                    {packageIcon}
                    {Dictionary.package}
                </Link>
            </>);
            
        break;

        case "foreignFlight":
            tabsJsx = (<>
                <Link to="/" className="home-tab">
                    {domesticFlightIcon}
                    {Dictionary.domesticFlight}
                </Link> 
                <button type="button" className="home-tab home-active-tab">
                    {flightIconActive}
                    {Dictionary.foreignFlight}
                </button>  
                <Link to="/hotel" className="home-tab">
                    {hotelIcon}
                    {Dictionary.foreignHotel}
                </Link>
                <Link to="/package" className="home-tab">
                    {packageIcon}
                    {Dictionary.package}
                </Link>        
            </>);

        break;
        
        case "hotel":
            tabsJsx = (<>
                {LayoutConfig.hasDomesticFlight ?
                    <Link to="/" className="home-tab">
                    {domesticFlightIcon}
                    {Dictionary.domesticFlight}
                    </Link>
                :null}                  
                <Link to={LayoutConfig.hasDomesticFlight?"/foreignflight":"/"} className="home-tab">
                    {flightIcon}
                    {Dictionary.foreignFlight}
                </Link>
                <button type="button" className="home-tab home-active-tab">
                    {hotelIconActive}
                    {Dictionary.foreignHotel}
                </button>  
                <Link to="/package" className="home-tab">
                    {packageIcon}
                    {Dictionary.package}
                </Link>                    
            </>);
        
        break;

        case "package":
            tabsJsx = (<>
                {LayoutConfig.hasDomesticFlight ?
                <Link to="/" className="home-tab">
                    {domesticFlightIcon}
                    {Dictionary.domesticFlight}
                </Link>
                :null}                  
                <Link to={LayoutConfig.hasDomesticFlight?"/foreignflight":"/"} className="home-tab">
                    {flightIcon}
                    {Dictionary.foreignFlight}
                </Link>
        
                <Link to="/hotel" className="home-tab">
                    {hotelIcon}
                    {Dictionary.foreignHotel}
                </Link>
        
                <button type="button" className="home-tab home-active-tab">
                    {packageIconActive}
                    {Dictionary.package}
                </button> 
            </>);
        break;

        default :
        tabsJsx = null;
    }

    return(<div className={`${props.withContainer?"page-container":""} mobile-tab-hotel-flight`}>
        <div className="clearfix home-tabs-holder">
            {tabsJsx}
        </div>
    </div>)
};


const mapStateToProps = state => ({
    Dictionary: state.VisualData.Dictionary
});
export default connect(mapStateToProps)(SearchBoxTab);