import React from 'react';
import PropTypes from 'prop-types';
export default class Rate extends React.Component{
    static propTypes={
        number:PropTypes.number.isRequired
    };
    render() {
        return(
            <div className={"rateContainer"}>
                <div className={"rate"} style={{width:this.props.number * 18+'px'}} />
            </div>
        )
    }
}