import React, { useEffect, useState,useRef } from "react";
import { Link } from "react-router-dom";
import Gallery from "../../components/Common/Gallery";
import { connect } from "react-redux";
import moment from "moment-jalaali";
import axios from "axios";
import queryString from "query-string";
import {
   Header,
   Package as PackageURL,
   ServerAddress,
 } from "../../Enum/Urls";
import { editMessage } from "../../Actions/VisualDataAction";
import HotelInformation from "../../components/Accomodation/HotelInformation";

import PackageFlightItem from "../../components/Package/PackageFlightItem";
import ShowMap from "../../components/Accomodation/showMap";
import Loading from "../../components/Common/Loading";

import HotelMultipleRoom from "../../components/Accomodation/RoomLayout/HotelMultipleRoom";

import HotelSingleRoom from "../../components/Accomodation/RoomLayout/HotelSingleRoom";
import LayoutConfig from "../../Config/layoutConfig";

const PackageDetails = props => {

  const [loading,setLoading] = useState(true);
  const [hotelDetails,setHotelDetails] =useState(undefined);
  const [flightDetails,setFlightDetails] = useState(undefined);
  const [flightLoading,setFlightLoading] = useState(true);

  const [errorContent,setErrorContent] = useState(undefined);
  const [errorModalVisible,setErrorModalVisible] = useState(false);
  

  //currecySign = null;

  const getPackageHotelDetail = () =>{
    setLoading(true);
    setErrorModalVisible(false);
    setErrorContent(undefined);

    let parameters = queryString.parse(props.location.search);

    if(parameters){
       let adults=[];         
       const searchedAdults= parameters.Adults.split(",");
       for(let i=0 ; i<searchedAdults.length ; i++){
          adults.push(+searchedAdults[i]);
       }

       let children=[];
       const searchedChildren= parameters.Children.split(",");
       for(let i=0 ; i<searchedChildren.length ; i++){
          children.push(+searchedChildren[i]);
       }

       let ages=[];
       const searchedAges= parameters.Ages.split(",");
       for(let i=0 ; i<searchedAges.length ; i++){
          ages.push(+searchedAges[i]);
       }

      const params = {
        "Id":+parameters.hotelId,
        "Adults":adults,
        "Children":children,
        "Ages":ages,
        "Checkin":moment(parameters.Checkin),
        "Checkout":moment(parameters.Checkout)
      }

       axios.post(`${ServerAddress.Type}${ServerAddress.Package}${PackageURL.GetPackageHotelDetailById}`, params, {headers: {...Header,},})
       .then(({ data }) => {
          if (data.error) {
             debugger;
          }else{
            setHotelDetails(data.result);
            setLoading(false);
          }
       })
       .catch((error) => {
          debugger;
       });

    }
 }
 const packageValidateFlight= async() =>{
  
  setErrorModalVisible(false);
  setErrorContent(undefined);

  setFlightLoading(true);
  let parameters = queryString.parse(props.location.search);
  if(parameters){
    const params = {
      "flightKey":parameters.flightKey
    }
    try {
      const response = await axios.post(`${ServerAddress.Type}${ServerAddress.Package}${PackageURL.PackageValidateFlight}`,params,{headers: {...Header,},});
      setFlightLoading(false);
      setFlightDetails(response.data.result);

  } catch (error) {
      
      const parameters = queryString.parse(props.location.search);

      const searchResultsUrl = `/package/result?originCode=${parameters.originCode}&originName=${parameters.originName}&destinationCode=${parameters.destinationCode}&destinationName=${parameters.destinationName}&checkin=${moment(parameters.Checkin).format("YYYY-MM-DD")}&checkout=${moment(parameters.Checkout).format("YYYY-MM-DD")}&adults=${parameters.Adults}&children=${parameters.Children}&ages=${parameters.Ages}`

      if (error.response) {
        
        setErrorContent({
          title:Dictionary.bookingError,
          details:Dictionary.theFlightYouSelectedIsCurrentlyUnavailable + ". " + Dictionary.pleaseSelectAnotherFlight+ ".",
          button:<Link to={searchResultsUrl} className="button color-1-btn">
                {Dictionary.returnToSearchList}
            </Link>
        });
        
        setFlightLoading(false);
        console.log(error.response.data.error);
        setErrorModalVisible(true);       

      } else if (error.request) {
          console.log( error.request);
      } else {
          console.log(error.message);
      }
      console.log(error);
  }



  }
  
  
 }

useEffect(()=>{
  window.scrollTo(0, 0);
  props.editMessage(false);
  packageValidateFlight();
  getPackageHotelDetail();
},[])


  const handleSelectRoom = async (bookingToken) => {
    setLoading(true);
    let parameters = queryString.parse(props.location.search);
    const params ={"flightKey":parameters.flightKey}

    try {
      const response = await axios.post(`${ServerAddress.Type}${ServerAddress.Package}${PackageURL.PreReserveFlight}`,params,{headers: {...Header,},});
      if (response){
        const preReserveKey = response.data.result;
        let adults=[];         
        const searchedAdults= parameters.Adults.split(",");
        for(let i=0 ; i<searchedAdults.length ; i++){
           adults.push(+searchedAdults[i]);
        }
 
        let children=[];
        const searchedChildren= parameters.Children.split(",");
        for(let i=0 ; i<searchedChildren.length ; i++){
           children.push(+searchedChildren[i]);
        }
 
        let ages=[];
        const searchedAges= parameters.Ages.split(",");
        for(let i=0 ; i<searchedAges.length ; i++){
           ages.push(+searchedAges[i]);
        }

        const roomPreReserveParams = {
          "adults":adults,
          "children":children,
          "ages":ages,
          "checkin":moment(parameters.Checkin),
          "checkout":moment(parameters.Checkout),
          "bookingToken":bookingToken,
          "preReserveKey":preReserveKey}
          try {
            const roomPreReserveResponse = await axios.post(`${ServerAddress.Type}${ServerAddress.Package}${PackageURL.PreReserveRoom}`,roomPreReserveParams,{headers: {...Header,},});
            if(roomPreReserveResponse && roomPreReserveResponse.data.result){
              props.history.push(
                `/package/getPassengers?preReserveKey=${roomPreReserveResponse.data.result}`
              );
            }else{
              const parameters = queryString.parse(props.location.search);
              const searchResultsUrl = `/package/result?originCode=${parameters.originCode}&originName=${parameters.originName}&destinationCode=${parameters.destinationCode}&destinationName=${parameters.destinationName}&checkin=${moment(parameters.Checkin).format("YYYY-MM-DD")}&checkout=${moment(parameters.Checkout).format("YYYY-MM-DD")}&adults=${parameters.Adults}&children=${parameters.Children}&ages=${parameters.Ages}`
              setErrorContent({
                title:Dictionary.bookingError,
                details:Dictionary.theFlightYouSelectedIsCurrentlyUnavailable + ". " + Dictionary.pleaseSelectAnotherFlight+ ".",
                button:<Link to={searchResultsUrl} className="button color-1-btn">
                      {Dictionary.returnToSearchList}
                  </Link>
              });
              setErrorModalVisible(true);

            }

          } catch (error) {
              // if (error.response) {
              // } else if (error.request) {
              // } else {
              // }
          }


        }
      
    } catch (error) {
      debugger;
      // if (error.response) {
      // } else if (error.request) {
      // } else {
      // }
    }

  };

    const { Dictionary } = props;
    
    let roomLayout;
    
    const hotelFacilities=hotelDetails && (hotelDetails.features.filter(item=>item.keyword === "facilities").length > 0) ? hotelDetails.features.filter(item=>item.keyword === "facilities")[0].items:[];
    const roomFacilities=hotelDetails && (hotelDetails.features.filter(item=>item.keyword === "room_facilities").length > 0) ? hotelDetails.features.filter(item=>item.keyword === "room_facilities")[0].items:[];

    if (
      hotelDetails &&
      hotelDetails.availabilities &&
      hotelDetails.availabilities.length > 0
    ) {
      if (hotelDetails.availabilities[0].rooms.length === 1) {

        roomLayout = hotelDetails.availabilities.map((m,index) => {
          return (
            <HotelSingleRoom
              key={index}
              hotelFacilities={hotelFacilities}
              roomFacilities={roomFacilities}
              room={m.rooms[0]}
              rates={m.rates}
              date={hotelDetails.date}
              currency={props.currencyCode}
              onSelectRoom={() => {handleSelectRoom(m.rates[0].bookingToken)}}
              location={props.location}
            />
          );
        });
      } else {
        roomLayout = hotelDetails.availabilities.map((m) => {
          return (
            <HotelMultipleRoom
              key={hotelDetails.availabilities[0].rates[0].token}
              rooms={m.rooms}
              rates={m.rates}
              date={hotelDetails.date}
              currency={props.currencyCode}
              onSelectRoom={()=>{handleSelectRoom(m.rates[0].bookingToken)}}
              location={props.location}
            />
          );
        });
      }
    } else {
      roomLayout = (
        <div className="box-border card-padding bg-white text-center">
          <div className="margin-bottom">
            <span className="itours-icon error-icon icon-xlarge" />
          </div>
          <div className="text-center font-18">
            <div className="margin-bottom">
              <strong>{Dictionary.SorryNoRoomFoundRightNow}!</strong>
            </div>
            <p>{Dictionary.pleaseTryAnotherHotel}</p>
          </div>
        </div>
      );
    }

    const roomsHolder = useRef();
    const scrollToRooms = () => { if(roomsHolder && roomsHolder.current ){ roomsHolder.current.scrollIntoView({behavior: "smooth"})}} ;
        
    return (
      <div className="roomDetailsContainer">
        {errorModalVisible && 
          <div className="itours-modal visible">
            <div className="modal-overlay" />
            <div className="modal-inner small-width">
              <div className="modal-header">{errorContent.title}</div>
              <div className="modal-main">
                {errorContent.details}
              </div>
              <div className="modal-footer">
                {errorContent.button?
                  errorContent.button
                :
                <button
                  type="button"
                  className="button color-1-btn"
                  onClick={()=>{setErrorModalVisible(false)}}
                >
                  {Dictionary.ok}
                </button>                
                }
              </div>
            </div>
          </div>
        }


        <div className="page-container no-padding-mobile">
          {loading ? (
            errorModalVisible || <Loading style2={LayoutConfig.themeClassName === "style-2"} fullPage description={Dictionary.loadingInformationPleaseWait+" ..."}/>
            // <div className="loading-outer-container">
            //   <div>
            //     <img
            //       src={LayoutConfig.logo ? LayoutConfig.logo : siteLogo}
            //       className="loading-logo"
            //       alt={
            //         LayoutConfig.companyName
            //           ? LayoutConfig.companyName
            //           : "itours"
            //       }
            //     />
            //     <div className="loading-inner-container">
            //       <div className="item-1"></div>
            //       <div className="item-2"></div>
            //       <div className="item-3"></div>
            //       <div className="item-4"></div>
            //       <div className="item-5"></div>
            //     </div>
            //     <div className="text-center">
            //       {this.state.doneSelectRoom ? (
            //         <span>{Dictionary.SendsSelectedRoomInformation} ... </span>
            //       ) : (
            //         <span> {Dictionary.getHotelInformation} ... </span>
            //       )}
            //     </div>
            //   </div>
            // </div>
          ) : (
            //loading Section
            <div className="section-vertical-padding hotel-details">
              <div className="hotel-head-info" dir="ltr">
                <HotelInformation
                  model={hotelDetails}
                  salePrice={0}
                  currencyCode={props.currencyCode}
                  onScrollToRooms={scrollToRooms}
                />
              </div>

              <div className="box-border bg-white margin-bottom detail-section blue-border-right">
                <div className="float-row no-gap ltr">
                  <div
                    className={
                      hotelDetails.features &&
                      hotelDetails.features.length > 0 &&
                      LayoutConfig.themeClassName !== "padide-com-theme"
                        ? "col-small-12 col-large-9"
                        : "col-small-12"
                    }
                    dir="ltr"
                  >
                    {hotelDetails.images.length >= 1 ? (
                      <Gallery images={hotelDetails.images} />
                    ) : hotelDetails.mainPhoto ? (
                      <img
                        src={hotelDetails.mainPhoto}
                        alt={hotelDetails.name}
                        className="full-width hotel-main-1photo"
                      />
                    ) : (
                      <div className="card hotel-no-picture box-border text-center body-direction">
                        <div className="no-image-hint ">
                          <div className="margin-bottom">
                            {Dictionary.noImageHeadingText}
                          </div>
                          <small>{Dictionary.noImageDetailText}</small>
                        </div>
                      </div>
                    )}
                  </div>
                  {hotelDetails.features.length > 0 ? (
                    <div
                      className={
                        LayoutConfig.themeClassName === "padide-com-theme"
                          ? "col-small-12"
                          : "col-small-12 col-large-3"
                      }
                    >
                      <div className="card-padding">
                        <h5 className="normal-title">
                          {Dictionary.specialFacilities}
                        </h5>

                        {hotelDetails.features.map((item, index) => {
                          return (
                            <div key={index} className="facilitie-item">
                              {item.name === "Facilities" && (
                                <div
                                  className="margin-bottom en-font"
                                  id="hotel_facilities"
                                >
                                  <div dir="ltr" className="feature-list">
                                    <div className="feature-items">
                                      {item.items.map(
                                        (detailsItem, detailsIndex) => (
                                          <div
                                            className={
                                              detailsItem.isImportant
                                                ? "is-important"
                                                : null
                                            }
                                            key={detailsIndex}
                                          >
                                            {detailsItem.name}{" "}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

             {flightLoading ?
                <div className="bg-white box-border margin-bottom blue-border-right">
                  <h5 className="normal-title card-padding no-margin-bottom black">{Dictionary.selectedFlight}</h5>
                  <div className="card-padding"><Loading style2={LayoutConfig.themeClassName === "style-2"} /></div>
                </div>
              :
                flightDetails && <div className="blue-border-right">
                <PackageFlightItem model={flightDetails.pricedItinerary}  isSelected isInPackageDetail/>
                </div>
              }

              {/*photo Gallery and hotel info*/}
              {hotelDetails.features.length > 0 ? (
                <div className="card card-padding bg-white box-border margin-bottom detail-section blue-border-right">
                  {hotelDetails.features.map((item, index) => {
                    return (
                      <div key={index} className="facilitie-item">
                        {item.name === "Object Information" && (
                          <div className="margin-bottom">
                            <h5 className="normal-title">
                              {Dictionary.hotelContactInformation}
                            </h5>
                            <div dir="ltr">
                              <ul className="float-row no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}:{detailsItem.value}{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {item.name === "Building Information" && (
                          <div className="margin-bottom">
                            <h5 className="normal-title">
                              {Dictionary.SpecificationsForHotelBuildings}
                            </h5>
                            <div dir="ltr">
                              <ul className=" float-row  no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}:{detailsItem.value}{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {item.name === "Payment" && (
                          <div className="margin-bottom">
                            <h5 className="normal-title">
                              {Dictionary.ValidFinancialCreditsForHotelUse}
                            </h5>
                            <div dir="ltr">
                              <ul className=" float-row  no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {item.name === "Facilities" && (
                          <div className="margin-bottom" id="hotel_facilities">
                            <h5 className="normal-title">
                              {Dictionary.hotelFacilities}
                            </h5>
                            <div dir="ltr">
                              <ul className=" float-row  no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {item.name === "Room Facilities" && (
                          <div className="margin-bottom">
                            <h5 className="normal-title">
                              {Dictionary.roomFacilities}
                            </h5>
                            <div dir="ltr">
                              <ul className=" float-row  no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {item.name === "Distances" && (
                          <div className="margin-bottom">
                            <h5 className="normal-title">
                              {Dictionary.DistanceFromHotelToPlaces}
                            </h5>
                            <div dir="ltr">
                              <ul className="float-row  no-list-style ltr">
                                {item.items.map((detailsItem, detailsIndex) => (
                                  <li
                                    className="col-small-12 col-medium-6 col-large-3 item"
                                    key={detailsIndex}
                                  >
                                    {detailsItem.name}:{detailsItem.value} m{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : null}

              {
                hotelDetails.paragraphs && hotelDetails.paragraphs.length > 0 && (
                  <div id="hotel_details">
                    <div
                      className="card card-padding bg-white box-border margin-bottom blue-border-right"
                      dir="ltr"
                    >
                      <h5 className="normal-title body-direction">
                        {" "}
                        {Dictionary.hotelDetails}
                      </h5>
                      <div className="en-font">
                        <div className="textContent">
                          {hotelDetails.paragraphs.map((item, index) => {
                            return (
                              <div key={index}>
                                <div>
                                  <b className="title">{item.title}</b>
                                </div>
                                <div>
                                  <p>{item.para}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )
                // hotel paragraph
              }

               <div className="box-border map-holder">
                <ShowMap data={[hotelDetails]} />
              </div>
              <br/>
              <div className="room-select-card" id="roomSelectSection" ref={roomsHolder}>
                <h5 className="normal-title">{Dictionary.selectRoom} </h5>
                <div id="roomSelect" className="select-room-wrapper">
                  {roomLayout}
                </div>
              </div> 
            </div>
          )}
        </div>
      </div>
    );
}
const dispatchMapProp = (dispatch) => ({
  editMessage: (param) => {dispatch(editMessage(param))}
});
const stateMapProp = (state) => ({
  error: state.VisualData.error,
  respond: state.VisualData.Responded,
  currencyCode: state.UserData.Currency,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(stateMapProp, dispatchMapProp)(PackageDetails);
