import React from 'react';
class TermsNo extends React.PureComponent{
    render() {
        return (
          <>
            <div>
              <b className="blue">Det er kjøperen og reisendes ansvar å ...</b>
            </div>
            <br />
            <ul className="bullet-list">
              <li>Les og godkjenn reisevilkår før kjøp.</li>
              <li>
                Kontroller at all informasjon er korrekt med hensyn til stavemåter med navn og tittel, samt kontroller flyet Planlegg datoer før avtalen trer i
                kraft. Ved å velge betalingsalternativ, bekreft informasjonen.
              </li>
              <li>
                Når du har fullført kjøpet, får du billetten sendt til deg via e-post innen 24 timer. Det er kundens ansvar å kontrollere at antall
                billettnummer stemmer overens med antall reisende. Hvis turen består av to enkeltbilletter, skal hver passasjer ha ett billettnummer pr. Vei.
                Hvis det mangler billettnummer eller andre tvetydigheter, bør du kontakte Itours innen to dager.
              </li>
              <li>Sjekk den angitte e-postadressen regelmessig, siden all korrespondanse med oss er via e-post.</li>
              <li>Kontinuerlig sjekke flyplaner og eventuelle endringer i tidsplanen for både utgående og returreise.</li>
              <li>Kontrollerer gjeldende innsjekkingstider for hele reisen.</li>
              <li>Kontroller med de respektive flyselskapene fra hvilken terminalavgang skjer.</li>
              <li>
                Kontroller visumkravene for deres endelige destinasjon, samt eventuelle mellomliggende stopp. Dette gjøres via respektive ambassade og
                flyselskapene du reiser med.
              </li>
              <li>Kontroller passregler og passgyldighet, siden enkelte land krever at passet ditt er gyldig i minst seks måneder etter returreisen</li>
              <li>Kontroller hvilke vaksinasjoner som trengs før du reiser.</li>
            </ul>
            <div>Rekkefølge.</div>
            <p>
              Skriv inn den riktige e-postadressen når du bestiller. All kommunikasjon mellom Itours og de reisende etter bestilling skjer via e-post. Derfor er
              det viktig å kontinuerlig sjekke tilgjengelig e-post mellom tidspunktet for bestilling og avreisedato
            </p>
            <p>
              Ved bestilling av rundreise skal alle strekninger av billetten brukes i riktig rekkefølge. Hvis passasjeren ikke klarer å delta på noen strekning,
              bevisst eller ubevisst, vil flyselskapet avbryte den gjenværende delen av reisen og vil ikke gjøre tilbakebetaling på delene av reisen ikke brukt
            </p>
            <p>
              <b className="blue">
                Itours tilbyr også tur og returbilletter bestående av to enkeltbilletter. Reglene for hver billett gjelder. Det vises når billetten består av to
                enkelt reiser. I disse tilfellene består bestillingen av to enkeltfly, kunden er ansvarlig for å sjekke reisen og billettnumrene for begge
                reiser mottas.
              </b>
            </p>
            <p>
              Når du har bestilt og betalt reise, sender vi en bestillingsbekreftelse til e-postadressen som ble oppgitt ved bestilling. I denne
              bekreftelsesmeldingen finner du en lenke til din personlige bestillingsside hvor du kan skrive ut din e-billett.
            </p>
            <p>
              Hvis flyselskapet har endret flyplanen, kan du se dette her. Vi anbefaler at du skriver ut billetten fra denne siden før du reiser. Informasjonen
              på siden er på engelsk. For å endre eller legge til kontaktinformasjon i din bestilling, må du ha et reservasjonsnummer når du kontakter oss. Hvis
              du ikke har mottatt bekreftelse via e-post, er det viktig at du kontakter oss umiddelbart slik at vi kan bekrefte dette og rette e-postadressen
              hvis den ikke er riktig. Du reiser alltid med e-billett (elektronisk billett). Det finnes ikke flere papirbilletter, i stedet får du et
              elektronisk reisedokument via e-post fra oss. Det enkleste er at du skriver ut e-billett og tar det med deg til innsjekkingen. Der finner du all
              viktig informasjon, billettnummer og flygtider. Du vil bli gitt et boardingpass ved innsjekkingstelleren på flyplassen når du viser et gyldig
              pass.
            </p>
            <div>
              <b className="blue">Sjekk at alle reisende har mottatt billettnummer.</b>
            </div>
            <p>
              Boarding kort kan skrives ut hjemme dersom du sjekker inn online. De kan ellers bli hentet ved telleren på flyplassen. For å bestille en tur på
              hjemmesiden må du være over 18 år eller ha autorisasjon fra en formynder.
            </p>
            <div>
              <b className="blue">Navn.</b>
            </div>
            <div>
              <b className="blue">Advarsel.</b>
            </div>
            <p>På grunn av flyselskapets høye krav til sikkerhet er det svært viktig å fylle ut riktig stavede navn på alle reisende i din bestilling.</p>
            <p>
              Først: For å skrive inn fornavnet som de vises i passet. Etternavn: Skriv inn alle etternavn på grunnlag av passet. Du kan ikke forkorte navnet
              ditt, eller gi dem kun med initialer.
            </p>

            <p>
              Hvis du gifte deg, må du bestille reisen med samme navn som du skal ha i passet ditt under reisen. Det er kjøpers ansvar å kontrollere at alle
              navn staves riktig. Merk at bestillingssystemet tar bort alt mellom slag og tegnsetting mellom navn. Å, æ og ø endret i henhold til internasjonale
              alfabet Når betalingsalternativet er valgt og billetten er utstedt, er det opp til flyselskapets regler om navnendring er tillatt. Det er kundens
              ansvar å informere seg om flyselskapsreglene før gjennomført kjøp. I tilfeller der flyselskapet endrer / korrigerer navn, tar Itours et gebyr på
              500 NOK / billett for å håndtere endringer, i tillegg til eventuell avgift fra flyselskapet.
            </p>
            <div>
              <b className="blue">Reiser med barn.</b>
            </div>
            <p>
              Det er viktig å angi riktig alder for alle barn som følger med på reisen vår. Deres alder kontrolleres av flyselskapet. Vær oppmerksom på at
              barnets alder på returdatoen gjelder. Hvis barnet ditt blir to under reisen, bør reisen bestilles som barn over to år. Det samme gjelder dersom
              reisende fyller tolv år på reisen. Dette bør da bestilles som voksen.
            </p>
            <p>Barn som ikke har nådd to år registrert som spedbarn. Dette betyr at barnet ikke har et eget sete, og i stedet får sitte på en voksen omgang.</p>
            <p>
              Det er ikke mulig å bestille en reise til et barn som ennå ikke er født, eller en person du ikke kjenner navnet til. Så du kan ikke bestille på
              vår hjemmeside og spesifiser "Baby" det navnet, siden riktig navn må spesifiseres når du bestiller, og dette må samsvare med navnet på passet
              ditt. Vi kan ikke garantere at barn ikke er involvert fra starten for å passe på flyet. Det er ikke alltid teknisk mulig å bestille ekstra plass
              til et spedbarn på en eksisterende reservasjon. Et spedbarn kan ikke legges til en delvis brukt rekkefølge. For eksempel, hvis foreldrene har
              reist til et annet land for å føde, og har en t / r-billett, er det ikke mulig å legge til spedbarnet på rebound. Det er heller ikke mulig å
              plassere et spedbarn alene i en separat rekkefølge. I slike tilfeller må det bestilles en ny enkeltbillett for en voksen og spedbarn.
            </p>
            <div>
              <b className="blue">Barn som reiser alene.</b>
            </div>
            <p>
              På vår nettside er det ikke mulig å bestille egen barne billett, siden barnrabatt gjelder kun i selskap med en voksen. Hvis du har bestilt for å
              reise for barn på en voksenbillett, er det teknisk sett ikke mulig å bestille assistanse om bord. For barn som reiser alene, er det ofte
              obligatorisk å forhåndsbestille kalt Unaccompanied Minor Service. Reglene og kostnaden for dette varierer fra flyselskap til flyselskap. Minimum
              og maksimum tillatt alder varierer også. SAS minimumsalder er for eksempel fem år, mens Air France har en minimumsalder på 4 år. Vanligvis bør du
              bruke det samme flyselskapet på reisen for å garantere at det fungerer. Vi trenger å vite navnet ditt, adressen og telefonnummeret til personene
              som leverer / samler barnet i flyplassen. Et såkalt handlingsråd må også fylles ut på flyplassen. Hver uledsagd mindre tjeneste må sendes som en
              forespørsel fra Itours til flyselskapet, og denne tjenesten er ikke garantert før flyselskapet har akseptert forespørselen. Hvis du ønsker å
              bestille barne billett med hjelp til enslige barn, vennligst kontakt oss via kontaktskjemaet på vår nettside. Legg merke til at et
              bestillingsgebyr gjelder (295 NOK).
            </p>
            <div>
              <b className="blue">Innbetaling.</b>
            </div>
            <p>
              Du har flere alternativer. For å betale med kredittkort eller via nettbank, betaler du hele beløpet dagen for bestillingen utføres. Vær oppmerksom
              på at alle betalingsmåter er bindende når du har valgt valget av betalingsmåte. Når du betaler med kort, legger det til et ekstra gebyr. Dette vil
              vi varsle deg før avtalen blir effektiv, og gebyret blir ikke refundert. Kortet må utstedes i Skandinavia Avbestilling gjelder ikke for transport.
              Når kunden har fullført bestillingen anses å bli betalt, og etter dette er bestillingen bindende. Itours formidler produkter fra forskjellige
              leverandører, og er derfor ikke ansvarlig for hendelsene som har å gjøre med ferdigstillelsen av reisen. Eksempel på dette er tapt bagasje,
              bagasje som ikke er inkludert i flyturen, avbestillte flyreiser og endringer i flyplaner. Dette ansvaret hviler hos flyselskapene, og kunden bør
              derfor søke direkte til det aktuelle flyselskapet med eventuelle klager. Tapt eller skadet bagasje må alltid rapporteres direkte i stedet for det
              aktuelle flyselskapet.
            </p>
            <div>
              <b className="blue">Tekniske problemer.</b>
            </div>
            <p>
              Itours forbeholder seg mot tekniske problemer, datafeil, prisfeil og endringer som gjøres av flyselskaper utenfor vår kontroll. Det kan for
              eksempel. være flyselskaper som viser steder som ikke er tilgjengelige, endringer i flyplaner eller feilaktige priser på grunn av datafeil.
              Flyselskapene har også rett til å endre bestillingen innen 24 timer etter at bestillingen er fullført. Vi forbeholder oss retten til å kontakte
              deg innen 24 timer på hverdager for eventuelle endringer i bestillingen. Hvis det oppstår problemer under eller i forbindelse med helgen,
              kontakter vi deg første arbeidsdag.
            </p>
            <div>
              <b className="blue">Ta ut.</b>
            </div>
            <p>
              Avbestilling skal ikke gjelde for transport, i henhold til gjeldende lov. Vi har ingen 24-timers regel når det gjelder våre bestillinger. Når
              betalingen er fullført og billetten er utstedt, har vi ingen muligheter til å endre billetten, men må følge flyselskapets reissued- /
              avbestillingsregler. Disse reglene er bestemt av flyselskapene og ikke av oss som agent. Les mer under Avbestilling / ombooking.
            </p>
            <div>
              <b className="blue">Dobbel booking.</b>
            </div>
            <p>
              Den reisende har ansvaret for at eventuelle dobbelte bestillinger blir kansellert, uansett hvordan de blir implementert. Den reisende risikerer
              ellers at flyselskapet uten varsel avbestiller dobbelt booking. Itours påtar seg intet ansvar for avbestilling av flyselskap eller manglende
              tilbakebetaling i den forbindelse.
            </p>
            <div>
              <b className="blue">Avbestilling / ombooking.</b>
            </div>
            <p>
              Når betalingen er fullført og billetten er utstedt, er det flyselskapets regler for ombooking / avbestilling gjelder. Det er kundens ansvar å få
              informasjon om reglene som gjelder på billetten før kjøpet er fullført. Dette kan gjøres ved å kontakte kundeservice på +47 22 11 27 44 mandag -
              fredag fra kl. 10 til 16.00. I tilfeller som flyselskapet tillater ombooking, tar Itours 500 NOK / billettbehandlingsgebyr i tillegg til
              flyselskapets egen betalte avgift. I slike tilfeller gir flyselskapet refusjon på avbestillte billetter alltid til en pris. I tillegg til
              avbestillingsgebyr for flyselskapet tar Itours 500 NOK / billett. Energiforbrukskostnader som kortavgift, avbestillingsforsikring og serviceavgift
              refundert. I de fleste tilfeller refunderes kun en liten del av skatter, siden disse inkluderer drivstoffkostnader og andre avgifter som
              flyselskapet vil refundere. Ved tilbakebetaling av de eneste flyplassavgiftene tar Itours et administrasjonsgebyr på 250 NOK / billett til de
              skatter og avgifter som flyselskapet vil refundere. Tilbakebetaling utføres av flyselskapene; behandlingstiden er 5-12 uker.
            </p>
            <p>
              Rebooking må gjøres minst 24 timer før avreise ved enten den opprinnelige bestillingen eller ombokning av det som skjer først. Rebooking er ikke
              fullført før Reissued avgift er betalt og Itours har utstedt ny billett. All ombooking må gjøres via telefon. Vennligst ha kortet ditt
              tilgjengelig for betaling av ombooking når du ringer til oss. Avbestilling må gjøres senest 24 timer før avreise av den opprinnelige bestillingen.
              Reisen anses å bli kansellert bare når Itours har bekreftet avbestillingen. Itours refunderer aldri delvis brukt billett.
            </p>
            <div>
              <b className="blue">Flytider.</b>
            </div>
            <p>
              Alle tider er gitt i lokal tid for respektive flyplass. Tider angitt ved stoppested er godkjent tid for bytte av fly, estimert av flyselskapene i
              samarbeid med flyplassen. Ved forsinkelse betyr at forsikringstakere ikke gjør neste fly på en billett som dekker flere flyreiser, er det
              flyselskapets ansvar å hjelpe kunden til destinasjonen. Når du bestiller separate billetter, er flyselskapet ikke ansvarlig for om de savner neste
              fly på grunn av forsinkelser. Det er da kundens ansvar å sørge for at det er nok tid til å bytte fly. Eventuelle mer transport og losji under
              turen er ikke inkludert i billettprisen. Det er reisendes ansvar å sjekke flyvetid før bestilling.
            </p>
            <div>
              <b className="blue">Direkte eller non-stop.</b>
            </div>
            <p>
              Å reise med direktefly kan bety at flyet kan lande på veien, for eksempel for å refillere. Så lenge flyet ikke endrer flynummeret ved landing,
              vurderer flyselskapene at det er en direkte flytur. Vi markerer denne mellomlandingen på bestillingsbekreftelsen, og prøv ikke på noen måte å
              skjule eventuelle stopp. Denne typen teknisk stopp er godkjent basert på de internasjonale flygreglene vedtatt av IATA (International Air Traffic
              Association). Nonstop-flight er et fly som ikke stopper, men flyr fra punkt A til B uten eksempel for å fylle opp. Fly indirekte flyreiser er med
              en eller flere mellomlandinger og hvor du bytter fly og flynummer. Bytter flyplass og overføring
            </p>
            <p>
              Hvis en tur bestilt indirekte som inkluderer endring av flyplasskunden, må selv være ansvarlig for overføringskostnader mellom flyplassene. Dette
              gjelder for eksempel under et stoppested i London når reisen i noen tilfeller går til London / Heathrow og siden videre fra London / Gatwick.
              Andre vanlige steder hvor det noen ganger oppstår bytte flyplass under et stoppested for eksempel. New York og Paris. Husk å sjekke flyplanen
              nøye. Ved utskifting av flyplassen skal bagasjen bli trukket ut og deretter sjekket inn igjen på den nye flyplassen. Ved reisen av et stopp krever
              en overnatting fordi neste flytur ikke fortsetter til neste dag, må reisen til og med betale for en hvilken som helst hotellnatt.
            </p>
            <div>
              <b className="blue">Endringer i flyplaner.</b>
            </div>
            <p>
              Flyselskapene kan med kort varsel endre flytid og avbryte flyet. Dette er utenfor Itours-kontrollen. , Endringen i flytid før avreise vil bli
              sendt via e-post med gjeldende opplysninger til e-postadressen som er angitt i bestillingen. Hvis endringen i flygtiden ikke er akseptert av den
              reisende, må den rapporteres til Itours omgående. Hvis endringen skjer etter at det er vanskelig for Itours å gi beskjed. Det er reisendes ansvar
              å holde seg informert om de riktige flytidene for utreise og returreise. Disse gangene refunderer flyselskapets refusjon på grunn av endringer i
              flyplanene Itours utgjør en administrativ avgift på 150 NOK / billett. Tilbakebetaling skjer fra flyselskapene og behandlingstiden er fra 5 til 12
              uker
            </p>
            <p>
              Hvis du har bestilt en tur og returreise som består av to enkeltreiser og endring skjer i flytid, eller en avgang avbestilles på en av turene, er
              det ikke tillatt å endre eller avbryte den andre reisen på grunn av dette, siden reiser i flyselskap-øyner holder seg ikke sammen.
            </p>
            <p>
              Itours kan ikke holdes ansvarlig for om på eller i nærheten av destinasjonen skjer miljøkatastrofe, krigshandling, streik eller annen intervensjon
              som gjør at planlagte turen ikke kan utføres. Itours arrangerer billetter og kan ikke holdes ansvarlig for endringer i flyplaner eller avbestillte
              flyreiser.
            </p>
            <div>
              <b className="blue">Forsikring.</b>
            </div>
            <p>
              Vi anbefaler sterkt våre reisende å ha en reiseforsikring eller kjøpe en før avreise. Det er reisendes ansvar å få forsikringen nødvendig for
              reisen.
            </p>
            <div>
              <b className="blue">Dokumentasjon.</b>
            </div>
            <p>
              Reisendes ansvar for å kontrollere visumkrav med ambassaden. Avhengig av destinasjonen nektes du å komme om bord hvis du ikke har gyldige
              reisedokumenter, for eksempel pass, visum og transittvisum (tenk også at enkelte land trenger pass gyldig i minst 6 måneder etter utløpsdato). Vi
              anbefaler at du alltid har passet ditt når du går ut og reiser. Europeerne reiser til USA eller mellom landene som må registreres oppføring
              online. Passasjerer blir bedt om å gå inn på nettsiden til Estas og fylle ut de obligatoriske opplysningene minst 72 timer før avreise. Itours kan
              hjelpe med visum til Australia for noen nasjonaliteter, koster 500 Nok / person.
            </p>
            <div>
              <b className="blue">Sjekk inn.</b>
            </div>
            <p>
              Vi anbefaler at du er på flyplassen i god tid før avreise. Tenk på at det kan være lange køer å sjekke inn og sikkerhetskontroll, og ta også
              hensyn til at ting kunne hende på vei til flyplassen. Mange flyselskaper tilbyr innsjekking på nettsiden omtrent 22 timer før avreise. For
              spørsmål om sjekk på nettet, refererer vi til det flyselskapet du reiser med.
            </p>
            <div>
              <b className="blue">Bagasje.</b>
            </div>
            <p>
              Den reisende er ansvarlig for å kontrollere hvilke bagasjeregler som gjelder. Informasjon finner du på billetten eller det respektive
              flyselskapets nettside. Itours påtar seg intet ansvar for tapt eller skadet bagasje. Eventuelle feil skal straks bli påtalt i lufthavnen,
              flyselskapets representant.
            </p>
            <p>
              Bemerker at enkelte flyselskaper tar ut en bagasjeavgift. Dette betales med direkte til flyselskapet. Dette gjelder for eksempel. Air Baltic,
              amerikanske transportører (innenlandsk USA). Ved å reise med mer enn ett flyselskap, kan det være forskjellige regler for de ulike selskapene. 1
              PC = 1 stk. (Maks 1 boks på 23 kg) Norsk 1 PC = 1 stk. (Maks 1 sak x 20 kg)
            </p>
            <div>
              <b className="blue">Ekstra bagasje.</b>
            </div>
            <p>
              Dessverre har vi ikke muligheten til å legge til mer lagringsplass. Ta kontakt med flyselskapet direkte for mer informasjon om mulige muligheter
              for ekstra bagasje, samt kostnad. Informasjon om bagasje brukes vanligvis til å finne på flyselskapets nettside.
            </p>
            <div>
              <b className="blue">Spesialbagasje.</b>
            </div>
            <p>
              De fleste flyselskapene vil at du informerer på forhånd om du skal ta med spesiell bagasje på reisen. Vi må vite vekten og dimensjonene (lengde x
              høyde x bredde) i spesielle poser. Du får oftest svar fra flyselskapet innen få timer. Avgiften for dette betales alltid direkte til flyselskapet.
            </p>
            <p>
              For spesiell bagasje vurderes: ski, golf, våpen, musikkinstrumenter, etc. Merk at noen mindre flytyper ikke kan ta imot større spesialbagasje.
              Itours kan bare sende en forespørsel til flyselskapet når det gjelder spesialbagasje, vi kan aldri garantere at forespørselen vil bli godkjent før
              vi har mottatt bekreftelse fra flyselskapet.
            </p>
            <div>
              <b className="blue">Innholdet i håndbagasje.</b>
            </div>
            <p>
              For informasjon om væsker, medisin, baby mat og spesiell diett i håndbagasje, refererer vi til flyselskapets nettsider. Sjekket bagasje påvirkes
              ikke av begrensningene.
            </p>
            <div>
              <b className="blue">Nektet ombordstigning, kanselleringer og forsinkelser.</b>
            </div>
            <p>
              I henhold til europeisk lov, under visse omstendigheter, berettiget til tilbakebetaling og / eller kompensasjon fra flyselskapet i forbindelse med
              nektet ombordstigning, kansellering og forsinkelser. Mer informasjon om disse rettighetene er avduket på europeiske flyplasser, og de er også
              tilgjengelige fra flyselskapene. Kompensasjon i slike tilfeller er imidlertid flyselskapets ansvar.
            </p>

            <h3 className="large-title">PERSONVERN. DIN INFORMASJON. DIN TRYGGHET.</h3>
            <div>
              <b className="blue">Våre Forpliktelser Overfor Deg</b>
            </div>
            <p>
              Du står i sentrum av alt vi gjør. Målsettingen vår er at du alltid skal føle deg trygg og ha tillit til oss, ved at vi håndterer
              personopplysningene dine med respekt og lar deg ha kontroll over dem.
            </p>
            <p>
              Det er viktig at du vet hvilke personopplysninger Itours AS (her betegnet som «vi» eller «Itours AS») samler inn om deg, og hvordan vi bruker dem.
            </p>
            <p>
              Vi har gjort vårt beste for å forklare dette på en kortfattet og enkel måte. Hvis du skulle ønske mer informasjon eller du har spørsmål, kan du ta
              kontakt med personvernombudet vårt ved å sende en e mail til online@itours.no.{' '}
            </p>
            <p>
              Hvis vi skulle gjøre større endringer av våre retningslinjer for personvern, vil vi gi deg beskjed. Om nødvendig vil vi også be om din
              godkjennelse.
            </p>

            <div>
              <b className="blue">Personvernerklæring</b>
            </div>
            <p>
              Vi har utformet personvernerklæringen vår med deg i tankene. Hvordan erklæringen gjelder for deg, vil avhenge av hvilken type kontakt du har med
              oss. For eksempel, hvis du:
            </p>
            <ul>
              <li>
                kjøper en billett hos oss, bruker vi informasjonen du gir oss til å oppfylle både våre og Samarbeidspartnernes forpliktelser til å levere
                tjenesten til deg, og hvis det er tillatt vil vi holde deg oppdatert om andre arrangementer som kan være av interesse for deg; og
              </li>
              <li>
                når du surfer på nettstedene våre, bruker vi informasjonskapsler for å gi deg en personlig tilpasset og forhåpentligvis sømløs opplevelse.
              </li>
            </ul>

            <p>Valgene og rettighetene du har i de ulike tilfellene er mer detaljert forklart nedenfor.</p>
            <div>
              <b className="blue">Hvilken informasjon vi har og hvor vi får den fra</b>
            </div>
            <ul>
              <li>
                Når du oppretter en konto, kjøper en billett eller en venn overfører en billett til deg, samler vi inn opplysninger om deg som, avhengig av
                tjenesten vi leverer, kan inkludere din kontakt- og faktureringsinformasjon.
              </li>
              <li>
                Når du bruker nettstedene eller applikasjonene våre, samler vi inn informasjon, som f.eks. hvilken nettleser og enhet du bruker, IP-adressen
                din, hvor du befinner deg, nettstedet du kom fra, hva du brukte og ikke brukte nettstedet/applikasjonen vår til, eller hvilket nettsted du gikk
                til når du forlot nettstedet vårt.
              </li>
              <li>
                Når du bruker en funksjon tilknyttet sosiale medier på nettstedet vårt eller i applikasjonene våre og du legger ut noe på sosiale
                medieplattformer, vil det sosiale mediet gi oss visse opplysninger om deg.
              </li>
              <li>
                I de få tilfellene der vi innhenter personopplysninger fra barn, ber vi alltid om de foresattes samtykke og vil alltid kun innhente slike
                opplysninger for de formålene som oppgis når vi innhenter dem.
              </li>
              <li>
                Vi bruker leverandører som setter sammen geodemografiske data, som hjelper oss å bedre tilpasse tjenestene våre til dine behov. Hvis du
                foretrekker at vi ikke gjør dette, se avsnittet «Dine valg og rettigheter» nedenfor.
              </li>
            </ul>
            <div>
              <b className="blue">Hvordan informasjonen din brukes og hvorfor</b>
            </div>
            <p className="semi-bold">1. For å oppfylle kontrakten vår med deg</p>
            <p>Vi bruker informasjonen din når du inngår en kontrakt med oss (f.eks. for å kjøpe varer eller en billett) for å kunne:</p>

            <ul>
              <li>behandle ordren din</li>
              <li>ta betalt, og</li>
              <li>gi deg kundestøtte.</li>
            </ul>
            <p className="semi-bold">2. For våre berettigede forretningsinteresser</p>
            <ul>
              <li>For å sende deg e-poster i tilknytning til kundeservice, inkl. bestillingsbekreftelser og påminnelser om arrangementer.</li>
              <li>
                For å forhindre eller oppdage ulovlig atferd, for å beskytte eller håndheve våre juridiske rettigheter eller slik loven på annen måte tillater
                det. F.eks. for å sørge for at billettene kommer i hendene på ekte fans. For å oppnå dette kan vi bruke informasjonen din til å forhindre
                svartebørssalg av billetter, misbruk av våre immaterielle eierrettigheter (f.eks. ift. våre eller Samarbeidspartneres varemerker), bedrageri
                eller andre forbrytelser.
              </li>

              <li>For å sikre at våre og våre Samarbeidspartneres aktiviteter utføres trygt og sikkert.</li>
            </ul>

            <p className="semi-bold">3. Der du har gitt ditt samtykke</p>

            <ul>
              <li>
                For å kontakte deg med informasjon eller tilbud om de kommende arrangementene, produktene eller tjenestene vi kan tilby – på e-post, med
                push-meldinger og nettbaserte varslinger, på SMS eller via sosiale medieplattformer.
              </li>
              <li>
                For å behandle dine helserelaterte opplysninger for å kunne oppfylle dine krav til tilgjengelighet, der det har blitt uttrykkelig forespurt og
                samtykke har blitt gitt.
              </li>
            </ul>
            <div>
              <b className="blue">Dine valg og rettigheter</b>
            </div>
            <p className="semi-bold no-margin">Valgmuligheter</p>
            <p>Dersom du har gitt oss ditt samtykke, kan du trekke det tilbake ved å gjøre følgende:</p>
            <ul>
              <li>
                For å ikke lenger motta markedsføringsmateriell fra oss kan du endre preferansene i kontoinnstillingene dine, følge instruksene for å avslutte
                abonnementet som alltid er oppgitt i e-poster vi sender deg, eller kontakte oss og be oss gjøre det for deg.
              </li>
            </ul>
            <p className="semi-bold no-margin">Dine rettigheter</p>
            <p>Du har også rettigheter vedrørende hvordan personopplysningene dine brukes, inkludert:</p>

            <ul>
              <li>Retten til å motsette deg at vi behandler opplysningene dine.</li>
              <li>Retten til å be om at opplysningene dine blir slettet eller at videre bruk av dem begrenses.</li>
              <li>Retten til å be om en kopi av informasjonen vi har om deg.</li>
              <li>
                Retten til å korrigere, endre eller oppdatere informasjonen du har gitt oss (hvis du har en konto hos oss, kan du også gjøre dette ved å logge
                inn og oppdatere informasjonen din).
              </li>
              <li>
                Retten til å bestride alle slags automatiserte beslutninger vi tar om deg. En automatisert beslutning er en beslutning som tas uten menneskelig
                inngripen og som har juridiske konsekvenser (f.eks. kredittsjekk). Vi bruker vanligvis ikke automatisert beslutningstaking, men hvis vi gjør det
                vil vi informere om det når slike beslutninger tas.
              </li>
            </ul>
            <p>
              For å utøve de ovennevnte rettighetene må du sende oss en e mail : online@itours.no. Vær oppmerksom på at selv om vi vurderer alle forespørsler vi
              mottar nøye, kan rettighetene dine variere etter hvor du bor, og vi er ikke alltid nødt til å etterkomme anmodningen din. Hvis dette skulle være
              tilfelle, vil vi forklare deg hvorfor.
            </p>
            <div>
              <b className="blue">Hvordan vi tar vare på informasjonen din</b>
            </div>
            <p>
              Vi har innført sikkerhetstiltak for å beskytte informasjonen din. Sikkerhetstiltakene vi bruker vil avhenge av hvilken type informasjon som samles
              inn.
            </p>
            <p>
              Vi vil bare lagre opplysningene dine så lenge det er nødvendig for å levere tjenestene du ber om, for de formålene som er beskrevet i denne
              erklæringen, og for eventuelle juridiske formål der vi er rettslig forpliktet til å oppbevare informasjonen. Vi vil slette informasjonen din på en
              sikker måte når den ikke lenger behøves for disse formålene, i henhold til selskapets retningslinjer.
            </p>
            <p>
              Det er strenge regler for overføringer av informasjon på denne måten for å sikre at opplysningene dine fortsatt er beskyttet iht. høye standarder.
              Der vi gjør dette, vil vi sørge for at nødvendige garantier er på plass. Når opplysningene dine overføres til et sted utenfor Det europeiske
              økonomiske samarbeidsområdet, vil vi bruke en av fremgangsmåtene nevnt nedenfor.
            </p>
            <ul>
              <li>Standardavtaler godkjent av Europakommisjonen</li>
              <li>Privacy Shield-rammeverket mellom EU og USA</li>
              <li>Bindende konsernregler for overføring</li>
              <li>Bindende konsernregler for overføring for databehandlere</li>
            </ul>

            <p>Kontakt oss for mer informasjon eller for å få en kopi av den gjeldende dokumentasjonen.</p>

            <div>
              <b className="blue">Kontakt Oss</b>
            </div>
            <p>
              Hvis du har spørsmål om opplysningene ovenfor eller om hvordan vi håndterer personvern, har vi en egen Personvernavdeling og et eget
              Personvernombud som kan hjelpe deg: online@itours.no
            </p>
            <p>Du kan også kontakte Datatilsynet, men vi oppfordrer deg til å først la oss forsøke å hjelpe deg.</p>
          </>
        );
    }

}
export default TermsNo;