import axios from "axios";
import {
  Header,
  Hotel as HotelURL,
  ServerAddress,
  Reserve,
} from "../Enum/Urls";
import { showError, editMessage } from "./VisualDataAction";
import { push } from "connected-react-router";
import _ from "lodash";
import { getFlightReserveAsync } from "../Actions/FlightAction";
export const searchHotelAsync = (data) => ({
  type: "HotelSearch",
  result: data,
});
export const searchHotelFilterAsync = (data) => ({
  type: "HotelFilterAsync",
  result: data,
});
export const selectedHotel = (data) => ({ type: "HotelDetails", result: data });
export const getHotelDetailsAsync = (data) => ({
  type: "HotelDetails",
  result: data,
});
export const setReserveID = (data) => ({
  type: "SetReserveID",
  reserveId: data,
});
export const filterSelect = (data) => {
  return { type: "FilterSelect", input: data };
};
export const applyFilter = () => ({ type: "ApplyFilter" });
export const getHotelReserveAsync = (data) => {
  let voucherList = [];
  if (data) {
    data.forEach((item) => {
      const {
        departureDate,
        currency,
        firstName,
        gender,
        lastName,
        origin,
        salePrice,
        id,
        status,
      } = item;
      let date = new Date(departureDate);
      let itemVoucher = {
        date: date.toLocaleDateString(),
        currency: currency.code,
        firstName,
        gender,
        lastName,
        hotel: origin.name,
        salePrice,
        id,
        status,
      };
      voucherList.push(itemVoucher);
    });
  }
  return { type: "editVoucher", data: voucherList };
};

export const fillHotelSearchURL = (url) => {
  return { type: "fillHotelSearchURL", url };
};
export const sortHotelSearch = (input) => ({
  type: "sortHotelSearch",
  input: { ...input },
});
export const getReserveByIDAsync = (data) => ({
  type: "setVoucherDetails",
  result: { ...data },
});
export const getRoomCancellationAsync = (data) => ({
  type: "getCancellationInfos",
  result: { ...data },
});
export const setCancelationRoom = (data) => ({
  type: "setCancelationRoom",
  result: { ...data },
});

//Sync methods
export const saveParameters = (params) => ({
  type: "SaveParameter",
  params: params,
});

export const getPreReserve = (params) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetPreReserve}`,
      data: { ...params },
      headers: { ...Header },
    })
      .then((response) => {
        dispatch(saveParameters(response.data.result));
        dispatch(editMessage(true));
      })
      .catch((error) => {
        try {
          dispatch(
            showError({
              visibility: true,
              content: error.response.data.error.message,
            })
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};
export const getPreReserveID = (params) => {
  return (dispatch) => {
    axios({
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetPreReserveID}`,
      params: params,
      headers: { ...Header },
    })
      .then((response) => {
        dispatch(getHotelDetailsAsync(response.data.result));
        dispatch(editMessage(true));
      })
      .catch((error) => {
        try {
          dispatch(
            showError({
              visibility: true,
              content: error.response.data.error.message,
            })
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};
export const getReserveByID = (params) => {
  return (dispatch) => {
    axios({
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetReserveByID}`,
      params: params,
      headers: { ...Header },
    })
      .then((response) => {
        console.log("response reserve ID is");
        console.log("hotel status is:", response.data.result.status);
        dispatch(selectedHotel(response.data.result));
        dispatch(editMessage(true));
      })
      .catch((error) => {
        try {
          dispatch(
            showError({
              visibility: true,
              content: error.response.data.error.message,
            })
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};
export const postReserve = (params) => {
  return (dispatch) => {
    //handle header
    let token = localStorage.getItem("userToken");
    let HeaderAthu = null;
    if (token) {
      HeaderAthu = { Authorization: `Bearer ${token}`, ...Header };
    } else {
      HeaderAthu = { ...Header };
    }
    //
    axios({
      method: "post",
      url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.PostReserver}`,
      data: { ...params },
      headers: HeaderAthu,
    })
      .then((response) => {
        dispatch(setReserveID(response.data.result.reserveId));
        dispatch(editMessage(true));
      })
      .catch((error) => {
        try {
          dispatch(
            showError({
              visibility: true,
              content: error.response.data.error.message,
            })
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};
export const getHotelReserve = (type,userEmail) => {
  return (dispatch) => {
    //handle header
    let token = localStorage.getItem("userToken");
    let HeaderAthu = null;
    if (token) {
      HeaderAthu = { Authorization: `Bearer ${token}`, ...Header };
    } else {
      HeaderAthu = { ...Header };
    }
    //
    let requestUrl,params;
    if (type==="NaminTravelFlights"){
      requestUrl =`${ServerAddress.Type}${ServerAddress.Flight}${Reserve.naminTravelFlight}`;
      params = { Email: userEmail };
    }else{
      requestUrl =`${ServerAddress.Type}${ServerAddress.Hotel}${Reserve.hotel}`;
      params = { ReserveTypes: type, PageNumber: 1, PageSize: 100000 };
    }
    axios({
      method: "get",
      url: requestUrl,
      params: params ,
      headers: HeaderAthu,
    })
      .then((response) => {
        if (type === "FlightGlobal" || type === "NaminTravelFlights") {
          dispatch(getFlightReserveAsync(type === "NaminTravelFlights" ? response.data.result.items : response.data.result));
          dispatch(editMessage(true));
        } else {
          dispatch(getHotelReserveAsync(response.data.result));
          dispatch(editMessage(true));
        }
      })
      .catch((error) => {
        try {
          dispatch(
            showError({
              visibility: true,
              content: error.response.data.error.message,
            })
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};
export const getHotelVoucherDetails = (params) => {
  return (dispatch) => {
    axios({
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetVoucherDetails}`,
      params,
      headers: { ...Header },
    })
      .then((response) => {
        dispatch(getReserveByIDAsync(response.data.result));
      })
      .catch((error) => {
        try {
          dispatch(
            showError({
              visibility: true,
              content: error.response.data.error.message,
            })
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};
export const bookhotel = (params) => {
  return (dispatch) => {
    //handle header
    let token = localStorage.getItem("userToken");
    let HeaderAthu = null;
    if (token) {
      HeaderAthu = { Authorization: `Bearer ${token}`, ...Header };
    } else {
      HeaderAthu = { ...Header };
    }
    //
    axios({
      method: "post",
      url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.BookHotel}`,
      data: { ...params },
      headers: HeaderAthu,
    })
      .then((responsive) => {
        dispatch(
          push(
            `/profile/details?userName=${params.userName}&reserveId=${params.reserveId}`
          )
        );
      })
      .catch((error) => {
        try {
          dispatch(
            push(
              `/profile/details?userName=${params.userName}&reserveId=${params.reserveId}`
            )
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};

export const getCancellationPolicy = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetCancellationPolicy}`,
      headers: { ...Header },
      params: params,
    };

    let response = await axios(config);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const preReserve = async (param) => {
  let header = { ...Header };
  try {
    let response = await axios.post(`${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.PreReserve}`,
     param,
     { headers: { ...header } });
    return response.data.result;
  } catch (error) {
    return error.response.data;
  }
};
export const setSafarmarketHotelPixel = (data)=>({type:"safarmarketHotelPixel",data:data});