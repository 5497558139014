import React from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../Actions/UserAction";
import { Link } from "react-router-dom";
import { toggleVarification } from "../../Actions/VisualDataAction";
class ProfileBallon extends React.Component {
  state = {
    visibileBallon: false,
  };
  toggleBallon = () => {
    this.setState((prevState) => {
      return {
        visibileBallon: !prevState.visibileBallon,
      };
    });
  };
  logout = () => {
    this.props.logout();
    this.props.toggleVarification(false);
  };
  render() {
    const { Dictionary } = this.props;
    return (
      <div className="ballonContainer">
        <Link to={"/profile/"} className="link-style-btn">
          {this.props.safarmarketLayout?<span className="itours-icon user-circle-dark" title={Dictionary.userProfile}/>:Dictionary.userProfile}
        </Link>
        {this.props.safarmarketLayout ? null :<button
          onClick={this.logout}
          type="button"
          className="link-style-btn margin-start"
        >
          {Dictionary.signOut}
        </button>}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  model: state.UserData,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  safarmarketLayout:state.VisualData.safarmarketLayout
});
const MapActionToProps = (dispatch) => ({
  logout: () => {
    dispatch(logoutUser());
  },
  toggleVarification: (data) => dispatch(toggleVarification(data)),
});
export default connect(mapStateToProps, MapActionToProps)(ProfileBallon);
