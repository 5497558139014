import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import moment from "moment-jalaali";
import { Flight as FlightURL, Header, ServerAddress } from "../../Enum/Urls";
import airplane from "../../image/airplane.svg";
import Price from "../Common/Price";
import { Collapse, Button,Tag } from "antd";
import { showError } from "../../Actions/VisualDataAction";
import { connect } from "react-redux";
import _ from "lodash";
import FlightItinerary from "./FlightItinery";

class FlighSummary extends React.PureComponent {
  state = {
    showItinerariModal: false,
    activeTab: "flightItinery",
    showRules: false,
    rulesContent: null
  };
  openItinerariModal = () => {
    this.setState({
      showItinerariModal: true,
    });
  };
  closeItinerariModal = () => {
    this.setState({
      showItinerariModal: false,
    });
  };
  static propTypes = {
    model: PropTypes.any.isRequired,
    fareInfo: PropTypes.any.isRequired,
    totalPrice: PropTypes.any.isRequired,
    currencyCode: PropTypes.any.isRequired,
    showButton: PropTypes.any.isRequired,
  };
  componentDidMount() {
    function getOffsetTop(elem) {
      var offsetTop = 0;
      do {
        if (!isNaN(elem.offsetTop)) {
          offsetTop += elem.offsetTop;
        }
      } while (elem === elem.offsetParent);
      return offsetTop;
    }
    function getOffsetLeft(elem) {
      var offsetLeft = 0;
      do {
        if (!isNaN(elem.offsetLeft)) {
          offsetLeft += elem.offsetLeft;
        }
      } while (elem === elem.offsetParent);
      return offsetLeft;
    }
    function sidesEffect() {
      if (document.getElementsByClassName("sides-holder").length > 0) {
        var docTop = document.documentElement.scrollTop,
          elmntsTop = getOffsetTop(
            document.getElementsByClassName("sides-holder")[0]
          ),
          elmnt1 = document.getElementsByClassName("side-item")[0],
          elmnt2 = document.getElementsByClassName("side-item")[1],
          h1 = elmnt1.offsetHeight,
          h2 = elmnt2.offsetHeight,
          h,
          H,
          smallSide,
          bigSide,
          smallSideParent,
          smallSideWidth,
          smallSidePosLeft;

        if (h1 < h2) {
          smallSide = document.getElementsByClassName("side-item")[0];
          bigSide = document.getElementsByClassName("side-item")[1];
        } else {
          smallSide = document.getElementsByClassName("side-item")[1];
          bigSide = document.getElementsByClassName("side-item")[0];
        }
        smallSideParent = smallSide.parentElement;
        smallSideParent.style.position = "relative";
        smallSideParent.style.minHeight = "1px";
        h = smallSide.offsetHeight;
        H = bigSide.offsetHeight;
        smallSideWidth = smallSideParent.offsetWidth;
        smallSidePosLeft = getOffsetLeft(smallSideParent) - 1;
        if (window.innerWidth < 850) {
          smallSide.style.position = "static";
          smallSide.style.top = "";
          smallSide.style.bottom = "";
          smallSide.style.width = "";
          smallSide.style.left = "";
        } else {
          if (docTop < elmntsTop) {
            smallSide.style.position = "static";
            smallSide.style.top = "";
            smallSide.style.bottom = "";
            smallSide.style.width = "";
            smallSide.style.left = "";
          } else {
            if (docTop < elmntsTop + H - h) {
              smallSide.style.position = "fixed";
              smallSide.style.top = 0;
              smallSide.style.bottom = "";
              smallSide.style.width = smallSideWidth + "px";
              smallSide.style.left = smallSidePosLeft + "px";
            } else {
              smallSide.style.position = "absolute";
              smallSide.style.top = "";
              smallSide.style.bottom = 0;
              smallSide.style.width = "";
              smallSide.style.left = 0;
            }
          }
        }
      }
    }
    document.onscroll = sidesEffect;
    window.onresize = sidesEffect;
  }
  showPassenger(cnt, type, price) {
    const { Dictionary } = this.props;
    return (
      <div className={"pair-row"}>
        <div className="passenger-type-title">
          {cnt} <span> x </span>
          {type === "ADT" && Dictionary.adult}
          {type === "CHD" && Dictionary.child}
          {type === "INF" && Dictionary.infant}
        </div>
        <div dir="ltr">
          <Price currency={this.props.currencyCode}>{price}</Price>
        </div>
      </div>
    );
  }
  showEachPassenger (index,type, extraBaggage) {
    const { Dictionary } = this.props;
    const { Panel } = Collapse;
    const passengerItemFare = this.props.fareInfo.filter(item=> item.passengerTypeQuantity.code === type)[0].passengerFare;
    return (
      <Panel
        className="PassengerType"
        header={
          <div className={"pair-row"}>
            <div className="passenger-type-title">
              {Dictionary.passenger} <span> {index+1} </span>
              ({type === "ADT" ? Dictionary.adult
              :type === "CHD" ? Dictionary.child
              :type === "INF" ? Dictionary.infant
              :type})
            </div>
            <div dir="ltr">
              <Price currency={this.props.currencyCode}>{extraBaggage ? passengerItemFare.totalFareWithExtraBaggage:passengerItemFare.totalFare }</Price>
            </div>
          </div>
        }
        key={index}
      >
        <div className="pair-row baseFare gray">
          <div>{Dictionary.baseFare}: </div>
          <div dir="ltr">
            <Price currency={this.props.currencyCode}>
              {passengerItemFare.baseFare}
            </Price>
          </div>
        </div>
        <div className="pair-row taxes gray">
          <div>{Dictionary.taxes}:</div>
          <div dir="ltr">
            <Price currency={this.props.currencyCode}>
              {passengerItemFare.totalTaxes
                ? passengerItemFare.totalTaxes
                : _.sumBy(
                  passengerItemFare.taxes,
                    (item) => item.amount
                  )}
            </Price>
          </div>
        </div>

        {extraBaggage ? <div className="pair-row baseFare gray">
          <div>{Dictionary.extraBaggage}: </div>
          <div dir="ltr">
            <Price currency={this.props.currencyCode}>
              {passengerItemFare.extraBaggage}
            </Price>
          </div>
        </div>:null}
      </Panel>

    );  
  }

  getRules = (key) => {
    axios({
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetRules}`,
      params: { key },
      headers: { ...Header },
    })
      .then((response) => {
        this.setState({
          rulesContent: response.data.result,
        });
      })
      .catch((error) => {
        try {
          this.props.showError({
            visibility: true,
            content: error.response.data.error.message,
          });
        } catch (e) {
          this.props.showError({
            visibility: true,
            content: "Internal Error,Please refresh the page",
          });
        }
      });
  };

  
  tabClick = (item) => {
    this.setState({
      activeTab: item,
    });
    if (item === "flightRules") {
      this.toggleRules(true);
    }
  };
  toggleRules = (value) => {
    if (!this.props.supplierKey) return;
    if (value) {
      this.getRules(this.props.supplierKey);
    }
    this.setState({
      showRules: value,
    });
  };
  render() {
    const { Dictionary, LAN } = this.props;
    const { Panel } = Collapse;
    const flightType = this.props.flightType &&<div className="summary-flight-type-row">
      <Tag 
        className="no-margin-end"
        color={this.props.model.flightType==="Systemic"?"purple":"orange"}
      >
        {this.props.flightType==="Systemic"?
          Dictionary.Systemic
        :this.props.flightType==="Charter"?
          Dictionary.Charter
        : this.props.flightType}
      </Tag>
    </div>
    return (
      <div className="ticket-style-holder">
        <div className="ticket-style-section">
          <div className="hr_padding clearfix">
            <Button
              className="pull-end no-padding"
              type="link"
              htmlType="button"
              onClick={this.openItinerariModal}
            >
              {Dictionary.flightDetails}
            </Button>
            <h5 className="normal-title">{Dictionary.selectedFlight}</h5>
              {/* {
              (this.props.supplierKey && (this.props.supplierKey.slice(0, 3) === "009") )
              ||
              (this.props.providerName && (this.props.providerName ==="TravelFusion") )
              ?
                <>
                <Tag color="red" className="itours-tag font-16">{Dictionary.nonChangeable}</Tag>
                  <Tag color="red" className="itours-tag font-16">{Dictionary.nonRefundable}</Tag>
                </>
              :
                null
              } */}
            <div className="selected-flights-info">
              {this.props.model.map((item) => {
                return (
                  <div
                    className="margin-bottom-light"
                    key={item.flightSegments[0].flightNumber}
                  >
                    <div>
                      <strong className="font-14 margin-end-light">
                        {moment(
                          item.flightSegments[0].departureDateTime
                        ).format("YYYY-MM-DD")}
                      </strong>
                      {LAN === "IR" ? (
                        <span className="font-12">
                          (
                          {moment(
                            item.flightSegments[0].departureDateTime
                          ).format("jYYYY-jMM-jDD")}
                          )
                        </span>
                      ) : null}
                    </div>
                    <div className="route-item">
                      <div className="route-item-flex">
                        <div className="airline-logo-holder summery-airline-logo">
                          <img
                            src={
                              item.flightSegments[0].marketingAirline.photoUrl
                            }
                            alt={item.flightSegments[0].marketingAirline.name}
                            className="airline-logo"
                          />
                        </div>
                        <div>
                          <div className="router">
                            <div className="originInfo">
                              <div className="time">
                                <strong>
                                  {moment(
                                    item.flightSegments[0].departureDateTime
                                  ).format("HH:mm")}
                                </strong>
                              </div>
                              <div className="codeCity">
                                {
                                  item.flightSegments[0].departureAirport
                                    .locationCode
                                }
                              </div>
                            </div>
                            <div className="stop-info text-center">
                              <div className="stop">
                                {item.flightSegments.length - 1 === 0 ? (
                                  <span className="green">
                                    {Dictionary.direct}
                                  </span>
                                ) : (
                                  <span>
                                    {item.flightSegments.length - 1}{" "}
                                    {Dictionary.stops}
                                  </span>
                                )}
                              </div>
                              <div className="dashed-line">
                                {" "}
                                <img
                                  src={airplane}
                                  alt="airplane"
                                  title={"airplane"}
                                />
                              </div>
                              <div className="duration">
                                {item.journeyDuration === "00:00:00" ? "":
                                <>
                                  {Dictionary.flightDuration}
                                  {item.journeyDuration}
                                </>
                                }
                              </div>
                            </div>
                            <div className="destinationInfo text-end">
                              <div className="time">
                                <strong>
                                  {(item.flightSegments[item.flightSegments.length-1].arrivalDateTime === '0001-01-01T00:00:00')?"-- : --": moment(
                                    item.flightSegments[
                                      item.flightSegments.length - 1
                                    ].arrivalDateTime
                                  ).format("HH:mm")}
                                </strong>
                              </div>
                              <div className="codeCity">
                                {
                                  item.flightSegments[
                                    item.flightSegments.length - 1
                                  ].arrivalAirport.locationCode
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showItinerariModal
              ? "itours-modal visible"
              : "itours-modal"
          }
        >
          <div className="modal-overlay" onClick={this.closeItinerariModal} />
          <div className="modal-inner">
            {this.props.showFlightAllDetails ?null:<div className="modal-header">{Dictionary.flightDetails}</div>}
            <div className={`modal-main ${this.props.showFlightAllDetails?"no-padding":""}`}>
              <div className="flightDetails">
                <div className="detail-flight">
                  {this.props.showFlightAllDetails?
                  //---------start
                  <div className="flight-detail-main">
                      <div className="itours-tab-holder">
                        <div className="itours-tab-list">
                          <button
                            type="button"
                            className={
                              this.state.activeTab === "flightItinery"
                                ? "itours-tab-btn active"
                                : "itours-tab-btn"
                            }
                            onClick={() => this.tabClick("flightItinery")}
                          >
                            {Dictionary.flightItinery}
                          </button>
                          <button
                            type="button"
                            className={
                              this.state.activeTab === "baggageInfo"
                                ? "itours-tab-btn active"
                                : "itours-tab-btn"
                            }
                            onClick={() => this.tabClick("baggageInfo")}
                          >
                            {Dictionary.baggageInfo}
                          </button>
                          <button
                            type="button"
                            className={
                              this.state.activeTab === "flightFare"
                                ? "itours-tab-btn active"
                                : "itours-tab-btn"
                            }
                            onClick={() => this.tabClick("flightFare")}
                          >
                            {Dictionary.flightFare}
                          </button>
                          {this.props.supplierKey && <button
                            type="button"
                            className={
                              this.state.activeTab === "flightRules"
                                ? "itours-tab-btn active"
                                : "itours-tab-btn"
                            }
                            onClick={() => this.tabClick("flightRules")}
                          >
                            {Dictionary.flightRules}
                          </button>}
                        </div>
                        <div className="itours-tab-contents">
                          {this.state.activeTab === "flightItinery" ? (
                            <div className="detail-flight">
                              <FlightItinerary model={this.props.model} />
                            </div>
                          ) : (
                            false
                          )}
                          {this.state.activeTab === "baggageInfo" ? (
                            <div className="flight-baggage-detail">
                              {this.props.model.map((item) => {
                                return (
                                  <div
                                    className="detail-flight-item "
                                    key={item.flightSegments[0].flightNumber}
                                  >
                                    {item.flightSegments.map((item, index) => {
                                      return (
                                        <div
                                          className="baggegesInformation"
                                          dir="ltr"
                                          key={index}
                                        >
                                          <div className="text-left">
                                            <div>
                                              <b>{item.departureAirport.cityName}</b> -{" "}
                                              <small>
                                                {item.departureAirport.countryName}
                                              </small>
                                            </div>
                                          </div>
                                          <div className={"airplaneIcon"}>
                                            <img
                                              src={airplane}
                                              alt={"airplane"}
                                              title={"airplane"}
                                            />
                                          </div>
                                          <div className="text-left">
                                            <div>
                                              <b>{item.arrivalAirport.cityName}</b> -{" "}
                                              <small>
                                                {item.arrivalAirport.countryName}
                                              </small>
                                            </div>
                                          </div>
                                          <div>
                                            {item.baggageInformation.map(
                                              (baggageItem, baggageIndex) => (
                                                <div key={baggageIndex}>
                                                  {baggageItem.baggageAllowance}{" "}
                                                  {baggageItem.unitType}{" "}
                                                  {this.props.hasExtraBaggage && <span>(
                                                    +{item.extraBaggageInformation.filter(x=>x.passengerType === baggageItem.passengerType)[0].baggageAllowance}  {baggageItem.unitType}{" "} {Dictionary.extraBaggage}
                                                  )</span>}
                                                  <span> {Dictionary.for} </span>
                                                  {baggageItem.passengerType === "ADT" && (
                                                    <span>{Dictionary.adult}</span>
                                                  )}
                                                  {baggageItem.passengerType === "CHD" && (
                                                    <span>{Dictionary.child}</span>
                                                  )}
                                                  {baggageItem.passengerType === "INF" && (
                                                    <span>{Dictionary.infant}</span>
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            false
                          )}
                          {this.state.activeTab === "flightFare" ? (
                            <div className={"fareContainer"}>
                              <div className="title">{Dictionary.fareTotal}</div>
                              <div className="baseFare">
                                <strong>{Dictionary.baseFare}: </strong>
                                <Price currency={this.props.currency}>
                                  {this.props.itinTotalFare.baseFare}
                                </Price>
                              </div>
                              {this.props.itinTotalFare.totalFee?
                                  <div className="fees">
                                    <strong>{Dictionary.fees}: </strong>
                                    <Price currency={this.props.currency}>
                                      {this.props.itinTotalFare.totalFee}
                                    </Price>
                                  </div>
                               :
                              this.props.itinTotalFare.fess && (this.props.itinTotalFare.fess.length > 0) && (
                                <div className="fees">
                                  <strong>{Dictionary.fees}: </strong>
                                  <Price currency={this.props.currency}>
                                    {this.props.itinTotalFare.fess[0].amount}
                                  </Price>
                                </div>
                              )}
                              {this.props.itinTotalFare.totalTaxes?
                                  <div className="fees">
                                    <strong>{Dictionary.taxes}: </strong>
                                    <Price currency={this.props.currency}>
                                      {this.props.itinTotalFare.totalTaxes}
                                    </Price>
                                  </div>
                               :
                               this.props.itinTotalFare.taxes && (this.props.itinTotalFare.taxes.length > 0) && (<div className="taxes">
                                <strong>{Dictionary.taxes}: </strong>
                                <Price currency={this.props.currency}>
                                  {this.props.itinTotalFare.taxes[0].amount}
                                </Price>
                              </div>)}
                              {this.props.hasExtraBaggage && 
                              <div className="extraBaggage">
                                <strong>{Dictionary.extraBaggage}: </strong>
                                <Price currency={this.props.currency}>
                                  {this.props.itinTotalFare.extraBaggage}
                                </Price>
                              </div>                      
                              }
                              <hr />
                              <div className="totalPrice">
                                <strong>{Dictionary.totalPrice}: </strong>
                                <Price currency={this.props.currency}>
                                  {this.props.itinTotalFare.totalFare}
                                </Price>
                              </div>

                              {this.props.hasExtraBaggage && <div className="totalPrice">
                                <strong>{Dictionary.totalFareWithExtraBaggage}: </strong>
                                <Price currency={this.props.currency}>
                                  {this.props.itinTotalFare.totalFareWithExtraBaggage}
                                </Price>
                              </div>}
                            </div>
                          ) : (
                            false
                          )}
                          {this.state.activeTab === "flightRules" ? (
                            <div className="rules-container">
                              {this.state.rulesContent ? (
                                <>
                                  {this.state.rulesContent.flightRules.length === 0 ? (
                                    <div>{Dictionary.informationNotFound}</div>
                                  ) : (
                                    <>
                                      {this.state.rulesContent.flightRules.map(
                                        (item, index) => {
                                          return (
                                            <div
                                              className="rules-item en-font"
                                              key={index}
                                            >
                                              <div className="rule-title">
                                                FareBase: {item.fareBase}
                                              </div>
                                              <Collapse>
                                                {item.flightRule.map(
                                                  (itemIn, indexIn) => {
                                                    return (
                                                      <Panel
                                                        header={itemIn.ruleTitle}
                                                        key={indexIn}
                                                      >
                                                        <p>{itemIn.ruleDescription}</p>
                                                      </Panel>
                                                    );
                                                  }
                                                )}
                                              </Collapse>
                                            </div>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <div>
                                  {Dictionary.loadingData}
                                  <span className="dots-loading">
                                    <span className="dot">.</span>
                                    <span className="dot">.</span>
                                    <span className="dot">.</span>
                                  </span>
                                </div>
                              )}
                            </div>
                          ) : (
                            false
                          )}
                        </div>
                        <div className="hidden-medium hidden-large">
                        </div>
                      </div>
                  </div>              
                  //---------end
                  :
                    <FlightItinerary model={this.props.model} />               
                  }
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="button color-1-btn"
                onClick={this.closeItinerariModal}
              >
                {Dictionary.ok}
              </button>
            </div>
          </div>
        </div>
        <div className="ticket-style-section">
          <div className="hr_padding">
            <h5 className="normal-title">{Dictionary.priceDetails}</h5>
            <div className="prices-info">
              {this.props.fareInfo !== null && (
                <Collapse bordered={false} defaultActiveKey={[null]}>
                  {this.props.hasExtraBaggage && this.props.passengerInformation ?
                  this.props.passengerInformation.map((passengerItem,passengerIndex)=>this.showEachPassenger(
                    passengerIndex,
                    passengerItem.type,
                    passengerItem.services.extraBaggage
                  ))
                  :
                   this.props.fareInfo.map((item, index) => {
                    return (
                      <Panel
                        className="PassengerType"
                        header={this.showPassenger(
                          item.passengerTypeQuantity.quantity,
                          item.passengerTypeQuantity.code,
                          // %%%extra this.props.extraBaggageIsSelected ? item[this.props.feild].totalFareWithExtraBaggage :
                           item[this.props.feild].totalFare
                        )}
                        key={index}
                      >
                        <div className="pair-row baseFare gray">
                          <div>{Dictionary.baseFare}: </div>
                          <div dir="ltr">
                            <Price currency={this.props.currencyCode}>
                              {item[this.props.feild].baseFare}
                            </Price>
                          </div>
                        </div>
                        <div className="pair-row taxes gray">
                          <div>{Dictionary.taxes}:</div>
                          <div dir="ltr">
                            <Price currency={this.props.currencyCode}>
                              {item[this.props.feild].totalTaxes
                                ? item[this.props.feild].totalTaxes
                                : _.sumBy(
                                    item[this.props.feild].taxes,
                                    (item) => item.amount
                                  )}
                            </Price>
                          </div>
                        </div>

                        {/* %%%extra {this.props.extraBaggageIsSelected ? <div className="pair-row baseFare gray">
                          <div>{Dictionary.extraBaggage}: </div>
                          <div dir="ltr">
                            <Price currency={this.props.currencyCode}>
                              {item[this.props.feild].extraBaggage}
                            </Price>
                          </div>
                        </div>:null} */}


                      </Panel>
                    );
                  })}
                </Collapse>
              )}
            </div>
            {flightType}
            <div className="totalPrice inli">
              <div className={"pair-row priceCur"}>
                <strong className="font-14">{Dictionary.totalPrice}:</strong>
                <div>
                  <b>
                    <Price currency={this.props.currencyCode}>
                      {this.props.hasExtraBaggage ? this.props.calculatedTotalPriceWithExtraBaggage:this.props.totalPrice}
                    </Price>
                  </b>
                </div>
              </div>
              {this.props.showButton && (
                <div>
                  <button disabled={this.props.disabledButton} className="button red-btn full-width has-arrow thick-btn">
                    {Dictionary.continueToPayment}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
const mapDispatchToProps = (dispatch) => ({
  showError: (param) => dispatch(showError(param))
});
export default connect(mapStateToProps,mapDispatchToProps)(FlighSummary);
