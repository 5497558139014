import {FilterType, Flight, FlightFilter} from '../Enum/Models';
import moment from 'moment';
import _ from "lodash";
export const FlightSearch=(state=new Flight(),action)=>{
  switch (action.type) {
      case "FLIGHTSEARCH":
          let fligths=[];
          let flightBackup=[];
          let stop=null;
          action.result.pricedItineraries.forEach((item)=>{
            let segmentStop=[];
            let wayLength=item.airItinerary.originDestinationOptions.length;
            item.airItinerary.originDestinationOptions.forEach(item=>{
                segmentStop.push(item.flightSegments.length);
            });
            let sunStop=segmentStop.reduce((sum,item)=>sum+=item);
            if(sunStop===wayLength){
                stop=0;
            }else if((sunStop>wayLength)&&(sunStop<=(wayLength*2))){
                stop=1;
            }else{
                stop=2;
            }


             let flightItem={
                availableSeats: item.availableSeats,
                flightType: item.flightType,
                priceInfo:item.airItineraryPricingInfo.itinTotalFare,
                isEthnic:item.isEthnic,
                 key:item.key,
                 hasExtraBaggage:item.hasExtraBaggage,
                 airItineraryPricingInfo:item.airItineraryPricingInfo,
                 totalPrice:item.airItineraryPricingInfo.itinTotalFare.totalFare,
                 airItinerary:item.airItinerary.originDestinationOptions,
                 stop,
                 class:item.airItinerary.originDestinationOptions[0].flightSegments[0].cabinClass.name,
                 airline:item.airItinerary.originDestinationOptions[0].flightSegments[0].marketingAirline.name,
                 departureDateTime:item.airItinerary.originDestinationOptions[0].flightSegments[0].departureDateTime,
                 arrivalDateTime:item.airItinerary.originDestinationOptions[item.airItinerary.originDestinationOptions.length-1].flightSegments[item.airItinerary.originDestinationOptions[item.airItinerary.originDestinationOptions.length-1].flightSegments.length-1].arrivalDateTime,
             };
             fligths.push(flightItem);
             flightBackup.push(flightItem);
          });
          //prepare fitler show
            let flightFilter=new FlightFilter();
           fligths.forEach(item=>{
                flightFilter.class.value.push(item.class);
                flightFilter.airline.value.push(item.airline);
           });
           flightFilter.class.value=_.uniq(flightFilter.class.value);
           flightFilter.airline.value=_.uniq(flightFilter.airline.value);
           //prepare price base on airline flight
          //1-find airItinerary that have same airline
          let uniqAirItinerary=[];
          let ethnicUniqAirItinerary=[];
          let noEthnicUniqAirItinerary=[];
          let leg=fligths.length && fligths[0].airItinerary.length;   
          fligths.forEach(item=>{
              let cashAirline=[];
              item.airItinerary.forEach(airItinerayItem=>{
                  airItinerayItem.flightSegments.forEach(segmentItem=>cashAirline.push(segmentItem.marketingAirline.code));
              });
            //   if(cashAirline.every((value,index,array)=>value===array[0])){
                  let airlineTarget=item.airItinerary[0].flightSegments[0].marketingAirline.code;
                  if(uniqAirItinerary[airlineTarget]){
                      if(cashAirline.length===leg){
                          if(uniqAirItinerary[airlineTarget].noneStop){
                              if(item.priceInfo.totalFare < uniqAirItinerary[airlineTarget].noneStop.priceInfo.totalFare){
                                  uniqAirItinerary[airlineTarget].noneStop=item;
                              }
                          }else{
                              uniqAirItinerary[airlineTarget]={...uniqAirItinerary[airlineTarget],noneStop:item};
                          }
                      }else{
                          if(uniqAirItinerary[airlineTarget].multiStop){
                              if(item.priceInfo.totalFare < uniqAirItinerary[airlineTarget].multiStop.priceInfo.totalFare){
                                  uniqAirItinerary[airlineTarget].multiStop=item;
                              }
                          }else{
                              uniqAirItinerary[airlineTarget]={...uniqAirItinerary[airlineTarget],multiStop:item};
                          }
                      }
                  }else{
                      if(cashAirline.length===leg){
                          uniqAirItinerary[airlineTarget]={noneStop:item}
                      }
                      else{
                          uniqAirItinerary[airlineTarget]={multiStop:item}
                      }
                  }

            //   }

          })

          fligths.filter(i => i.isEthnic).forEach(item=>{
            let cashAirline=[];
            item.airItinerary.forEach(airItinerayItem=>{
                airItinerayItem.flightSegments.forEach(segmentItem=>cashAirline.push(segmentItem.marketingAirline.code));
            });
          //   if(cashAirline.every((value,index,array)=>value===array[0])){
                let airlineTarget=item.airItinerary[0].flightSegments[0].marketingAirline.code;
                if(ethnicUniqAirItinerary[airlineTarget]){
                    if(cashAirline.length===leg){
                        if(ethnicUniqAirItinerary[airlineTarget].noneStop){
                            if(item.priceInfo.totalFare < ethnicUniqAirItinerary[airlineTarget].noneStop.priceInfo.totalFare){
                                ethnicUniqAirItinerary[airlineTarget].noneStop=item;
                            }
                        }else{
                            ethnicUniqAirItinerary[airlineTarget]={...ethnicUniqAirItinerary[airlineTarget],noneStop:item};
                        }
                    }else{
                        if(ethnicUniqAirItinerary[airlineTarget].multiStop){
                            if(item.priceInfo.totalFare < ethnicUniqAirItinerary[airlineTarget].multiStop.priceInfo.totalFare){
                                ethnicUniqAirItinerary[airlineTarget].multiStop=item;
                            }
                        }else{
                            ethnicUniqAirItinerary[airlineTarget]={...ethnicUniqAirItinerary[airlineTarget],multiStop:item};
                        }
                    }
                }else{
                    if(cashAirline.length===leg){
                        ethnicUniqAirItinerary[airlineTarget]={noneStop:item}
                    }
                    else{
                        ethnicUniqAirItinerary[airlineTarget]={multiStop:item}
                    }
                }

          //   }

          })

          fligths.filter(i => !i.isEthnic).forEach(item=>{
            let cashAirline=[];
            item.airItinerary.forEach(airItinerayItem=>{
                airItinerayItem.flightSegments.forEach(segmentItem=>cashAirline.push(segmentItem.marketingAirline.code));
            });
          //   if(cashAirline.every((value,index,array)=>value===array[0])){
                let airlineTarget=item.airItinerary[0].flightSegments[0].marketingAirline.code;
                if(noEthnicUniqAirItinerary[airlineTarget]){
                    if(cashAirline.length===leg){
                        if(noEthnicUniqAirItinerary[airlineTarget].noneStop){
                            if(item.priceInfo.totalFare < noEthnicUniqAirItinerary[airlineTarget].noneStop.priceInfo.totalFare){
                                noEthnicUniqAirItinerary[airlineTarget].noneStop=item;
                            }
                        }else{
                            noEthnicUniqAirItinerary[airlineTarget]={...noEthnicUniqAirItinerary[airlineTarget],noneStop:item};
                        }
                    }else{
                        if(noEthnicUniqAirItinerary[airlineTarget].multiStop){
                            if(item.priceInfo.totalFare < noEthnicUniqAirItinerary[airlineTarget].multiStop.priceInfo.totalFare){
                                noEthnicUniqAirItinerary[airlineTarget].multiStop=item;
                            }
                        }else{
                            noEthnicUniqAirItinerary[airlineTarget]={...noEthnicUniqAirItinerary[airlineTarget],multiStop:item};
                        }
                    }
                }else{
                    if(cashAirline.length===leg){
                        noEthnicUniqAirItinerary[airlineTarget]={noneStop:item}
                    }
                    else{
                        noEthnicUniqAirItinerary[airlineTarget]={multiStop:item}
                    }
                }

          //   }

          })

          //check oneStop or twoStop
          let tempUniqAirItinerary=Object.entries(uniqAirItinerary);
          uniqAirItinerary=tempUniqAirItinerary.map(item=>item[1]);

          let ethnicTempUniqAirItinerary=Object.entries(ethnicUniqAirItinerary);
          ethnicUniqAirItinerary=ethnicTempUniqAirItinerary.map(item=>item[1]);

          let noEthnicTempUniqAirItinerary=Object.entries(noEthnicUniqAirItinerary);
          noEthnicUniqAirItinerary=noEthnicTempUniqAirItinerary.map(item=>item[1]);

          flightFilter.airlinePrice=uniqAirItinerary;
          flightFilter.airlinePriceEthnic=ethnicUniqAirItinerary;
          flightFilter.airlinePriceNoEthnic=noEthnicUniqAirItinerary;

          //add systemic or charter filter:
          let flightTypesArray = [];
          fligths.forEach(item=>{
            if (item.flightType) flightTypesArray.push(item.flightType);
          });
          flightFilter.flightType.value=[...new Set(flightTypesArray)];

          return{
              ...state,
              flight:fligths,
              backupFlight:flightBackup,
              filterShow:flightFilter
          };
      case "sortFlightSearch":
          let flightData=JSON.stringify(state.flight);
          let flightDataBackup=JSON.stringify(state.backupFlight);
          flightData=JSON.parse(flightData);
          flightDataBackup=JSON.parse(flightDataBackup);
          flightData= _.sortBy(flightData,action.input.typeSort);
          flightDataBackup= _.sortBy(flightDataBackup,action.input.typeSort);
          if(action.input.isReverse){
              flightData=flightData.reverse();
              flightDataBackup=flightDataBackup.reverse();
          }
          return {
              ...state,
              flight:flightData,
              backupFlight:flightDataBackup
          }
      case "resetFilters":
        return {
            ...state,
            filterSelect:new FlightFilter()
        }

      case "FlightFilterSelect":
          let filterSelect=state.filterSelect;
          if(action.input.isAdd){
                  filterSelect[action.input.type].value.push(action.input.value);
          }else{
                filterSelect[action.input.type].value=filterSelect[action.input.type].value.filter((item)=>{
                return item!==action.input.value
              })
          }
          return {
              ...state,
              filterSelect:filterSelect
          }

        case "FlightFilterSelectNaminTravelAirlineStopEthnic":
            let naminUpdatedItems;
            if(action.input.isAdd){
                let newItem = {
                    airline:action.input.airline,
                    stop:action.input.stop,
                    ethnic:action.input.ethnic
                }                
                naminUpdatedItems = [...state.filterSelect.naminTravelAirlinePrice.value,newItem];
            }else{
                naminUpdatedItems = state.filterSelect.naminTravelAirlinePrice.value.filter(filteringItem => {
                    let state=true;
                    if(
                        filteringItem.airline===action.input.airline &&
                        filteringItem.stop===action.input.stop &&
                        filteringItem.ethnic===action.input.ethnic
                    ){
                        state=false;
                    }
                    return(state)
                });
            }
            const updatedNaminTravelAirlinePriceFilter = {...state.filterSelect.naminTravelAirlinePrice,value:naminUpdatedItems}
            return {
                ...state,
                filterSelect:{...state.filterSelect,naminTravelAirlinePrice:updatedNaminTravelAirlinePriceFilter}                
            }

        case "flightFilterSelectAirlineStop":
            let updatedItems;
            if(action.input.isAdd){               
                updatedItems = [...state.filterSelect.airlinePrice.value,{
                    airline:action.input.airline,
                    stop:action.input.stop
                }];
            }else{
                updatedItems = state.filterSelect.airlinePrice.value.filter(filteringItem => {
                    let state=true;
                    if(
                        filteringItem.airline===action.input.airline &&
                        filteringItem.stop===action.input.stop
                    ){
                        state=false;
                    }
                    return(state)
                });
            }
            const updatedAirlinePriceFilter = {...state.filterSelect.airlinePrice,value:updatedItems}
            return {
                ...state,
                filterSelect:{...state.filterSelect,airlinePrice:updatedAirlinePriceFilter}                
            }

            
      case "ApplyFilterFlight":
          const filtering=(item)=>{
              let status=true;
              for(let filterName in state.filterSelect){
                  let filterItem=state.filterSelect[filterName].value;
                  if(filterItem && filterItem.length){
                      if(filterName==="departureDateTime" || filterName==="arrivalDateTime"){
                          //filter time
                          let timeStatus=false;
                          filterItem.forEach(itemFilter=>{
                              let minTime= moment(itemFilter.split("-")[0],"HH:mm");
                              let maxTime=moment(itemFilter.split("-")[1],"HH:mm");
                              let currentTime=moment(item[filterName].split("T")[1],"HH:mm:ss");
                              if(currentTime.isSameOrAfter(minTime) && currentTime.isSameOrBefore(maxTime)){
                                    timeStatus=true;
                              }
                          });
                          if(!timeStatus){
                              status=false;
                          }
                      }
                      else if(filterName==="airlinePrice"){
                          if (status){
                              status=filterItem.some(checkedItem=>{
                                  let itemStop =  item.stop?true:false;
                                  if (filterItem.length===0){
                                      return (true);
                                  }
                                  if (
                                      itemStop=== checkedItem.stop &&
                                      item.airline === checkedItem.airline) {
                                      return (true);
                                  }else{
                                      return (false);
                                  }
                                });
                          }
                      }

                      else if(filterName==="naminTravelAirlinePrice"){
                        if (status){                            
                            status=filterItem.some(checkedItem=>{
                              let itemStop =  item.stop?true:false;
                              if (filterItem.length===0){
                                  return (true);
                              }
                              if (
                                  item.isEthnic === checkedItem.ethnic && 
                                  itemStop=== checkedItem.stop &&
                                  item.airline === checkedItem.airline) {
                                  return (true);
                              }else{
                                  return (false);
                              }
                            })
                          }
                        }
                        else if(filterName==="baggage"){
                            if (status){  
                                for(let k=0; k<filterItem.length ; k++){
                                    if (filterItem[k] === "extraBaggage"){
                                        if (!item.hasExtraBaggage){
                                            status=false;
                                        }
                                    }else if(filterItem[k] === "baggage"){   
                                        let hasBaggage =true;                                 
                                        item.airItinerary.forEach((itineraryItem) => {
                                          itineraryItem.flightSegments.forEach((segmentItem, index) => {
                                            segmentItem.baggageInformation.filter(x=>x.passengerType !== "INF").forEach((baggageItem) => {
                                              if (baggageItem.baggageAllowance < 1) {
                                                hasBaggage = false;
                                              }
                                            });
                                          });
                                        });
                                        if (!hasBaggage){
                                            status=false;
                                        }
                                    }
                                }
                              }
                            }
                      else{
                          //convert number value to array
                          let valueFilterName=[item[filterName]];
                          let differentCount=_.differenceWith(filterItem,valueFilterName,_.isEqual);
                          //when if hotel should have all of selected  item in filter choice Intersection but hotel should have some item choice Union
                          if(state.filterSelect[filterName].type===FilterType.Union){
                              if(differentCount.length===filterItem.length){
                                  status=false
                              }
                          }
                          else if(state.filterSelect[filterName].type===FilterType.Intersection){
                              if(differentCount.length!==0){
                                  status=false
                              }
                          }
                      }
                  }
              }
              if(status){
                  return item;
              }
          };
          let flightResult=state.backupFlight.filter(filtering);
          return {
              ...state,
              flight:flightResult
          };
      case "setFlightData":
          return{
              ...state,
              flightSelected:action.result
          }
      case "setReserveID":
          return{
              ...state,
              reserveId:action.id
          }
      case "FLIGHTERROR":
          return {
              ...state,
              flightError:action.result
          }
      case "FLIGHTRESERVE":
          
          return{
            ...state,
            reserveFlight:action.result  
          }
      case "naminTravelSelectedFlight":
          return{
            ...state,
            naminTravelSelectedFlight:action.data  
          }
      case "safarmarketPixel":
        return{
            ...state,
            safarmarketPixel:action.data  
          }
      case "directTicketByIdResult":
          return{
            ...state,
            directTicketByIdResult:action.data   
          }
      case "getDirectTicketByIdLoading":
          return{
              ...state,
              getDirectTicketByIdLoading:action.data
          }
          
      default:
          return state;
  }
};