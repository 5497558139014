import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
//import reducers
import {VisualData} from './VisualDataReducer';
import {UserData} from './UserReducer';
import {HotelSearch} from './HotelReducer'
import {PaymentData} from './PaymentReducer';
import {FlightSearch} from './FlightReducer';
import{PackageSearch} from './PackageReducer';
//combination
const createRootReducer=(history)=>
    combineReducers({
        router:connectRouter(history),
        VisualData,UserData,HotelSearch,PaymentData,FlightSearch,PackageSearch

    });

export default createRootReducer;