import React from "react";
import {Tooltip} from "antd";
import _ from "lodash";
import Autocomplete from "../Common/Autocomplete";
import { Header, ServerAddress,Package } from "../../Enum/Urls";
import moment from "moment-jalaali";
import MultiDatePicker from "react-multi-date-picker"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import returnway from "../../image/returnway.svg";
import { showError } from "../../Actions/VisualDataAction";
import SelectRoom from "../Accomodation/SelectRoom";
import LayoutConfig from "../../Config/layoutConfig";
import SearchBoxTab from '../Common/SearchBoxTab';

class PackageSearchForm extends React.Component {
  state = {
    isMobile:false,
    infantAdultValidationErrorMessage:undefined,
    selectPassengersOptionVisibility: false,
    validation: false,
    searchParam: {
      checkin:this.props.defaultValues ? this.props.defaultValues.checkin : undefined,
      checkout: this.props.defaultValues ? this.props.defaultValues.checkout : undefined,
      originCode: this.props.defaultValues ? this.props.defaultValues.originCode : undefined,
      originName:this.props.defaultValues ? this.props.defaultValues.originText : undefined,
      destinationCode: this.props.defaultValues ? this.props.defaultValues.destinationCode : undefined,
      destinationName: this.props.defaultValues ? this.props.defaultValues.destinationText : undefined,
      adults: this.props.defaultValues ? this.props.defaultValues.adults : [2],
      children: this.props.defaultValues ? this.props.defaultValues.children : [0],
      ages: this.props.defaultValues ? this.props.defaultValues.ages : [0]
    },
    showMenu: true,
    datepickerType: this.props.LAN === "IR" ? "persian":"gregorian",
    datepickerLocale:this.props.LAN === "IR" ? "fa": this.props.LAN === "AR"? "ar":"en"
  };

  handlePassenger = (e) => {
    let infantAdultValidationErrorMessage = undefined; 
    let totalAdults=  _.sum(e.adult);
    let AdultsAndChildrenOver2 = totalAdults;
    let totalInfants= 0;
    for (let i=0 ; i< e.age.length ; i++){
      if (e.age[i] < 2 ){
        totalInfants++;
      }else{
        AdultsAndChildrenOver2++
      }
    }
    if (AdultsAndChildrenOver2 > 9){
      infantAdultValidationErrorMessage = this.props.Dictionary.TheNumberOfPassengersShouldNotBeMoreThan9person;
    }else if (totalInfants > totalAdults){
      infantAdultValidationErrorMessage = this.props.Dictionary.numberOfChildrenBelow2MustNotBeMoreThanAdults;
    }

    let passenger = JSON.stringify(this.state.searchParam);
    passenger = JSON.parse(passenger);
    passenger.adults = e.adult;
    passenger.children = e.children;
    passenger.ages = e.age;
    this.setState({
      ...this.state,
      searchParam: { ...this.state.searchParam, ...passenger },
      infantAdultValidationErrorMessage:infantAdultValidationErrorMessage
    });
  };
  closeSelectPassenger = () => {
    this.setState({
      selectPassengersShow: false,
    });
  };
  toggleSelectPassengers = () => {
    this.setState({
      selectPassengersShow: !this.state.selectPassengersShow,
    });
  };

  showPersianMonth = (input) => {
    let day = input.jMonth() + 1;
    switch (day) {
      case 1:
        return "فروردین";
      case 2:
        return "اردیبهشت";
      case 3:
        return "خرداد";
      case 4:
        return "تیر";
      case 5:
        return "مرداد";
      case 6:
        return "شهریور";
      case 7:
        return "مهر";
      case 8:
        return "آبان";
      case 9:
        return "آذر";
      case 10:
        return "دی";
      case 11:
        return "بهمن";
      case 12:
        return "اسفند";
      default:
        return null;
    }
  };
  showGregorianMonth = (input) => {
    let day = input.get("month") + 1;
    switch (day) {
      case 1:
        return "Jan";
      case 2:
        return "Feb";
      case 3:
        return "Mar";
      case 4:
        return "Apr";
      case 5:
        return "May";
      case 6:
        return "Jun";
      case 7:
        return "Jul";
      case 8:
        return "Aug";
      case 9:
        return "Sep";
      case 10:
        return "Oct";
      case 11:
        return "Nov";
      case 12:
        return "Dec";
      default:
        return null;
    }
  };
  ///
  showPersianDate = (input) => {
    let day = input.get("days");
    switch (day) {
      case 6:
        return "شنبه";
      case 0:
        return "یکشنبه";
      case 1:
        return "دوشنبه";
      case 2:
        return "سه شنبه";
      case 3:
        return "چهارشنبه";
      case 4:
        return "پنجشنبه";
      case 5:
        return "جمعه";
      default:
        return null;
    }
  };
  showGregorianDate = (input) => {
    let day = input.get("days");
    switch (day) {
      case 6:
        return "Saturday";
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      default:
        return null;
    }
  };
  componentDidMount() {
    if (window.innerWidth <= 767){
      this.setState({isMobile:true});
    }
    if (this.props.LAN === "IR") {
      moment.loadPersian({
        dialect: "persian-modern",
        usePersianDigits: false,
      });
    }

    if (this.props.inline) {
      this.setState({
        showMenu: false,
      });
    }

    this.checkSelectedPassengers();
  }

  setDate = (index, value) => {
    const date = moment(value);
    if (!index){
      if (moment(value).isAfter(this.state.searchParam.checkout)){
        this.setState({
          searchParam: {
            ...this.state.searchParam,
            checkin: date,
            checkout:date,
          },
        });
      }else{
        this.setState({
          searchParam: {
            ...this.state.searchParam,
            checkin: date,
          },
        });
      }

    }else{
      this.setState({
        searchParam: {
          ...this.state.searchParam,
          checkout: date,
        },
      });   
    }

  };
  
  autoCompleteCallback = (value, text, name, id) => {

    if (value){
      let searchParamCatch = this.state.searchParam;      
      searchParamCatch[name + "Name"] = text;
      searchParamCatch[name + "Code"] = value;
      this.setState({
        searchParam: { ...this.state.searchParam, ...searchParamCatch },
      });
    }
  };

  destToOrigin = () => {
    let newSearchParam = { ...this.state.searchParam };

    let originName = newSearchParam.originName;
    let originCode = newSearchParam.originCode;
    let destinationName = newSearchParam.destinationName;
    let destinationCode = newSearchParam.destinationCode;

    newSearchParam.originCode = destinationCode;
    newSearchParam.originName = destinationName;
    newSearchParam.destinationCode = originCode;
    newSearchParam.destinationName = originName;
    
    this.setState({
      searchParam: newSearchParam,
    });
  };

  toggleDatepicker = () =>{
    if (this.props.LAN ==="IR"){
      if(this.state.datepickerType === "gregorian"){
        this.setState({
          datepickerType:"persian",
          datepickerLocale:"fa"
        })
      }else{
        this.setState({
          datepickerType:"gregorian",
          datepickerLocale:"en"
        })
      }
    }
  }

  checkSelectedPassengers() {
    if (
      this.state.searchParam.adults.length > 1 ||
      this.state.searchParam.children[0] > 0
    ) {
      this.setState({
        roomOptionSelect: "OPTIONS",
        selectPassengersOptionVisibility: true,
      });
    } else {
      if (this.state.searchParam.adults[0] === 1) {
        this.setState({
          roomOptionSelect: "1ADT",
          selectPassengersOptionVisibility: false,
        });
      } else if (this.state.searchParam.adults[0] === 2) {
        this.setState({
          roomOptionSelect: "2ADT",
          selectPassengersOptionVisibility: false,
        });
      } else {
        this.setState({
          roomOptionSelect: "OPTIONS",
          selectPassengersOptionVisibility: true,
        });
      }
    }
  }

  selectPassengersOptions = (event) => {
    let option = event.target.value;
    if (option === "2") {
      this.setState({
        searchParam: {
          ...this.state.searchParam,
          adults: [2],
          children: [0],
          ages: [0],
        },
        roomOptionSelect:"2ADT",
        selectPassengersOptionVisibility: false,
      });
    } else if (option === "1") {
      this.setState({
        searchParam: {
          ...this.state.searchParam,
          adults: [1],
          children: [0],
          ages: [0],
        },
        roomOptionSelect:"1ADT",
        selectPassengersOptionVisibility: false,
      });
    } else {
      this.setState({
        selectPassengersOptionVisibility: true,
        roomOptionSelect:"OPTIONS"
      });
    }
  };



  submitHandler = () => {
    this.setState({
      validation: true,
    });
    let statusForm = true;
    if (this.state.infantAdultValidationErrorMessage){
      statusForm = false;
    }
    if (!this.state.searchParam.originCode || !this.state.searchParam.destinationCode || !this.state.searchParam.checkin || !this.state.searchParam.checkout) {
      statusForm = false;
    }
    if (statusForm){
      this.setState({showMenu:false});
      this.props.history.push(
        `/package/result?originCode=${this.state.searchParam.originCode}&originName=${this.state.searchParam.originName}&destinationCode=${this.state.searchParam.destinationCode}&destinationName=${this.state.searchParam.destinationName}&checkin=${moment(this.state.searchParam.checkin).format("YYYY-MM-DD")}&checkout=${moment(this.state.searchParam.checkout).format("YYYY-MM-DD")}&adults=${this.state.searchParam.adults}&children=${this.state.searchParam.children}&ages=${this.state.searchParam.ages}`
      );
    }
  };

  checkinChangeHandle = (d) => {
    const e = d.toDate();
    let checkoutDate = this.state.searchParam.checkout;
    if(checkoutDate &&  moment(checkoutDate).isAfter(e)){
      this.setState(prevState=>({
        searchParam: {
          ...prevState.searchParam,
          checkin: moment(e).format('YYYY-MM-DD')
        },
      }),
      () => {
        this.checkinRef.closeCalendar();
      }
    );
    }else{
      this.setState(prevState=>({
          searchParam: {
            ...prevState.searchParam,
            checkin: moment(e).format('YYYY-MM-DD'),
            checkout: moment(e).add(1, 'day').format('YYYY-MM-DD'),
          },
        }),
        () => {
          this.checkinRef.closeCalendar();
          this.checkoutRef.openCalendar();
        }
      );
    }
  }

  render = () => {
    let formWrapperClassNames ='';
    if (!this.props.alwaysShowForm && this.props.inline){
      formWrapperClassNames="full-container";
    }
    const { Dictionary, LAN } = this.props;

    const datepickerConfig = {
      calendarPosition : LAN === "IR" || LAN === "AR" ?"bottom-right":"bottom-left",
      weekDays:this.state.datepickerLocale === "fa" ? [
        "ش", 
        "ی", 
        "د", 
        "س", 
        "چ", 
        "پ", 
        "ج"
      ]:this.state.datepickerLocale === "ar" ?[
        "الأحد",
        "الإثنين",
        "الثلاثاء",
        "الأربعاء",
        "الخميس",
        "الجمعة",
        "السبت"
      ]:undefined,
      digits: this.state.datepickerLocale === "ar" ? [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9"
      ]:undefined,
      className:`multi-datepicker ${this.state.isMobile?"rmdp-mobile":""} ${LAN === "IR" && this.state.datepickerLocale === "en" ? "en-font":""}`
    };
    return !this.state.showMenu && !this.props.alwaysShowForm ? (
      <div className={"searchShow"}>
        <div className="originShow text-center">
          <div className="gray-label">{Dictionary.from}</div>
          <Tooltip placement="top" title={this.state.searchParam.originName}>
            <b>{this.state.searchParam.originCode}</b>
          </Tooltip>
        </div>
        <div className="typeShow">

            <img
              src={returnway}
              alt="return way tip"
              title="return way trip"
              className="rotate-rtl"
            />

        </div>
        <div className="destinationShow text-center">
          <div className="gray-label">{Dictionary.to}</div>
          <Tooltip placement="top" title={this.state.searchParam.destinationName}>
            <b>{this.state.searchParam.destinationCode}</b>
          </Tooltip>
        </div>
        <div className="seperator"></div>
        <div className="depShow text-start">
          <div className="gray-label">{Dictionary.departureDate}</div>
          {LAN === "IR" ? (
            <div>
              <span className="searched-value shamsi-date">
                {" "}
                {moment(this.state.searchParam.checkin).format(
                  "jYYYY-jMM-jDD"
                )}
              </span>
              {" "}
              <small>
                (
                {moment(this.state.searchParam.checkin).format(
                  "YYYY-MM-DD"
                )}
                ){" "}
              </small>
            </div>
          ) : (
            <div className="searched-value">
              {moment(this.state.searchParam.checkin).format(
                "YYYY-MM-DD"
              )}
            </div>
          )}
        </div>

          <div className="retShow text-start">
            <div className="gray-label">{Dictionary.returnDate}</div>
            {LAN === "IR" ? (
              <div>
                <span className="searched-value shamsi-date">
                  {" "}
                  {moment(
                    this.state.searchParam.checkout
                  ).format("jYYYY-jMM-jDD")}
                </span>
                {" "}
                <small>
                  (
                  {moment(
                    this.state.searchParam.checkout
                  ).format("YYYY-MM-DD")}
                  ){" "}
                </small>
              </div>
            ) : (
              <div className="searched-value">
                {moment(
                  this.state.searchParam.checkout
                ).format("YYYY-MM-DD")}
              </div>
            )}
          </div>


        <div className="passShow text-start">
          <div className="gray-label">{Dictionary.passengersQuantity}</div>
          <strong className="searched-value">
            <>
            <span>{this.state.searchParam.adults.length} </span>
              <span> {this.state.searchParam.adults.length > 1 ? Dictionary.rooms : Dictionary.room} </span>

              (<span>{_.sumBy(this.state.searchParam.adults, item => +item)} </span>
              <span> {this.state.searchParam.adult > 1 ? Dictionary.adults : Dictionary.adult}</span>

              {_.sumBy(this.state.searchParam.children, item => +item) > 0 && <>
                  <span> + {_.sumBy(this.state.searchParam.children, item => +item)} </span>
                  <span>{this.state.searchParam.child > 1 ? Dictionary.children : Dictionary.child}</span>
              </>})
            </>
        
          </strong>
        </div>
        <div className="showSearch">
          <button
            type="button"
            className="button color-1-btn"
            onClick={() => this.setState({ showMenu: true })}
          >
            {Dictionary.modifySearch}
          </button>
        </div>
      </div>
    ) : (
      <div className={formWrapperClassNames}>
        <div className={this.props.inline && "search-container"}>
          <div className="search-box-holder package-searchbox-holder">
          {LayoutConfig.themeClassName === 'style-2' && <SearchBoxTab searchType="package" />}
            <div className="searchbox-inner">
              <div className="triptype-radio-group">
                {this.props.inline && (
                  <div className="clearfix">
                    <button
                      type={"button"}
                      className="button show-close-btn pull-end"
                      onClick={() => {
                        this.setState({ showMenu: false });
                      }}
                    >
                      <span className="itours-icon gray-close-icon icon-large" />
                    </button>
                  </div>
                )}
              </div>
              <div className="search-bar">
                <div className="float-row half-gap">
                  <div className="col-small-12 col-medium-10">
                    <div className="float-row half-gap">
                      <div className={`col-xsmall-12 col-small-12 col-medium-8 ${LayoutConfig.themeClassName === 'style-2'?"margin-bottom-half":"margin-bottom"}`}>
                        <div className="search-route-item" >
                          <div className="origin-destination-block relative">
                            <div className="flight-origin-holder relative">
                              <Autocomplete
                                defaultValue={
                                  this.state.searchParam.originCode
                                }
                                placeholder={Dictionary.origin}
                                defaultText={
                                  this.state.searchParam.originName
                                }
                                getValue={this.autoCompleteCallback.bind(this)}
                                name={"origin"}
                                index={0}
                                type={"package"}
                                validate={true}
                                url={ServerAddress.Type +ServerAddress.Package +Package.GetLocation}
                                min={3}
                                param="value"
                                header={Header}
                              />
                              {this.state.validation &&
                                !this.state.searchParam.originCode && (
                                  <b className="validator-label">
                                    {Dictionary.pleaseEnterOrigin}
                                  </b>
                                )}
                            </div>
                            <div className="flight-destination-holder relative">
                              <Autocomplete
                                defaultValue={
                                  this.state.searchParam.destinationCode
                                }
                                placeholder={Dictionary.destination}
                                defaultText={
                                  this.state.searchParam.destinationName
                                }
                                getValue={this.autoCompleteCallback.bind(this)}
                                name={"destination"}
                                index={0}
                                type={"package"}
                                validate={true}
                                url={ServerAddress.Type +ServerAddress.Package +Package.GetLocation}
                                min={3}
                                param="value"
                                header={Header}
                              />
                              {this.state.validation &&
                                !this.state.searchParam.destinationCode && (
                                  <b className="validator-label">
                                    {Dictionary.pleaseEnterDestination}
                                  </b>
                                )}
                            </div>
                            <button
                              type="button"
                              className="swap-vert"
                              onClick={this.destToOrigin}
                            />
                          </div>
                          <div className="relative date-block departure-date">
                            <div
                              className={
                                this.state.searchParam.checkin
                                  ? "styled-date-holder"
                                  : null
                              }
                            >
                                <>
                                    <MultiDatePicker 
                                      ref={element => {this.checkinRef = element}}
                                      calendarPosition={datepickerConfig.calendarPosition}
                                      weekDays={datepickerConfig.weekDays}
                                      digits={datepickerConfig.digits}
                                      inputClass="multi-datepicker-input"
                                      className={datepickerConfig.className}
                                      minDate={
                                        this.state.datepickerType ==="persian"? moment().format("jYYYY/jMM/jDD") : moment().format("YYYY/MM/DD")
                                      }
                                      format={"YYYY-MM-DD"}
                                      calendar={this.state.datepickerType}
                                      locale={this.state.datepickerLocale}
                                      value={this.state.searchParam.checkin && (this.state.datepickerType ==="persian"?
                                        moment(this.state.searchParam.checkin).format("jYYYY/jMM/jDD")
                                        : moment(this.state.searchParam.checkin).format("YYYY/MM/DD")
                                      )
                                      }
                                      onFocusedDateChange={this.checkinChangeHandle}
                                      placeholder={ Dictionary.departure}
                                    >
                                      {this.state.isMobile && (
                                        <div className="date-picker-title">{Dictionary.departureFlightDate}</div>
                                      )}
                                      {LAN==="IR" && <button type="button" className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font" onClick={this.toggleDatepicker}>{this.state.datepickerLocale === "fa" ? "تقویم میلادی":"تقویم شمسی"}</button>}
                                      {this.state.isMobile && (
                                        <button className={'close-datepicker-btn'} onClick={() => this.checkinRef.closeCalendar()}>
                                          <span className={`itours-icon gray-close-icon icon-large`} />
                                        </button>
                                      )} 
                                    </MultiDatePicker>

                                  {this.state.searchParam.checkin ? (
                                    LAN === "IR"?
                                    <div className="styled-date-inner-wrapper">
                                      <div className="styled-date styled-date-month">
                                        {(this.state.datepickerType==="gregorian")
                                          ? this.showGregorianMonth(
                                              moment(
                                                this.state.searchParam
                                                  .checkin
                                              )
                                            )
                                          : this.showPersianMonth(
                                              moment(
                                                this.state.searchParam
                                                  .checkin
                                              )
                                            )}
                                      </div>
                                      <div className="styled-date styled-date-date">
                                        {(this.state.datepickerType==="gregorian")
                                          ? moment(
                                              this.state.searchParam
                                                .checkin
                                            ).format("DD")
                                          : moment(
                                              this.state.searchParam
                                                .checkin
                                            ).format("jDD")}
                                      </div>
                                      <div className="styled-date styled-date-day">
                                        {(this.state.datepickerType==="gregorian")
                                          ? this.showGregorianDate(
                                              moment(
                                                this.state.searchParam
                                                  .checkin
                                              )
                                            )
                                          : this.showPersianDate(
                                              moment(
                                                this.state.searchParam
                                                  .checkin
                                              )
                                            )}
                                      </div>
                                    </div>
                                    :
                                    <div className="styled-date-inner-wrapper">
                                      <div className="styled-date styled-date-month">
                                        {moment(
                                          this.state.searchParam
                                            .checkin
                                        ).format("MMMM")}
                                      </div>
                                      <div className="styled-date styled-date-date">
                                        {moment(
                                          this.state.searchParam
                                            .checkin
                                        ).format("DD")}
                                      </div>
                                      <div className="styled-date styled-date-day">
                                        {moment(
                                          this.state.searchParam
                                            .checkin
                                        ).format("dddd")}
                                      </div>
                                    </div>                                      

                                  ) : null}
                                </>

                              {this.state.validation &&
                                !this.state.searchParam.checkin && (
                                  <b className="validator-label">
                                    {Dictionary.pleaseEnterDepartureDate}
                                  </b>
                                )}
                            </div>
                          </div>
                          <div className="date-block return-date">
                            <div className="relative">
                              <div
                                className={
                                  this.state.searchParam.checkout
                                    ? "styled-date-holder"
                                    : null
                                }
                              >
                                  <>
                                  <MultiDatePicker 
                                    ref={element => {this.checkoutRef = element}}
                                    calendarPosition={datepickerConfig.calendarPosition}
                                    weekDays={datepickerConfig.weekDays}
                                    digits={datepickerConfig.digits}
                                    inputClass="multi-datepicker-input"
                                    className={datepickerConfig.className}
                                    minDate={
                                      this.state.datepickerType ==="persian" ?
                                      moment(this.state.searchParam.checkin).format("jYYYY/jMM/jDD")  
                                      : moment(this.state.searchParam.checkin).format("YYYY/MM/DD")
                                    }
                                    format={"YYYY-MM-DD"}
                                    calendar={this.state.datepickerType}
                                    locale={this.state.datepickerLocale}
                                    value={
                                      this.state.searchParam.checkout ? (this.state.datepickerType ==="persian"? 
                                      moment(this.state.searchParam.checkout).format("jYYYY/jMM/jDD")
                                      :moment(this.state.searchParam.checkout).format("YYYY/MM/DD")
                                      ): undefined
                                    }
                                    onFocusedDateChange={d => {
                                      const e = d.toDate();
                                      this.setDate(1, e);
                                      this.checkoutRef.closeCalendar();
                                    }}
                                    placeholder={Dictionary.return}
                                  >
                                      {this.state.isMobile && (
                                        <div className="date-picker-title">{Dictionary.returnFlightDate}</div>
                                      )}
                                    {LAN==="IR" && <button type="button" className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font" onClick={this.toggleDatepicker}>{this.state.datepickerLocale === "fa" ? "تقویم میلادی":"تقویم شمسی"}</button>}
                                    {this.state.isMobile && (
                                      <button className={'close-datepicker-btn'} onClick={() => this.checkoutRef.closeCalendar()}>
                                        <span className={`itours-icon gray-close-icon icon-large`} />
                                      </button>
                                    )} 
                                  </MultiDatePicker>
                                    {this.state.searchParam
                                      .checkout ? (
                                      LAN === "IR"?
                                      <div className="styled-date-inner-wrapper">
                                        <div className="styled-date styled-date-month">
                                        {(this.state.datepickerType==="gregorian")
                                            ? this.showGregorianMonth(
                                                moment(
                                                  this.state.searchParam
                                                    .checkout
                                                )
                                              )
                                            : this.showPersianMonth(
                                                moment(
                                                  this.state.searchParam
                                                    .checkout
                                                )
                                              )}
                                        </div>
                                        <div className="styled-date styled-date-date">
                                          {(this.state.datepickerType==="gregorian")
                                            ? moment(
                                                this.state.searchParam
                                                  .checkout
                                              ).format("DD")
                                            : moment(
                                                this.state.searchParam
                                                  .checkout
                                              ).format("jDD")}
                                        </div>
                                        <div className="styled-date styled-date-day">
                                          {(this.state.datepickerType==="gregorian")
                                            ? this.showGregorianDate(
                                                moment(
                                                  this.state.searchParam
                                                    .checkout
                                                )
                                              )
                                            : this.showPersianDate(
                                                moment(
                                                  this.state.searchParam
                                                    .checkout
                                                )
                                              )}
                                        </div>
                                      </div>
                                      :
                                      <div className="styled-date-inner-wrapper">
                                      <div className="styled-date styled-date-month">
                                        {moment(
                                          this.state.searchParam
                                            .checkout
                                        ).format("MMMM")}
                                      </div>
                                      <div className="styled-date styled-date-date">
                                        {moment(
                                          this.state.searchParam
                                            .checkout
                                        ).format("DD")}
                                      </div>
                                      <div className="styled-date styled-date-day">
                                        {moment(
                                          this.state.searchParam
                                            .checkout
                                        ).format("dddd")}
                                      </div>
                                    </div>
                                    ) : null}
                                  </>
                              </div>
                              {this.state.validation &&
                                !this.state.searchParam.checkout && (
                                  <b className="validator-label">
                                    {Dictionary.pleaseEnterReturnDate}
                                  </b>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`col-xsmall-12 col-small-12 col-medium-4 ${LayoutConfig.themeClassName === 'style-2'?"":"margin-bottom"}`}>
                        <div className="form-group">
                          <select
                            className="form-select room-option-toggle-select"
                            onChange={(event) =>
                              this.selectPassengersOptions(event)
                            }
                          >
                            <option 
                              value="2"
                              selected={this.state.roomOptionSelect === "2ADT"}
                            >
                              1 {Dictionary.room}
                              {Dictionary.comma} 2 {Dictionary.adults}
                              {Dictionary.comma} 0 {Dictionary.children}
                            </option>


                            <option
                                value="1"
                                selected={this.state.roomOptionSelect === "1ADT"}
                            >
                              1 {Dictionary.room}
                              {Dictionary.comma} 1 {Dictionary.adults}
                              {Dictionary.comma} 0 {Dictionary.children}
                            </option>
                            
                            <option 
                              value="0"
                              selected={this.state.roomOptionSelect === "OPTIONS"}
                            >
                              {this.state.roomOptionSelect === "OPTIONS"
                              ? this.state.searchParam.adults.length +" " +
                                Dictionary.room +
                                Dictionary.comma + " " +
                                this.state.searchParam.adults.reduce((sum,item)=>sum+item,0) +" " +
                                Dictionary.adults +
                                Dictionary.comma + " " +
                                this.state.searchParam.children.reduce((sum,item)=>sum+item,0) +" " +
                                Dictionary.children
                              : Dictionary.moreOptions}
                            </option>

                          </select>
                        </div>
                      </div>
                    </div>
                    {this.state.selectPassengersOptionVisibility && (
                      <div className="passengers-options-holder margin-bottom">
                        <SelectRoom
                          validationError={this.state.infantAdultValidationErrorMessage}
                          onSelectItem={(e) => {
                            this.handlePassenger(e);
                          }}
                          defaultAdult={this.state.searchParam.adults}
                          defaultChildren={
                            this.state.searchParam.children
                          }
                          defaultAges={this.state.searchParam.ages}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-small-12 col-medium-2">
                    <button
                      type="button"
                      className="thickSearchBtn button red-btn pull-end full-width"
                      onClick={this.submitHandler}
                    >
                      <span className="itours-icon search-icon" />
                      <span className={"btn-text"}> {Dictionary.search} </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
const mapDispatchToProps = (dispatch) => ({
  showError: (param) => dispatch(showError(param)),
});
const RouterPath = withRouter(PackageSearchForm);
export default connect(mapStateToProps, mapDispatchToProps)(RouterPath);
