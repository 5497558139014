import { useRef, useState } from 'react';
import { Form } from 'antd';
import moment from 'moment-jalaali';

import Loading from '../../Common/Loading';
import {loginUserIr, getUserAsync,recoverPassword } from '../../../Actions/UserAction';
import { useDispatch } from 'react-redux';
import { showError, toggleVarification } from '../../../Actions/VisualDataAction';

const Login = (props) => {
  const [loading, setLoading] = useState();
  const [forgetLinkSent, setForgetLinkSent] = useState(false);

  const passwordRef = useRef();
  const dispatch = useDispatch();

  let userNameValue = props.userName;
  if (/^\d+$/.test(userNameValue)) {
    if (userNameValue[0] === '0') {
      userNameValue = userNameValue.substring(1);
    }
    userNameValue = `+98${userNameValue}`;
  }

  const SubmitLoginHandler = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(" ورود به حساب کاربری...");
        const response = await loginUserIr({ emailOrPhoneNumber: userNameValue, password: values.password });
        setLoading();
        if (response.data?.success) {
          const user = response.data.result.user;

          localStorage.setItem('userData', JSON.stringify(user));
          localStorage.setItem('userToken', response.data.result.accessToken);
          localStorage.setItem('userTokenCreateTime', moment().format());

          dispatch(getUserAsync(response.data.result.user));
          dispatch(toggleVarification(true));

          const currentPagePath = window.location.pathname;
          if (currentPagePath === '/confrim' && response.data.result.user.isEmailConfirmed) {
            window.location.href = '/';
          }
        } else {
          dispatch(
            showError({
              visibility: true,
              content: response.message,
              closable:true
            })
          );
        }
      }
    });
  };

  const forgetPasswordHandle =async userName => {
    if (/[a-zA-Z]/g.test(userName)) {
      //using email address:
      setLoading(` ارسال لینک بازیابی به ایمیل ${userName}`);
      const response = await recoverPassword({ emailAddress: userName });
      setLoading();
      if (response.data?.success) {
        setForgetLinkSent(true);
      } else {
        dispatch(
          showError({
            visibility: true,
            content: response.error.message,
          })
        );
      }
    }else{
      //using mobile number:
      props.changeModal('mobileForgetPassword');
    }
  }

  const { getFieldDecorator } = props.form;

  if (forgetLinkSent){
    return (
      <div className="margin-top-xlarge margin-bottom-xlarge text-center">
        <span className="check-green itours-icon icon-large margin-bottom" />
        <p>
          <strong>لینک بازیابی کلمه عبور برای شما ایمیل شد.</strong>
        </p>
        <button
          type="button"
          className="link-style-btn"
          onClick={() => {setForgetLinkSent(false);}}>
          بازگشت به صفحه ورود
        </button>
      </div>
    );
  }

  return loading ? (
    <div className="margin-top-xlarge margin-bottom-xlarge">
      <Loading description={loading} />
    </div>
  ) : (
    <>
      <h4 className="medium-title margin-bottom-large-mobile">ورود </h4>
      <Form onSubmit={SubmitLoginHandler}>
        <Form.Item className="no-margin form-group" label="کلمه عبور">
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'کلمه عبور را وارد کنید' }],
          })(<input ref={passwordRef} className="form-input" type="password" />)}
        </Form.Item>
        <button type="submit" className="margin-top margin-bottom button blue-btn large-btn full-width">
          ورود
        </button>
      </Form>

      <div className="text-end">
        <div>
          <button
            type="button"
            className="link-style-btn"
            onClick={() => {
              props.changeModal('verification');
            }}>
            ورود با رمز یک بار مصرف
          </button>
        </div>
        <button
          type="button"
          className="link-style-btn"
          onClick={() => {
            forgetPasswordHandle(props.userName);
          }}>
          فراموشی کلمه عبور
        </button>
      </div>
    </>
  );
};

const WrappedLoginForm = Form.create({ name: 'login' })(Login);

export default WrappedLoginForm;
