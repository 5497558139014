import Currency from "../Enum/Currency";
export const Header = {
  "Content-Type": "application/json",
  "Accept-Language": "en-US",
  "currency":
    process.env.REACT_APP_THEME === "PADIDEH81COM"
      ? "EUR"
      : process.env.REACT_APP_THEME === "PADIDEH81IR"
      ? "IRR"
      : Currency.getCurrency(),
  "apikey": process.env.REACT_APP_SERVER_TERMINAL,
  "tenantid": process.env.REACT_APP_SERVER_TENANTID,
};
export const ServerAddress = {
  Type: process.env.REACT_APP_SERVER_TYPE,
  User: process.env.REACT_APP_SERVER_USER,
  Hotel: process.env.REACT_APP_SERVER_HOTEL,
  Package: process.env.REACT_APP_SERVER_PACKAGE,
  Payment: process.env.REACT_APP_SERVER_PAYMENT,
  localServer: process.env.REACT_APP_SERVER_LOCALSERVER,
  localIP: process.env.REACT_APP_SERVER_LOCALIP,
  Flight: process.env.REACT_APP_SERVER_FLIGHT
};
export const User = {
  Register: "/api/services/app/Account/Register", //for register new user
  RegisterByInfo: "/api/services/app/Account/RegisterByInfo", //register new user for namin travel
  Login: "/api/TokenAuth/Login", //for login user
  VarficationUser: "/api/services/app/Account/GetUser",
  Forgot: "/api/services/app/Account/ForgotPassword",
  Confrim: "/api/services/app/Account/ConfirmEmail",
  ResetPassword: "/api/services/app/Account/ResetPassword",
  Update: "/api/services/app/Account/Update",
  ChangePassword: "/api/services/app/Account/ChangePassword",
  CreateNewsLetter:"/api/services/app/NewsLetter/Create",
  CreateContactUs:"/api/services/app/ContactUs/Create",
  SendEmailActivation:"​/api/services/app/Account/SendEmailActivation",
  ActivateEmail:"/api/services/app/Account/ActivateEmail",
  
  //for itours ir only:
  IsUsernameExist:"/api/services/app/Account/IsUsernameExist",
  SendOTP:"/api/services/app/OTP/SendOTP",
  RegisterOrLogin:"/api/services/app/OTP/RegisterOrLogin",
  ChangePasswordByAuthorizedUser:"/api/services/app/Account/ChangePasswordByAuthorizedUser",
  ForgotPasswordByPhoneNumber:"/api/services/app/Account/ForgotPasswordByPhoneNumber",
  ResetPasswordByPhoneNumber:"/api/services/app/Account/ResetPasswordByPhoneNumber"
};
export const Flight = {
  GetLocation: "/api/services/app/BookingFlight/GetAirport",
  GetDomesticLocation:"/api/services/app/BookingFlight/GetDomesticAirport",
  GetAirline: "/api/services/app/BookingFlight/GetAirline",
  GetFlights: "/api/services/app/BookingFlight/LowFareSearch",
  ValidateFlight: "/api/services/app/BookingFlight/Validate",
  GetPNR: "/api/services/app/BookingFlight/ReservePNR",
  GetRules: "/api/services/app/BookingFlight/GetFlightRules",
  GetReserveByID: "/api/services/app/BookingFlight/GetFlightReserveById",
  GetDirectTicketByID: "/api/services/app/BookingFlight/GetDirectTicketById",
  BankStatus:"/api/services/app/BookingFlight/BankStatus",
  GetTime:"/api/services/app/BookingFlight/GetTime"
};
export const Hotel = {
  GetLocation: "/api/services/app/BookingHotel/GetLocation",
  GetAccomadtion: "/api/services/app/BookingHotel/GetSearch",
  GetAccomadtionDetails: "/api/services/app/BookingHotel/GetHotelById",
  PreReserve: "/api/services/app/BookingHotel/PreReserve",
  GetPreReserveID: "/api/services/app/BookingHotel/GetPreReserveByKey",
  GetReserveByID: "/api/services/app/BookingHotel/GetReserveById",
  PostReserver: "/api/services/app/BookingHotel/Reserve",
  GetVoucherDetails: "/api/services/app/BookingHotel/GetReserveById",
  BookHotel: "/api/services/app/BookingHotel/Book",
  GetRoom: "/api/services/app/BookingHotel/GetRoom",
  GETType: "/api/services/app/Reserve/GetReserveById",
  GetCancellationPolicy: "/api/services/app/BookingHotel/GetCancellationPolicy",
};
export const Package = {
  PackageAvailibility: "/api/services/app/Booking/Availability",
  PackageGetAvailability: "/api/services/app/Booking/GetAvailability",
  GetPackageHotelDetailById: "/api/services/app/Booking/GetHotelDetailById",
  PreReserveFlight:"/api/services/app/Booking/PreReserveFlight",
  PreReserveRoom:"/api/services/app/Booking/PreReserveRoom",
  reserve:"/api/services/app/Booking/Reserve",
  Confirm:'/api/services/app/Booking/Confirm',
  PackageValidateFlight:"/api/services/app/Booking/ValidateFlight",
  GetByPrereserveKey:"/api/services/app/PreReserve/Get",
  GetReserveById:"/api/services/app/Reserve/Get",
  GetLocation:"/api/services/app/Booking/GetAirports"
};
export const Reserve = {
  hotel: "/api/services/app/Reserve/GetReserves",
  naminTravelFlight : "/api/services/app/Reserve/GetAll",
  getReserveFromCoordinator : "/api/services/app/Order/Get"
};
export const Payment = {
  GetBanksInformation: "/api/v1/Reserves/Gateway/GetGateways",
  MakeToken: "/api/v1/Reserves/Gateway/MakeToken",
  GatewayReport: "/api/v1/Payment/GatewayReport",
};

export const ServerStatus = {
  Success: 1,
  Error: 2,
  SummaryError: 3,
};
