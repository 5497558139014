import React from 'react';
import Form from '../Common/Form';
import {connect} from 'react-redux';
import Validation from "../Common/Validation";
import {showError,editMessage} from "../../Actions/VisualDataAction"
import {changePassword,ChangePasswordByAuthorizedUser} from "../../Actions/UserAction"
class ChangePassword extends Form{
    state={
        ...this.state
    };
    FormDOM=null;
    handleSubmit(event){
        const {Dictionary} = this.props;
        super.validateForm(event,()=>{
            if(this.state.DataInput.newPassword===this.state.DataInput.confrimPassword){
                if (this.props.LAN === "IR"){
                    this.props.ChangePasswordByAuthorizedUser(this.state.DataInput);
                }else{
                    this.props.changePassword(this.state.DataInput);
                }
            }else{
                this.props.showError({visibility: true,content:Dictionary.newPasswordsAreNotEqual,closable:true});
            }

        })
    }
    componentDidMount(){
        super.componentDidMount(this.FormDOM);
    }
    render(){
        const {Dictionary,LAN} = this.props;
        const {error,respond} =this.props;
        return(
            <div>
                {(error.visibility)?
                    <div className="showMessage">
                        <div className="content">
                            {error.content}
                        </div>
                        <div className="buttonContainer">
                            <button type="button" onClick={()=>{this.props.showError({visibility:false,connect:null,closable:true})}}> {Dictionary.ok} </button>
                        </div>
                    </div>
                    :
                    (respond)?
                        <div className="text-center margin-bottom">
                            <div className="margin-bottom">
                                <div>
                                    <span className="itours-icon check-green icon-large" />
                                </div>
                                <strong>
                                    {Dictionary.yourPasswordHasBeenChangedSuccessfully}.
                                </strong>
                            </div>
                            <div className="buttonContainer clearfix">
                                <button type="button" className="button blue-btn" onClick={()=>{this.props.editMessage(false)}}> {Dictionary.ok} </button>
                            </div>
                        </div>
                        :
                        <form onSubmit={this.handleSubmit.bind(this)} ref={(node) => this.FormDOM=node}>
                            {LAN === "IR" || <div className="float-row">
                                <div className="col-small-12 col-medium-6">
                                    <div className="form-group">
                                        <label className="form-label">
                                            {Dictionary.currentPassword}
                                        </label>
                                        <input className="form-input placeholder-right en-input-text" data-validator={true} type="password"  name="currentPassword" />
                                        <Validation model={this.state} feild="ValidatorList" name='currentPassword' />
                                    </div>
                                </div>
                            </div>}
                            <div className="float-row">
                                <div className="col-small-12 col-medium-6">
                                    <div className="form-group">
                                        <label className="form-label">
                                            {Dictionary.newPassword}
                                        </label>
                                        <input className="form-input placeholder-right en-input-text" data-validator={true} type="password" name="newPassword" />
                                        <Validation model={this.state} feild="ValidatorList" name='newPassword' />
                                    </div>
                                </div>
                                <div className="col-small-12 col-medium-6">
                                    <div className="form-group">
                                        <label className="form-label">
                                            {Dictionary.newPasswordRepeat}
                                        </label>
                                        <input className="form-input placeholder-right en-input-text" data-validator={true} type="password" name="confrimPassword"  />
                                        <Validation model={this.state} feild="ValidatorList" name='confrimPassword' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button className="button red-btn" type="submit">
                                    {Dictionary.saveChanges}
                                </button>
                            </div>
                        </form>
                }

            </div>
        )
    }
}
const disptachMapProp=(dispatch)=>({
    showError:(param)=>dispatch(showError(param)),
    changePassword:(param)=>dispatch(changePassword(param)),
    ChangePasswordByAuthorizedUser:(param)=>dispatch(ChangePasswordByAuthorizedUser(param)),
    editMessage:(param)=>dispatch(editMessage(param))
});
const stateMapProp=(state)=>({
    error:state.VisualData.error,
    respond:state.VisualData.Responded,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default  connect(stateMapProp,disptachMapProp)(ChangePassword)