import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
class Validation extends React.Component{
    static propTypes={
        model:PropTypes.any,
        name:PropTypes.string.isRequired,
        feild:PropTypes.string.isRequired
    }
    render(){
        /* const {Dictionary,LAN} = this.props; */
        return(
            <div className="validator-label">{(this.props.model)?this.props.model[this.props.feild][this.props.name]:'SOS'}</div>
            
        )
    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(Validation);